import Notification from "../../../notification"
import {
  sectionContainer,
  sectionLeft,
  sectionTitle,
  container,
  subSectionTitle,
  form,
  uploadAssetsDiv,
  acknowledgementCheckContainer,
} from "./designerFront.module.scss"
import React, { useContext, useState } from "react"
import * as styles from "./designerFront.module.scss"
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core"
import { ThemeProvider } from "@material-ui/styles"
import { createMuiTheme } from "@material-ui/core/styles"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import { AppDispatchContext, AppStateContext } from "../../../../context/appContext"
import { UPDATE_NOTIFICATION, UPDATE_DESIGNER_DETAILS } from "../../../../constants/actionTypes"
import { uploadDesignerAssets } from "../../../../services/card"
import { navigate } from "gatsby-link"
import { API, graphqlOperation } from "aws-amplify"
import { submitOrder } from "../../../../graphql/mutations"
import { isBrowser } from "../../../../services/auth"
import { getPricingByCategory } from "../../../../services/pricing"
import { isValidPhoneNumber, formatPhoneNumber } from "react-phone-number-input"
import isEmail from "validator/lib/isEmail"
import { checkEmptyFields } from "../../../../services/validate"
import { backdrop } from "../../../creditCard/credit-card.module.scss"
import { OrderNavigation } from "../../../orderNavigation"
import { getSubmitOrderJson } from "../../../../utils/submitOrderData"

const DesignerFrontPage = () => {
  const dispatch = useContext(AppDispatchContext)
  const state = useContext(AppStateContext)
  const orderPaymentMethod = state.order.payment.paymentMethod
  const { order: appOrder, user, profileAddress, authProvider } = useContext(AppStateContext)
  const amountOfCards = 1
  const defaultCountryCode = "+1"
  const assets_max_file_size = "100MB"
  const assets_max_file_size_in_bytes = 104857600
  const [contactDetails, setContactDetails] = useState({
    businessName: state?.order?.cards?.contactDetails?.businessName || "",
    email: state?.order?.cards?.contactDetails?.email || "",
    phoneNumber: state?.order?.cards?.contactDetails?.phoneNumber || "",
  })
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [fileLoadingAnimation, setFileLoadingAnimation] = useState(false)
  const [acknowledgementCheck, setAcknowledgementCheck] = useState(false)
  const [assetsFile, setAssetsFile] = useState({
    name: state?.order?.cards?.designerAssets?.name || "",
    url: state?.order?.cards?.designerAssets?.url || "",
  })
  const [notesForDesigner, setNotesForDesigner] = useState(state?.order?.cards?.notes || "")
  const [showBackdrop, setShowBackdrop] = useState(false)

  const pricing = getPricingByCategory("card", "designer", authProvider)
  const nextLabel =
    process.env.GATSBY_SHOW_PAYMENT === "1" && orderPaymentMethod === "BEFORE_CREDIT_CARD"
      ? "Payment"
      : "Submit"
  const prevLabel = "Back: Dashboard"
  const final_price = pricing.reduce((result, price) => {
    const { quantity, total_price } = price
    return quantity === amountOfCards ? total_price : 0
  }, {})
  //Update the State with Pricing
  if (final_price !== 0 && state.order.cards) {
    state.order.cards.amountOfCards = amountOfCards
    state.order.cards.cost = final_price
  }
  /**
   * @param accepted {boolean} Whether terms was accepted
   * @returns {boolean} Whether there is missing data
   */
  const missingData = accepted => {
    return !accepted || checkEmptyFields()
  }

  // Warn users before: reload, close window, close tab
  window.addEventListener("beforeunload", function (e) {
    const pathname = window.location.pathname
    //Paths for orders: card-front, card-back accessories shipping review-order payment
    if (
      pathname === "/app/order/card-front" ||
      pathname === "/app/order/card-front-deluxe" ||
      pathname === "/app/order/designer" ||
      pathname === "/app/order/card-back" ||
      pathname === "/app/order/accessories" ||
      pathname === "/app/checkout/shipping" ||
      pathname === "/app/checkout/review-order" ||
      pathname === "/app/checkout/payment" ||
      pathname === "/app/checkout/payment/" ||
      pathname === "/app/order/designer"
    ) {
      e.returnValue = ""
    }
  })

  const handleContactDetailsInputBlur = event => {
    const { name, value } = event.target
    setSubmitButtonDisabled(missingData(acknowledgementCheck))
    setContactDetails({
      ...contactDetails,
      [name]:
        event.target.type === "tel"
          ? formatPhoneNumber(defaultCountryCode + value.trim())
          : value.trim(),
    })
  }

  const handleContactDetailsInputChange = event => {
    const { name, value, type } = event.target
    let clearValue = value
    if (type === "tel") {
      clearValue = clearNumber(value)
    }
    setSubmitButtonDisabled(missingData(acknowledgementCheck))
    setContactDetails({
      ...contactDetails,
      [name]: clearValue,
    })
  }

  const handleNotesForDesignerChange = event => {
    setSubmitButtonDisabled(missingData(acknowledgementCheck))
    setNotesForDesigner(event.target.value)
  }

  const handleDesignAssetsInputChange = event => {
    const assetFile = event.target.files[0]
    // No file was selected
    if (!assetFile) return

    setFileLoadingAnimation(true)
    setAssetsFile({
      name: "",
      url: "",
    })

    if (assetFile.size > assets_max_file_size_in_bytes) {
      setFileLoadingAnimation(false)
      return dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          text: `File size is larger than  ${assets_max_file_size}`,
        },
      })
    }

    uploadDesignerAssets(assetFile)
      .then(assetUrl => {
        setAssetsFile({
          name: assetFile.name,
          url: assetUrl,
        })
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: {
            show: true,
            type: `success`,
            text: `Upload successful`,
          },
        })
        setFileLoadingAnimation(false)
      })
      .catch(err => {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: {
            show: true,
            type: `error`,
            text: `Upload Error. ${err.errors[0].message}`,
          },
        })
        setFileLoadingAnimation(false)
      })

    return ``
  }

  const clearNumber = value => {
    return value.replace(/\D+/g, "")
  }

  const handleAckCheck = e => {
    // NOTE: acknowledgementCheck at this point has NOT been updated
    setSubmitButtonDisabled(missingData(!acknowledgementCheck))
    setAcknowledgementCheck(prev => !prev)
    return e.target.value
  }

  const onSubmitOrderSuccess = creation_date => {
    if (isBrowser()) {
      window.sessionStorage.removeItem("pcoState")
      window.sessionStorage.setItem("orderSuccessfullySubmitted", "1")
    }
    setShowBackdrop(false)
    // reset state and redirect to order summary
    navigate(`/app/orders/order-summary/#` + window.btoa(creation_date), {
      state: { resetOrder: 1 },
    })
  }

  const submitForm = async () => {
    if (!isEmail(contactDetails.email)) {
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          text: `Invalid Email Address`,
        },
      })
      document.getElementById("email").focus()
      return
    }
    if (!isValidPhoneNumber(defaultCountryCode + contactDetails.phoneNumber.replace(/\D/g, ""))) {
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          text: `Invalid Phone Number`,
        },
      })
      document.getElementById("phoneNumber").focus()
      return
    }
    if (process.env.GATSBY_SHOW_PAYMENT === "1" && orderPaymentMethod === "BEFORE_CREDIT_CARD") {
      dispatch({
        type: UPDATE_DESIGNER_DETAILS,
        payload: {
          cardType: "designer",
          amountOfCards,
          cost: final_price,
          contactDetails: contactDetails,
          designerAssets: assetsFile,
          designerAssetsUrl: assetsFile.url,
          notes: notesForDesigner,
        },
      })
      navigate("/app/checkout/payment/")
    } else {
      try {
        setShowBackdrop(true)
        const paymentDetails = {}
        const inputOrder = await getSubmitOrderJson(
          authProvider,
          user,
          appOrder,
          profileAddress,
          paymentDetails,
          {
            notesForDesigner: notesForDesigner,
            designerAssetsUrl: assetsFile.url,
            contactDetails: contactDetails,
          }
        )
        const result = await API.graphql(graphqlOperation(submitOrder, { input: inputOrder }))
        const {
          data: { submitOrder: saved },
        } = result
        onSubmitOrderSuccess(saved.createdAt)
      } catch (err) {
        console.warn(err)
        setShowBackdrop(false)
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: {
            show: true,
            type: `error`,
            text: `Error submitting order: ${err}`,
          },
        })
      }
    }
  }

  const navigateBack = () => {
    navigate("/app/dashboard")
  }

  const theme = createMuiTheme({
    typography: {
      body1: {
        textTransform: "none",
      },
    },
  })

  return (
    <>
      <Backdrop className={backdrop} open={showBackdrop}>
        <CircularProgress />
      </Backdrop>
      <Notification />
      <section className={sectionContainer}>
        <div className={sectionLeft}>
          <h4 className={sectionTitle}>upload logo and contact information</h4>
          <div className={container}>
            <h4 className={subSectionTitle}>Details:</h4>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl variant="filled" className={form}>
                  <TextField
                    required
                    id="businessName"
                    name="businessName"
                    variant="filled"
                    aria-describedby="my-helper-text"
                    label="Business Name"
                    inputProps={{ maxLength: 50 }}
                    onChange={handleContactDetailsInputChange}
                    onBlur={handleContactDetailsInputBlur}
                    value={contactDetails.businessName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="filled" className={form}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    variant="filled"
                    aria-describedby="my-helper-text"
                    label="Email"
                    inputProps={{ maxLength: 50 }}
                    onChange={handleContactDetailsInputChange}
                    onBlur={handleContactDetailsInputBlur}
                    value={contactDetails.email}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="filled" className={form}>
                  <TextField
                    required
                    id="phoneNumber"
                    name="phoneNumber"
                    type={`tel`}
                    variant="filled"
                    aria-describedby="my-helper-text"
                    label="Phone Number"
                    onChange={handleContactDetailsInputChange}
                    onBlur={handleContactDetailsInputBlur}
                    inputProps={{ maxLength: 10 }}
                    value={contactDetails.phoneNumber}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="filled" className={form}>
                  <TextField
                    required
                    id="notes"
                    type="text"
                    variant="filled"
                    name="notes"
                    multiline
                    minRows={4}
                    maxRows={4}
                    aria-describedby="my-helper-text"
                    defaultValue={notesForDesigner}
                    onChange={handleNotesForDesignerChange}
                    onBlur={handleNotesForDesignerChange}
                    label="Notes to the Designer"
                    inputProps={{ maxLength: 350 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px" }} className={styles.designerParagraph}>
                <h4 className={subSectionTitle}>Upload Your Logo and Design Assets:</h4>
                If you have any files you would like to share with your designer, like a business
                logo, please upload the file or an archive (zip/compressed file) of all the art
                assets you wish to send (maximum {assets_max_file_size}).
                <div className={uploadAssetsDiv}>
                  <Button
                    id="assetButton"
                    variant="contained"
                    component="label"
                    color="primary"
                    endIcon={<CloudUploadIcon />}
                    style={{ padding: "10px" }}
                  >
                    Upload File
                    <input
                      type="file"
                      data-test="upload-element"
                      hidden
                      onChange={handleDesignAssetsInputChange}
                    />
                  </Button>
                  <span style={{ marginLeft: "20px" }}>
                    {fileLoadingAnimation ? (
                      <foreignObject x="45%" y="45%" width="100%" height="100%">
                        <div xmlns="http://www.w3.org/1999/xhtml">
                          <CircularProgress />
                        </div>
                      </foreignObject>
                    ) : (
                      ``
                    )}
                    <div>{assetsFile.name}</div>
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px" }} className={styles.designerParagraph}>
                <h4 className={subSectionTitle}>Acknowledgement:</h4>
                By checking the box below you are confirming that you have reviewed all the
                information above is correct and final. You will receive two (2) proofs of your gift
                card design. One (1) front gift card design is created per order. You will be able
                to customize the back of your gift card when you order plastic gift cards from your
                final design. This form can not be changed or altered once submitted. The $100
                designer charge is non-refundable and all sales are final.
                <br />
                <ThemeProvider theme={theme}>
                  <FormControlLabel
                    className={acknowledgementCheckContainer}
                    control={
                      <Checkbox
                        name="acknowledgement_checkbox"
                        onChange={handleAckCheck}
                        value={acknowledgementCheck}
                        required
                      />
                    }
                    label="I accept the Acknowledgement stated above."
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={styles.sectionRight}>
          <div className={styles.designerNavigation}>
            {OrderNavigation(navigateBack, prevLabel, submitForm, nextLabel, submitButtonDisabled)}
          </div>
        </div>
      </section>
    </>
  )
}

export default DesignerFrontPage
