import axios from "axios"
import amexLogo from "./icons/american-express.png"
import dcLogo from "./icons/diners-club.png"
import discoverLogo from "./icons/discover.png"
import jcbLogo from "./icons/jcb.png"
import mastercardLogo from "./icons/mastercard.png"
import visaLogo from "./icons/visa.png"
import { PAYMENT_NONCE_URL } from "../../constants/localEnv"

/**
 * Has card expired
 *
 * @param {string} expiryMonth - 01 or 1
 * @param {string} expiryYear - 2019
 * @returns {boolean} True if card expired
 */
export function hasExpired(expiryMonth, expiryYear) {
  const today = new Date()
  const expiry = new Date(parseInt(expiryYear), parseInt(expiryMonth), 1)
  return expiry.getTime() < today.getTime()
}

/**
 * Luhn check a credit card number
 *
 * @param {string} value - credit card number
 * @returns {boolean} True if valid, false if invalid
 */
export function luhnCheck(value) {
  // Accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) return false

  // Luhn Algorithm
  let nCheck = 0,
    bEven = false
  value = value.replace(/\D/g, "")

  for (let n = value.length - 1; n >= 0; n--) {
    let cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10)
    if (bEven && (nDigit *= 2) > 9) nDigit -= 9
    nCheck += nDigit
    bEven = !bEven
  }

  return nCheck % 10 === 0
}

/**
 * Generate the credit card form data
 * @param values
 * @returns {URLSearchParams}
 */
function cardFormData(values) {
  const params = new URLSearchParams()
  appendToParams(params, "card[name]", values.name)
  appendToParams(params, "card[number]", values.number)
  appendToParams(params, "card[cvc]", values.cvc)
  appendToParams(params, "card[exp_month]", values.expMonth)
  appendToParams(params, "card[exp_year]", values.expYear)
  appendToParams(params, "card[address_line1]", values.addressLine1)
  appendToParams(params, "card[address_line2]", values.addressLine2)
  appendToParams(params, "card[address_city]", values.city)
  appendToParams(params, "card[address_state]", values.state)
  appendToParams(params, "card[address_zip]", values.zip)
  return params
}

/**
 * @param params {URLSearchParams}
 * @param name {string}
 * @param value {string}
 */
export function appendToParams(params, name, value) {
  if (value === undefined) return
  params.append(name, value)
}

export async function generateNonce(values) {
  try {
    const response = await axios({
      url: process.env.GATSBY_PAYMENT_NONCE_URL || PAYMENT_NONCE_URL,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      crossDomain: true,
      method: "post",
      data: cardFormData(values),
      timeout: 30000,
    })
    return response.data.id
  } catch (e) {
    if (e.response) {
      console.warn(e.response.data.message)
    }
    console.warn("Failed to generate nonce")
    throw new Error("Error submitting order. Please try again later.")
  }
}

/**
 * Get Credit Card Icon
 *
 * @param {Array} cardTypes
 * @returns {String} Url for the logo
 */
export const getCardIcon = cardTypes => {
  if (!cardTypes || cardTypes.length === 0 || cardTypes.length > 3) return ""
  const cardType = cardTypes[0].type
  if (!cardType) return ""

  if (cardType === "american-express") {
    return amexLogo
  } else if (cardType === "diners-club") {
    return dcLogo
  } else if (cardType === "discover") {
    return discoverLogo
  } else if (cardType === "jcb") {
    return jcbLogo
  } else if (cardType === "mastercard") {
    return mastercardLogo
  } else if (cardType === "visa") {
    return visaLogo
  }

  return ""
}

/**
 * Get Credit Card Icon
 *
 * @param {Array} cardTypes
 * @returns {String} Specific card type
 */
export const getCardType = cardTypes => {
  if (!cardTypes || cardTypes.length === 0 || cardTypes.length > 3) return ""
  const cardType = cardTypes[0].type

  return cardType ? cardType : ""
}

/**
 * Get Credit Card Icon
 *
 * @param {String} cardType
 * @returns {String} Url for the logo
 */
export const getCardIconFromType = cardType => {
  if (cardType === "american-express") {
    return amexLogo
  } else if (cardType === "diners-club") {
    return dcLogo
  } else if (cardType === "discover") {
    return discoverLogo
  } else if (cardType === "jcb") {
    return jcbLogo
  } else if (cardType === "mastercard") {
    return mastercardLogo
  } else if (cardType === "visa") {
    return visaLogo
  }

  return ""
}

/**
 * formatZipcode
 * formats the input zipcode text to follow
 * XXXXX for 5 digit zipcodes OR
 * XXXXX-XXXX for 5+4 digit zipcodes as user inputs digits
 *
 * @param value
 */
export function formatZipcode(value) {
  // for eg if the user deletes the input, then just return
  if (!value) return value

  // clean the input for any non-digit values.
  const cleanZip = value.replace(/[^\d]/g, "")

  // zipcodeLength is used to know when to apply our formatting for the zipcode
  const zipcodeLength = cleanZip.length

  // we need to return the value with no formatting if its less then 6 digits (i.e. the standard 5 digit zip
  if (zipcodeLength < 6) return cleanZip

  // if zipcode is less than 10 digits (i.e. 9; or 5+4 format XXXXX-XXXX)
  if (zipcodeLength < 10) {
    return `${cleanZip.slice(0, 5)}-${cleanZip.slice(5)}`
  }
}

// statesArray includes American Samoa (AS) + District of Columbia (DC) + Federated States Of Micronesia (FM) + Guam (GU) + Marshall Islands (MH) + Northern Mariana Islands (MP) + Palau (PW)+ Puerto Rico (PR) + Virgin Islands (VI)
export const statesArray = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
]
