import React from "react"
import * as styles from "../../conversion/conversion_new.module.scss"

const Numbering = props => {
  return (
    <>
      <div className={styles.list_list_icon_atom_text}>{props.number}</div>
    </>
  )
}

export default Numbering
