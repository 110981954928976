import React, { useContext, useEffect } from "react"
import { Amplify } from "aws-amplify"
import { Authenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import { navigate } from "gatsby-link"
import { initConfig } from "../services/amplify"
import { AppStateContext, isSessionActive, isSessionLoading } from "../context/appContext"
import { LoadingSpinner } from "../utils/loadingSpinner"

Amplify.configure(initConfig())

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const state = useContext(AppStateContext)

  useEffect(() => {
    console.log("Change in loggedIn State", state.loggedIn)
  }, [state.loggedIn])

  const checkSessionActive = event => {
    if (isSessionActive()) {
      console.log("Session is active")
    } else {
      console.log("Session in-active", window.location.href)
      event.preventDefault()
      navigate("/logged-out")
      return
    }
  }

  useEffect(() => {
    if (isSessionActive()) {
      console.log("Initialize event listeners")
      // click and keypress events, reset timeout
      window.addEventListener("click", checkSessionActive)
      window.addEventListener("keydown", checkSessionActive)
      return () => {
        console.log("Remove event listeners")
        window.removeEventListener("keydown", checkSessionActive)
        window.removeEventListener("click", checkSessionActive)
      }
    }
  }, [])

  if (isSessionActive()) {
    // User is probably logged in
    return <Component {...rest} />
  } else if (isSessionLoading()) {
    return <LoadingSpinner />
  } else if (process.env.NODE_ENV === "development" || process.env.GATSBY_SHOW_AMPLIFY_LOGIN) {
    // NOTE: This is for local development only
    return <Authenticator />
  } else {
    // Redirect to logged out page
    navigate("/logged-out")
    return null
  }
}

export default PrivateRoute
