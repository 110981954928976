import React, { useState } from "react"
import { setActiveClass } from "../../utils/helper"
import { Button, Backdrop, CircularProgress } from "@material-ui/core"
import Notification from "../notification"
import VLReportsComponent from "./vlReports"

const ReportsComponent = () => {
  const [showBackdrop, setShowBackdrop] = useState(false)

  function handleBackdrop(visible) {
    setShowBackdrop(visible)
  }

  const handleTabPanel = event => {
    const { id } = event.currentTarget
    setActiveClass(".pageNavigation", "> .toggleGroup > div.buttonContainer > button.active", id)
  }

  return (
    <React.Fragment>
      <Backdrop className={`backdrop`} open={showBackdrop}>
        <CircularProgress />
      </Backdrop>
      <Notification />
      <div className={`pageWrapper`}>
        <div className={`pageNavigation`}>
          <div className={`toggleGroup`}>
            <div className={`buttonContainer`}>
              <Button
                key={`reports`}
                className={`active reportingOption`}
                id={`reports`}
                value={`reports`}
                onClick={handleTabPanel}
                variant={`text`}
              >
                Reports
              </Button>
              <span className={`activeIndicator`}>&nbsp;</span>
            </div>
          </div>
        </div>
        <h2 className={`typographyH1`} tabIndex="-1" data-test="reports-page-title"></h2>
        <VLReportsComponent handleBackdrop={handleBackdrop} />
      </div>
    </React.Fragment>
  )
}

export default ReportsComponent
