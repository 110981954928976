import React from "react"
import * as styles from "./invoice.module.scss"
import { Button } from "@material-ui/core"
import OrderSummaryComponent from "../../../orderSummary"
import OrderSummaryHeaderComponent from "../../../orderSummary/header"
import GiftCardIcon from "../../../img/icons/giftCardIcon"

const InvoicePage = () => {
  const printInvoice = () => {
    window.print()
  }

  return (
    <>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionLeft}>
          <div className={styles.printHeader}>
            <h2 className={styles.sectionTitle}>
              <div>
                <GiftCardIcon />
              </div>
              invoice
            </h2>
          </div>
          <OrderSummaryHeaderComponent />
          <OrderSummaryComponent order_type={"invoice"} show_promo={false} />
        </div>
        <div className={styles.sectionRight}>
          <Button
            variant="contained"
            color="primary"
            className="buttonPrimary"
            id="print-button"
            onClick={printInvoice}
          >
            print
          </Button>
        </div>
      </div>
    </>
  )
}

export default InvoicePage
