import React from "react"
import { Checkbox } from "@material-ui/core"
import termsPDF from "../../../../images/rsa-terms-v1.pdf"
import pricingPDF from "../../../../images/pricing-2022.pdf"
import cloverPricingPDF from "../../../../images/clover-pricing-2022.pdf"
import * as styles from "../reviewOrder/review-order.module.scss"
import { AUTH_PROVIDER_PBC } from "../../../../constants/authProvider"

const agreementCheck = (pricingChecked, handlePricingCheck, authProvider) => {
  return (
    <div className={`agreementCheckComponent`}>
      <Checkbox id="checkbox" checked={pricingChecked} onChange={handlePricingCheck} />I agree to{" "}
      <a
        href={authProvider === AUTH_PROVIDER_PBC ? pricingPDF : cloverPricingPDF}
        id={styles.pricing_pdf_link}
        target="_blank"
        rel="noreferrer"
        className="active"
      >
        pricing
      </a>{" "}
      and{" "}
      <a
        href={termsPDF}
        id={styles.terms_pdf_link}
        target="_blank"
        rel="noreferrer"
        className="active"
      >
        <span className={styles.termsOfService}> terms of service </span>
      </a>
    </div>
  )
}

export default agreementCheck
