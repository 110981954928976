import {
  desc,
  grid,
  itemGrid,
  subTotalGrid,
  totalGrid,
  promoGrid,
  promoButton,
  promoAppliedGrid,
  priceColumn,
  promoItem,
} from "../orderSummary/order-summary.module.scss"
import {
  paymentsDesc,
  paymentsGrid,
  paymentsItemGrid,
  paymentsSubTotalGrid,
  paymentsTotalGrid,
  paymentsPromoGrid,
  paymentsPromoButton,
  paymentsPromoAppliedGrid,
  paymentsPriceColumn,
  paymentsPromoItem,
} from "../../components/views/checkout/payments/payments.module.scss"
import React, { useContext, useEffect, useState } from "react"
import { AppDispatchContext, AppStateContext } from "../../context/appContext"
import { isDesigner, getReceiptValues } from "../../services/card"
import { Backdrop, Box, Button, CircularProgress, IconButton, TextField } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import * as styles from "../views/checkout/reviewOrder/review-order.module.scss"
import { backdrop } from "../creditCard/credit-card.module.scss"
import { getDiscountValue, getPromoDetails } from "../../services/order-summary"
import { UPDATE_PROMO_DETAILS } from "../../constants/actionTypes"

const OrderSummaryReceiptComponent = ({ orderType, showPromo, receiptView }) => {
  const {
    order: currentOrder,
    recentOrder,
    profileAddress,
    user,
    authProvider,
  } = useContext(AppStateContext)
  const dispatch = useContext(AppDispatchContext)
  const [receiptBackdrop, setReceiptBackdrop] = useState(false)
  const [promoButtonDisabled, setPromoButtonDisabled] = useState(true)
  const [promoPriceChange, setPromoPriceChange] = useState(false)
  const [promoCode, setPromoCode] = useState("")
  const [promoError, setPromoError] = useState("")
  const [subtotalValue, setSubtotalValue] = useState(0)
  const [taxValue, setTaxValue] = useState(0)
  const [totalValue, setTotalValue] = useState(0)
  const [shippingValue, setShippingValue] = useState(0)
  const order = orderType ? recentOrder : currentOrder
  const cardType = order.cards.cardType

  const getAccessoryItems = () => {
    if (cardType !== "designer") {
      return order.accessories.summary.map((item, index) => {
        return (
          <div key={index}>
            <div>
              <span>
                {item.description}
                {!item.isCardCarrier ? "s" : ""} {item.isCardCarrier ? "Carrier" : ""}
              </span>
              <span className={receiptView && receiptView === "payments" ? paymentsDesc : desc}>
                ${(item.cost / item.quantity / 100).toFixed(2)} each
              </span>
            </div>
            <div>{item.quantity}</div>
            <div
              className={
                receiptView && receiptView === "payments" ? paymentsPriceColumn : priceColumn
              }
            >
              ${(item.cost / 100).toFixed(2)}
            </div>
          </div>
        )
      })
    }
  }

  const getPromoItem = () => {
    if (
      cardType !== "designer" &&
      order.payment.promoDetails &&
      Object.keys(order.payment.promoDetails).length > 0 &&
      order.payment.promoDetails.discount > 0
    ) {
      return (
        <div className={receiptView && receiptView === "payments" ? paymentsPromoItem : promoItem}>
          <div>
            <span>Discounts and Promotions</span>
            <span className={receiptView && receiptView === "payments" ? paymentsDesc : desc}>
              {order.payment.promoDetails.text}
            </span>
          </div>
          <div />
          <div
            className={
              receiptView && receiptView === "payments" ? paymentsPriceColumn : priceColumn
            }
          >
            {order.payment.promoDetails.type !== "free_shipping"
              ? "-$" + (order.payment.promoDetails.discount / 100).toFixed(2)
              : ""}
          </div>
        </div>
      )
    }
  }

  const handlePromoCodeChange = e => {
    setPromoButtonDisabled(!e.target.value)
    setPromoCode(e.target.value.toUpperCase())
    setPromoError("")
  }

  const applyPromoCode = async () => {
    setReceiptBackdrop(true)
    const response = await getPromoDetails(promoCode, authProvider, order, user)

    if (!response || response.error !== undefined) {
      setPromoError(response.error)
      setPromoButtonDisabled(true)
      setPromoPriceChange(false)
    } else {
      setPromoPriceChange(true)
      dispatch({
        type: UPDATE_PROMO_DETAILS,
        payload: {
          promoCode: promoCode,
          discount: response.discount,
          text: response.text,
          type: response.type,
          value: response.value,
          params: response.params,
        },
      })
    }
    setReceiptBackdrop(false)
  }

  const removePromo = () => {
    setReceiptBackdrop(true)
    setPromoCode("")
    setPromoButtonDisabled(true)
    setPromoPriceChange(true)
    dispatch({
      type: UPDATE_PROMO_DETAILS,
      payload: {
        promoCode: "",
        discount: 0,
        text: "",
        type: "",
        value: 0,
      },
    })
    setReceiptBackdrop(false)
  }

  const updateCost = () => {
    //Receipt values returns array [subtotal, tax, totalCost]
    getReceiptValues(order, profileAddress).then(values => {
      setSubtotalValue(values[0].toFixed(2))
      setTaxValue(values[1].toFixed(2))
      setTotalValue(values[2].toFixed(2))
      setShippingValue(values[3].toFixed(2))
    })
  }

  useEffect(() => {
    if (
      !orderType &&
      order.payment.promoDetails &&
      Object.keys(order.payment.promoDetails).length > 0 &&
      order.payment.promoDetails.discount > 0 &&
      order.payment.promoDetails.discount !== getDiscountValue(order, order.payment.promoDetails)
    ) {
      dispatch({
        type: UPDATE_PROMO_DETAILS,
        payload: {
          discount: getDiscountValue(order, order.payment.promoDetails),
        },
      })
    }

    if (promoPriceChange || order) {
      //update the cost on promoChange or order change
      updateCost()
    }

    if (
      taxValue === 0 ||
      totalValue === 0 ||
      ((!order.payment.promoDetails ||
        (order.payment.promoDetails && order.payment.promoDetails.type !== "free_shipping")) &&
        shippingValue === 0)
    ) {
      updateCost()
    }
  }, [order, dispatch, promoPriceChange, totalValue]) // eslint-disable-line react-hooks/exhaustive-deps

  const getPromoBlock = () => {
    if (
      order.payment.promoDetails &&
      Object.keys(order.payment.promoDetails).length > 0 &&
      order.payment.promoDetails.discount > 0
    ) {
      return (
        <div
          className={
            receiptView && receiptView === "payments" ? paymentsPromoAppliedGrid : promoAppliedGrid
          }
        >
          <Box
            component="div"
            sx={{
              display: "block",
              p: 0.5,
              m: 0.5,
            }}
          >
            <span>
              PROMO: {order.payment.promoDetails.promoCode}
              <br />
              {order.payment.promoDetails.text}
            </span>
            <IconButton
              id="promoCloseButton"
              aria-label="close"
              color="secondary"
              size="small"
              variant="contained"
              onClick={removePromo}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <div />
        </div>
      )
    } else {
      return (
        <div className={receiptView && receiptView === "payments" ? paymentsPromoGrid : promoGrid}>
          <div>
            <TextField
              id="promoCode"
              name="promoCode"
              variant="filled"
              inputProps={{ maxLength: 50 }}
              onChange={e => handlePromoCodeChange(e)}
              onBlur={e => handlePromoCodeChange(e)}
              helperText={promoError}
              error={promoError ? true : undefined}
              value={promoCode}
            />
          </div>
          <div
            className={
              receiptView && receiptView === "payments" ? paymentsPromoButton : promoButton
            }
          >
            <Button
              id="promoButton"
              variant="contained"
              className={`${styles.buttonPrimary} applyPromo buttonPrimary`}
              component="label"
              // color="primary"
              onClick={applyPromoCode}
              disabled={promoButtonDisabled}
            >
              Apply
            </Button>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <Backdrop className={backdrop} open={receiptBackdrop}>
        <CircularProgress />
      </Backdrop>
      <div className={receiptView && receiptView === "payments" ? paymentsGrid : grid}>
        <div>
          <div>
            <strong>ITEM</strong>
          </div>
        </div>
        <div>
          <div>
            <strong>QTY</strong>
          </div>
        </div>
        <div>
          <div
            className={
              receiptView && receiptView === "payments" ? paymentsPriceColumn : priceColumn
            }
          >
            <strong>PRICE</strong>
          </div>
        </div>
      </div>

      <div className={receiptView && receiptView === "payments" ? paymentsItemGrid : itemGrid}>
        <div>
          <div>
            <span>{order.cards.cardType} Gift Card Package</span>
            <span className={receiptView && receiptView === "payments" ? paymentsDesc : desc}>
              ${(order.cards.cost / order.cards.amountOfCards / 100).toFixed(2)} each
            </span>
          </div>
          <div>{order.cards.amountOfCards}</div>
          <div
            className={
              receiptView && receiptView === "payments" ? paymentsPriceColumn : priceColumn
            }
          >
            ${(order.cards.cost / 100).toFixed(2)}
          </div>
        </div>
        {getAccessoryItems()}
        {getPromoItem()}
      </div>

      <div
        className={receiptView && receiptView === "payments" ? paymentsSubTotalGrid : subTotalGrid}
      >
        <>
          <div />
          <div>
            <strong>SUBTOTAL</strong>
          </div>
          <div
            className={
              receiptView && receiptView === "payments" ? paymentsPriceColumn : priceColumn
            }
          >
            <strong>${subtotalValue}</strong>
          </div>
        </>
        <>
          <div />
          <div>
            <strong>TAX</strong>
          </div>
          <div
            className={
              receiptView && receiptView === "payments" ? paymentsPriceColumn : priceColumn
            }
          >
            <strong>${taxValue}</strong>
          </div>
        </>
        {!isDesigner(order) && (
          <>
            <div />
            <div>
              <strong>SHIPPING</strong>
            </div>
            <div
              className={
                receiptView && receiptView === "payments" ? paymentsPriceColumn : priceColumn
              }
            >
              <strong>${shippingValue}</strong>
              {/*getShippingCost().toFixed(2)*/}
            </div>
          </>
        )}
      </div>

      <div className={receiptView && receiptView === "payments" ? paymentsTotalGrid : totalGrid}>
        <>
          <div />
          <div>
            <strong>TOTAL</strong>
          </div>
          <div
            className={
              receiptView && receiptView === "payments" ? paymentsPriceColumn : priceColumn
            }
          >
            <strong>${totalValue}</strong>
          </div>
        </>
      </div>

      {!isDesigner(order) && showPromo && (
        <>
          <div className={receiptView && receiptView === "payments" ? paymentsGrid : grid}>
            <div>Have a Promo Code?</div>
            <div />
            <div />
          </div>
          {getPromoBlock()}
        </>
      )}
    </>
  )
}

export default OrderSummaryReceiptComponent
