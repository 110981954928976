import * as styles from "../orderSummary/order-summary.module.scss"
import React, { useContext } from "react"
import { AppStateContext } from "../../context/appContext"
import moment from "moment"

const OrderSummaryHeaderComponent = () => {
  const { recentOrder } = useContext(AppStateContext)

  return (
    recentOrder && (
      <div className={styles.summaryHeader}>
        <div className={styles.right}>
          <div className={styles.top}>
            {recentOrder.orderId && <span>Order # {recentOrder.orderId}</span>}
            <br />
            {recentOrder.createdAt && (
              <span>Date {moment(recentOrder.createdAt).format("MM-DD-YYYY")}</span>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default OrderSummaryHeaderComponent
