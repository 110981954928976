import React from "react"
import { Card, CardContent } from "@material-ui/core"
import OrderSummaryReceiptComponent from "./receipt"
import OrderSummaryPreviewComponent from "./preview"
import { cardContent, left, right } from "./order-summary.module.scss"

const OrderSummaryComponent = props => {
  const orderType = props.order_type
  const showPromo = props.show_promo

  return (
    <Card>
      <CardContent>
        <div className={cardContent}>
          <div className={left}>
            <OrderSummaryReceiptComponent
              orderType={orderType}
              showPromo={showPromo}
              receiptView={""}
            />
          </div>
          <div className={right}>
            <OrderSummaryPreviewComponent orderType={orderType} />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default OrderSummaryComponent
