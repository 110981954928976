import React, { useContext, useEffect, useState } from "react"
import * as styles from "./summary.module.scss"
import OrderSummaryComponent from "../../orderSummary"
import OrderSummaryHeaderComponent from "../../orderSummary/header"
import {
  RESET_ORDER,
  SET_ORDER,
  SET_RECENT_ORDER,
  UPDATE_NOTIFICATION,
} from "../../../constants/actionTypes"
import { AppDispatchContext, AppStateContext } from "../../../context/appContext"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import { loadOrderByCreatedAt, cancelByCreatedAt } from "../../../services/order-summary"
import { Button } from "@material-ui/core"
import Notification from "../../notification"
import GiftCardIcon from "../../img/icons/giftCardIcon"
import CloverIcon from "../../../images/icons/clover-logo.png"
import { deleteCookie } from "../../../utils/cookieHelper"
import { AUTH_PROVIDER_PBC } from "../../../constants/authProvider"

const canReOrderList = ["SUBMITTED", "RECEIVED", "COMPLETE"]
const OrderSummaryPage = () => {
  const [orderDate, setOrderDate] = useState(null)
  const [recentOrder, setRecentOrder] = useState(null)
  const state = useContext(AppStateContext)
  const dispatch = useContext(AppDispatchContext)
  const { user } = useContext(AppStateContext)
  const location = useLocation()

  let locState = false
  const orderSuccessfullySubmitted = window.sessionStorage.getItem("orderSuccessfullySubmitted")
  if (window && window.history && !locState) {
    locState = window.history.state
  }

  deleteCookie("encodedTimestamp")
  deleteCookie("emailRedirectURL")

  useEffect(() => {
    window.scrollTo(0, 0)
    const loadOrder = async createdAt => {
      try {
        const orderDetails = await loadOrderByCreatedAt(user, createdAt)
        dispatch({
          type: SET_RECENT_ORDER,
          payload: orderDetails,
        })
        setRecentOrder(orderDetails)
      } catch (err) {
        console.warn(err)
        navigate("/app/dashboard/")
      }
    }

    if (user && document.location.hash && document.location.hash.length > 2) {
      const createdAt = atob(document.location.hash.substr(1))
      setOrderDate(createdAt)
      loadOrder(createdAt)
    } else if (!document.location.hash) {
      navigate("/app/dashboard/")
    }

    if (locState && locState.resetOrder && orderSuccessfullySubmitted) {
      dispatch({ type: RESET_ORDER })
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `success`,
          text: `Congratulations! Your order was successfully submitted.`,
        },
      })
      window.sessionStorage.removeItem("orderSuccessfullySubmitted")
    }
  }, [user, orderDate, location, dispatch, locState, orderSuccessfullySubmitted])

  const reOrder = async isDesigner => {
    let navigate_to =
      user.paymentMethod &&
      user.paymentMethod === "BEFORE_CREDIT_CARD" &&
      process.env.GATSBY_SHOW_PAYMENT === "1"
        ? "/app/checkout/payment"
        : "/app/checkout/review-order"

    // NOTE: We should remove any of the order status fields before creating a new order
    delete recentOrder.pending
    delete recentOrder.status
    delete recentOrder.vlOrderId
    delete recentOrder.createdAt
    if (isDesigner) {
      recentOrder.cards.cardType = "deluxe"
      recentOrder.cards.subCardType = "designer"
      recentOrder.designOrderId = recentOrder.orderId
      delete recentOrder.cards.amountOfCards
      navigate_to = "/app/order/card-front-deluxe"
    } else {
      recentOrder.originalOrderId = recentOrder.orderId
    }
    delete recentOrder.orderId

    recentOrder.payment = {
      paymentMethod:
        (user.paymentMethod &&
          user.paymentMethod === "BEFORE_CREDIT_CARD" &&
          process.env.GATSBY_SHOW_PAYMENT === "1") ||
        (user.paymentMethod && user.paymentMethod !== "BEFORE_CREDIT_CARD")
          ? user.paymentMethod
          : "AFTER_MERCHANT_ACCOUNT",
    }

    dispatch({
      type: SET_ORDER,
      payload: recentOrder,
    })
    navigate(navigate_to)
  }

  const cancelOrder = async () => {
    try {
      await cancelByCreatedAt(user, orderDate)
      navigate("/app/dashboard/")
    } catch (err) {
      console.warn(err)
      alert("Failed to cancel order")
    }
  }

  const canReOrderCards = () => {
    if (!recentOrder || !recentOrder.cards) return false

    if (recentOrder.cards.cardType === "designer") {
      // Designer cards can be orderd only when the status is COMPLETE
      return recentOrder.status === "COMPLETE"
    }

    // Standard cards can be re-ordered if the status is in ["SUBMITTED", "RECEIVED", "COMPLETE"]
    return (
      ["standard", "deluxe"].includes(recentOrder.cards.cardType) &&
      canReOrderList.includes(recentOrder.status)
    )
  }

  const printInvoice = () => {
    window.print()
  }

  return (
    <>
      <Notification />
      <div className={`${styles.sectionContainer} screenOnly`}>
        <div className={styles.sectionLeft}>
          <div>
            <h2 className={styles.sectionTitle}>order summary</h2>
            {recentOrder && <OrderSummaryHeaderComponent />}
            {recentOrder && <OrderSummaryComponent order_type={"recent"} show_promo={false} />}
          </div>
        </div>
        <div className={styles.sectionRight}>
          <div className={styles.supportButtons}>
            {recentOrder && recentOrder.pending === 1 && (
              <Button
                variant="contained"
                color="primary"
                className="buttonPrimary"
                id="reorder-button"
                onClick={cancelOrder}
              >
                cancel order
              </Button>
            )}
            {recentOrder && recentOrder.status === "ERROR" && (
              <Button
                className={`link`}
                variant="text"
                id="contact-support-button"
                href={
                  "mailto:GiftCardProduction@fiserv.com?subject=PCO: Error for order created at " +
                  recentOrder.createdAt
                }
                target="top"
              >
                Contact Support
              </Button>
            )}
            {canReOrderCards() &&
              (["standard", "deluxe"].includes(recentOrder.cards.cardType) ? (
                <Button
                  variant="contained"
                  color="primary"
                  className="buttonPrimary"
                  id="reorder-button"
                  onClick={e => reOrder(recentOrder.cards.cardType === "designer")}
                  style={{ width: "8rem" }}
                >
                  reorder
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className="buttonPrimary"
                  id="reorder-button"
                  onClick={e => reOrder(recentOrder.cards.cardType === "designer")}
                >
                  order deluxe
                </Button>
              ))}
            {recentOrder && canReOrderList.includes(recentOrder.status) && (
              <Button
                className={
                  recentOrder.cards.cardType === "designer" ? `buttonPrimary` : `buttonSecondary`
                }
                variant="text"
                id="print-button"
                onClick={printInvoice}
                style={{ minWidth: "10rem" }}
              >
                Print Invoice
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.sectionContainerPrint}`}>
        <div className={styles.sectionLeft}>
          <div className={styles.printHeader}>
            <h2 className={styles.sectionTitle}>
              {/* <div> */}
              {state.authProvider === AUTH_PROVIDER_PBC ? (
                <div className={styles.pcoIconContainer}>
                  <GiftCardIcon />
                </div>
              ) : (
                <div className={styles.cloverIconContainer}>
                  <img src={CloverIcon} className={styles.cloverIcon} />
                </div>
              )}
              {/* </div> */}
              invoice
            </h2>
          </div>
          <OrderSummaryHeaderComponent />
          <OrderSummaryComponent order_type={"invoice"} show_promo={false} />
        </div>
      </div>
    </>
  )
}

export default OrderSummaryPage
