import React, { useEffect, useContext } from "react"
import Zendesk from "react-zendesk"
import { AppStateContext } from "../../../context/appContext"
import { AUTH_PROVIDER_CLOVER } from "../../../constants/authProvider"

const SupportComponent = ({ user, show }) => {
  const state = useContext(AppStateContext)

  const setting = {
    contactForm: {
      fields: [
        { id: "name", prefill: { "*": `${user.name}` } },
        { id: "email", prefill: { "*": `${user.email}` } },
        { id: "description", prefill: { "*": "How can we help you today?" } },
      ],
    },
    offset: {
      vertical: "50px",
    },
  }

  useEffect(() => {
    if (show && window.zE) {
      window.zE("webWidget", "show")
    }
  }, [show])

  return (
    <>
      {state.authProvider === AUTH_PROVIDER_CLOVER ? (
        <div className="zendeskContainer">
          <Zendesk
            defer
            zendeskKey={process.env.GATSBY_ZENDESK_KEY}
            {...setting}
            onLoaded={() => {
              if (show && window.zE) {
                window.zE("webWidget", "show")
              }
            }}
            hide
          />
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default SupportComponent
