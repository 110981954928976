import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import * as styles from "../dashboard/dashboard.module.scss"
import { Button, Card, CardActions, CardContent } from "@material-ui/core"
import GiftCardExample from "../../img/giftCardExample"
import GiftCardExampleDeluxe from "../../img/giftCardExampleDeluxe"
import GiftCardExampleDesigner from "../../img/giftCardExampleDesigner"
import OrderHistoryComponent from "../../orderHistory"
import { AppDispatchContext, AppStateContext } from "../../../context/appContext"
import {
  RESET_ORDER,
  UPDATE_NOTIFICATION,
  SET_ORDER_TYPE,
  SET_PROFILE_ADDRESS,
} from "../../../constants/actionTypes"
import Notification from "../../notification"
import { fetchProfileAddress } from "../../../services/profile"

const DashboardPage = () => {
  const dispatch = useContext(AppDispatchContext)
  const { user, profileAddress } = useContext(AppStateContext)

  let locState = null
  if (window && window.history) {
    // setting state in navigate ends up here
    locState = window.history.state
  }

  if (
    user &&
    user.gatewayMID &&
    (!profileAddress || (profileAddress && !profileAddress.shipAddress1))
  ) {
    //Empty profile address
    fetchProfileAddress(user.gatewayMID).then(profileAddress => {
      if (profileAddress && profileAddress.length > 0) {
        dispatch({
          type: SET_PROFILE_ADDRESS,
          payload: {
            ...profileAddress[0],
          },
        })
      }
    })
  }

  const orderSuccessfullySubmitted = window.sessionStorage.getItem("orderSuccessfullySubmitted")
  useEffect(() => {
    if (locState && locState.resetOrder && orderSuccessfullySubmitted) {
      dispatch({ type: RESET_ORDER })
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `success`,
          text: `Congratulations! Your order was successfully submitted. You may check the status of your order on the Gift Cards section.`,
        },
      })
      window.sessionStorage.removeItem("orderSuccessfullySubmitted")
    }
  }, [locState, dispatch, orderSuccessfullySubmitted])

  return (
    <>
      <Notification />
      <div className={styles.pageContainer}>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionFull}>
            <h3 className={styles.sectionTitle} data-test="dashboard-page-title">
              order cards
            </h3>
            <div className={styles.orderCards}>
              <Card className={`${styles.card} elevatedCard`}>
                <CardContent>
                  <h5>Standard Package</h5>
                  <div className={styles.image}>
                    <GiftCardExample />
                  </div>
                  <div className={styles.packageDescription}>
                    <p>Ready made templates to choose from </p>
                  </div>
                </CardContent>
                <CardActions className={styles.cardFooter}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.buttonPrimary}
                    data-test="dashboard-order-cards-button"
                    onClick={() => {
                      dispatch({
                        type: SET_ORDER_TYPE,
                        payload: "standard",
                      })
                      navigate("../order/card-front")
                    }}
                  >
                    Order standard
                  </Button>
                </CardActions>
              </Card>
              {process.env.GATSBY_SHOW_DELUXE === "1" && (
                <Card className={`${styles.card} elevatedCard`}>
                  <CardContent>
                    <h5>Deluxe Package</h5>
                    <div className={styles.image}>
                      <GiftCardExampleDeluxe />
                    </div>
                    <div className={styles.packageDescription}>
                      <p>Create your own design and upload a template</p>
                    </div>
                  </CardContent>
                  <CardActions className={styles.cardFooter}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={styles.buttonPrimary}
                      data-test="dashboard-order-deluxe-cards-button"
                      onClick={() => {
                        dispatch({
                          type: SET_ORDER_TYPE,
                          payload: "deluxe",
                        })
                        navigate("../order/card-front-deluxe")
                      }}
                    >
                      Order deluxe
                    </Button>
                  </CardActions>
                </Card>
              )}
              {process.env.GATSBY_SHOW_DESIGNER === "1" && (
                <Card className={`${styles.card} elevatedCard`}>
                  <CardContent>
                    <h5>Designer Package</h5>
                    <div className={styles.image}>
                      <GiftCardExampleDesigner />
                    </div>
                    <div className={styles.packageDescription}>
                      <p>Work with one of our Designers for a custom gift card design</p>
                    </div>
                  </CardContent>
                  <CardActions className={styles.cardFooter}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={styles.buttonPrimary}
                      data-test="dashboard-order-designer-cards-button"
                      onClick={() => {
                        dispatch({
                          type: SET_ORDER_TYPE,
                          payload: "designer",
                        })
                        navigate("../order/designer")
                      }}
                    >
                      Order designer
                    </Button>
                  </CardActions>
                </Card>
              )}
            </div>
          </div>
        </div>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionFull}>
            <OrderHistoryComponent pageType={"recent"} />
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardPage
