import React, { useContext, useEffect, useState } from "react"
import * as styles from "./location-management.module.scss"
import { AppDispatchContext, AppStateContext } from "../../context/appContext"
import { API, graphqlOperation } from "aws-amplify"
import { SET_LOCATION_MANAGEMENT } from "../../constants/actionTypes"
import TableContainer from "@material-ui/core/TableContainer"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import { IconButton, TableFooter } from "@material-ui/core"
import TablePagination from "@material-ui/core/TablePagination"
import { navigate } from "gatsby"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import FirstPageIcon from "@material-ui/icons/FirstPage"
import LastPageIcon from "@material-ui/icons/LastPage"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"

const ROWS_PER_PAGE = [5, 10, 25, 50]

const LocationManagementComponent = () => {
  const dispatch = useContext(AppDispatchContext)
  const state = useContext(AppStateContext)
  const { locationManagement = null, user } = state
  const [locations, setLocations] = useState(locationManagement)
  const [locationCount, setLocationCount] = useState(
    locationManagement !== null ? parseInt(locationManagement.length, 10) : 0
  )
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[2])

  useEffect(() => {
    const loadLocations = async () => {
      try {
        const listLocationsForManagement = /* GraphQL */ `
          query ListLocations {
            listLocations {
              locationID
              merchantId
              name
              programType
              address {
                addressLine1
                addressLine2
                addressLine3
                city
                state
                zipCode
              }
            }
          }
        `

        const {
          data: { listLocations },
        } = await API.graphql(graphqlOperation(listLocationsForManagement))

        setLocations(listLocations)
        setLocationCount(listLocations.length)

        dispatch({
          type: SET_LOCATION_MANAGEMENT,
          payload: listLocations,
        })
      } catch (err) {
        console.warn("listLocations failed", err)
        setLocations(false)
        setLocationCount(0)
      }
    }

    if (user) {
      loadLocations().then()
    }
  }, [dispatch, user])

  const onChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const onChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const paginationStyle = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(3),
    },
  }))

  function TablePaginationActions() {
    const theme = useTheme()
    const classes = paginationStyle()

    const onFirstPageClick = event => {
      onChangePage(event, 0)
    }

    const onPrevPageClick = event => {
      onChangePage(event, page - 1)
    }

    const onNextPageClick = event => {
      onChangePage(event, page + 1)
    }

    const onLastPageClick = event => {
      onChangePage(event, Math.max(0, Math.ceil(locationCount / rowsPerPage) - 1))
    }

    return (
      <div className={classes.root}>
        <IconButton onClick={onFirstPageClick} disabled={page === 0}>
          {theme.direction === "ltr" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        <IconButton onClick={onPrevPageClick} disabled={page === 0}>
          {theme.direction === "ltr" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={onNextPageClick}
          disabled={page >= Math.ceil(locationCount / rowsPerPage) - 1}
        >
          {theme.direction === "ltr" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={onLastPageClick}
          disabled={page >= Math.ceil(locationCount / rowsPerPage) - 1}
        >
          {theme.direction === "ltr" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
      </div>
    )
  }

  let locationRows = (
    <TableRow>
      <TableCell colSpan={3} align="center">
        Loading...
      </TableCell>
    </TableRow>
  )

  const handleRowClick = (event, locationId) => {
    navigate(`/app/locations/location-details/#` + locationId)
  }

  if (locations) {
    if (locationCount > 0) {
      locationRows = locations.map((location, idx) => {
        const locationId = location.locationID
        return (
          <TableRow
            key={idx}
            hover
            onClick={event => handleRowClick(event, locationId)}
            className={styles.tableRow}
          >
            <TableCell component="th" scope="row" className={styles.tablePadding}>
              {location.name}
            </TableCell>
            <TableCell component="th" scope="row">
              {locationId}
            </TableCell>
            <TableCell component="th" scope="row">
              {location.programType}
            </TableCell>
          </TableRow>
        )
      })
    } else {
      locationRows = (
        <TableRow>
          <TableCell colSpan={3} align="center">
            No locations found
          </TableCell>
        </TableRow>
      )
    }
  } else if (locations === false) {
    locationRows = (
      <TableRow>
        <TableCell colSpan={3} align="center">
          Error loading locations...
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <div className={styles.pageContainer}>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionFull}>
            <h3 className={styles.sectionTitle} data-test="dashboard-page-title">
              location management
            </h3>
            <TableContainer component={Paper} className={`elevatedCard`}>
              <Table className={styles.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.tablePadding}>
                      <b>Merchant Name</b>
                    </TableCell>
                    <TableCell>
                      <b>Location ID</b>
                    </TableCell>
                    <TableCell>
                      <b>Program Type</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{locationRows}</TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={ROWS_PER_PAGE}
                      colSpan={3}
                      count={locationCount}
                      labelRowsPerPage={"Locations per page:"}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={onChangePage}
                      onRowsPerPageChange={onChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  )
}

export default LocationManagementComponent
