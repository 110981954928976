import React, { useCallback, useState, useContext, useEffect } from "react"
import {
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  CircularProgress,
  Backdrop,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import { backdrop } from "./credit-card.module.scss"
import {
  luhnCheck,
  hasExpired,
  generateNonce,
  statesArray,
  getCardIcon,
  formatZipcode,
  getCardType,
} from "./utils"
import creditCardType from "credit-card-type"
import { AppDispatchContext, AppStateContext } from "../../context/appContext"
import { UPDATE_NOTIFICATION, UPDATE_PAYMENT_DETAILS } from "../../constants/actionTypes"
import Notification from "../notification"
import { checkEmptyFields } from "../../services/validate"
import { validateCharacters } from "../../utils/characterValidation"

const CreditCardComponent = ({
  submitCalled,
  parentCallBack,
  useShippingAddressCheckbox,
  parentBackdrop,
  endCallInProgress,
}) => {
  const dispatch = useContext(AppDispatchContext)
  const { order } = useContext(AppStateContext)
  const numeric_regex = /^[0-9\-\b]+$/
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [cardLogo, setCardLogo] = useState("")
  const [activeCard, setActiveCard] = useState({
    cardholder: "",
    cardType: "",
    cardNumber: "",
    expirationMonth: "",
    expirationYear: "",
    cvv: "",
    address1: "",
    address2: "",
    country: "United States",
    city: "",
    state: "",
    zipCode: "",
  })
  const [callInProgress, setCallInProgress] = useState(false)
  const [shippingAddress, setShippingAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
  })
  const [useShippingAddress, setUseShippingAddress] = useState(false)
  const [customBillingAddress, setCustomBillingAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
  })
  const [formErrors, setFormErrors] = useState({})
  const emptyFieldErrors = {
    cardholder: "Please check that the Name on Card has been completed.",
    cardNumber: "Required",
    cvv: "Required",
    expirationMonth: "Required",
    expirationYear: "Required",
    address1: "Please check that Address 1 has been completed.",
    city: "Please check that the City field has been completed.",
    state: "Please check that the State field has been completed.",
    zipCode: "Please check that the Zip Code field has been completed.",
  }

  const useStyles = makeStyles({
    expirationDateInputRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: "100%",
      "@media (min-width: 375px) and (max-width: 767px)": {
        flexDirection: "column",
        flexWrap: "nowrap",
      },
    },
    expirationDateInputCol: {
      display: "flex",
      flexDirection: "column",
      flexBasis: "100%",
      flex: "1",
      maxWidth: "30%",

      "@media (min-width: 375px) and (max-width: 767px)": {
        minWidth: "100%",
        marginBottom: "1rem",
      },
    },
    expiryInputStyle: {
      maxHeight: "1.5rem",
      minHeight: "1.5rem",
      minWidth: "9rem",
      "@media (min-width: 375px) and (max-width: 767px)": {
        minWidth: "100%",
      },
    },
    h3Style: {
      fontSize: "1rem",
    },
    h4Style: {
      fontSize: "0.9rem",
    },
    h5Style: {
      fontSize: "0.8rem",
      marginTop: "-0.5rem",
      color: "#666666",
    },
    inputStyle: {
      marginBottom: "1rem",
      maxWidth: "720px",
      minWidth: "33%",
      "@media (min-width: 375px) and (max-width: 767px)": {
        minWidth: "100%",
      },
    },
    inputLocationStyle: {
      marginBottom: "1rem",
      minWidth: "48%",
      "@media (min-width: 375px) and (max-width: 767px)": {
        minWidth: "100%",
      },
    },
    fakeInput: {
      position: "relative",
    },
    cardNumberInput: {
      minWidth: "100%",
      position: "relative",
    },
    cardIcon: {
      borderRadius: "5px",
      position: "absolute",
      maxWidth: "3rem",
      top: "0.7rem",
      right: "1rem",
    },
    stateInput: {
      "@media (min-width: 375px) and (max-width: 767px)": {
        display: "flex",
        marginBottom: "1rem",
        minWidth: "16rem",
      },
    },
    addressSplitInputCol: {
      display: "flex",
      flexDirection: "column",
      flexBasis: "100%",
      flex: "1",
      maxWidth: "48%",
      "@media (min-width: 375px) and (max-width: 767px)": {
        minWidth: "100%",
        marginBottom: "1rem",
      },
    },
    countryInput: {
      "@media (min-width: 375px) and (max-width: 767px)": {
        minWidth: "16rem",
      },
    },
    checkboxContainer: {
      marginBottom: "1rem",
      minWidth: "100%",
    },
    creditCardInputContainer: {
      "@media (min-width: 768px) and (max-width: 4000px)": {
        minWidth: "36rem !important",
      },
    },
    cardInfoTop: {
      minWidth: "720px",
      "& div": {
        maxWidth: "575px",
      },
    },
    billingAddressTop: {
      minWidth: "720px",
      "& div": {
        maxWidth: "575px",
      },
    },
    locationGrid: {
      maxWidth: "575px",
      minWidth: "575px",
    },
    creditCardGrid: {
      paddingBottom: "5rem",
    },
  })

  const classes = useStyles()

  const renderExpiryAndCVV = () => {
    const renderItems = items => {
      return items.map((item, index) => {
        return (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        )
      })
    }

    const renderYears = () => {
      const currentYear = new Date().getFullYear()
      const yearOptions = []
      for (let i = currentYear; i <= currentYear + 10; i++) {
        yearOptions.push(i)
      }
      return renderItems(yearOptions)
    }

    const renderMonths = () => {
      return renderItems(["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"])
    }

    return (
      <div className={classes.expirationDateInputRow}>
        <div className={classes.expirationDateInputCol}>
          <TextField
            id="expirationMonth"
            required
            className={classes.expiryInputStyle}
            name="expirationMonth"
            label="Expiration Month"
            variant="filled"
            defaultValue=""
            select
            onChange={e => handleCreditCardChange("expirationMonth", e)}
            onBlur={e => handleCreditCardBlur("expirationMonth", e)}
            helperText={formErrors.expirationMonth}
            error={formErrors.expirationMonth ? true : undefined}
          >
            {renderMonths()}
          </TextField>
        </div>
        <div className={classes.expirationDateInputCol}>
          <TextField
            id="expirationYear"
            required
            className={classes.expiryInputStyle}
            name="expirationYear"
            label="Expiration Year"
            variant="filled"
            defaultValue=""
            select
            onChange={e => handleCreditCardChange("expirationYear", e)}
            onBlur={e => handleCreditCardBlur("expirationYear", e)}
            helperText={formErrors.expirationYear}
            error={formErrors.expirationYear ? true : undefined}
          >
            {renderYears()}
          </TextField>
        </div>
        <div className={classes.expirationDateInputCol}>
          <TextField
            id="cvv"
            required
            className={classes.inputStyle}
            name="cvv"
            label="CVV"
            variant="filled"
            type="number"
            // onInput={e => {
            //   e.target.value = Math.max(0, parseInt(e.target.value))
            //     .toString()
            //     .slice(0, getCvvSize())
            // }}
            pattern=".{3,4}"
            onChange={e => handleCreditCardChange("cvv", e)}
            onBlur={e => handleCreditCardBlur("cvv", e)}
            helperText={formErrors.cvv}
            error={formErrors.cvv ? true : undefined}
            value={activeCard["cvv"] || ""}
          />
        </div>
      </div>
    )
  }

  const renderStates = () => {
    return statesArray.sort().map((item, index) => {
      return (
        <MenuItem key={index} value={item}>
          {item}
        </MenuItem>
      )
    })
  }

  const getCvvSize = () => {
    const cardTypes = creditCardType(activeCard.cardNumber)
    const defaultSize = 3

    if (!cardTypes || cardTypes.length === 0 || cardTypes.length > 3) {
      return defaultSize
    } else {
      return cardTypes[0].code && cardTypes[0].code.size ? cardTypes[0].code.size : defaultSize
    }
  }

  const validateForm = (cardNumber, cvv, zipCode, expirationMonth) => {
    let billingError = validateBillingAddressFields("zipCode", zipCode, undefined)
    let cvvError = validateCreditCardFields("cardNumber", cardNumber, undefined)
    let expiryError = validateCreditCardFields("expirationMonth", expirationMonth, undefined)

    parentCallBack(
      !checkEmptyFields() &&
        cardNumber.split("-").join("").length >= 13 &&
        cardNumber.split("-").join("").length <= 19 &&
        cvv.length >= 3 &&
        cvv.length <= 4 &&
        luhnCheck(cardNumber) &&
        billingError === undefined &&
        cvvError === undefined &&
        (expiryError === undefined ||
          Object.values(expiryError).every(x => x === null || x === undefined))
    )
  }

  const handleCreditCardChange = (fieldName, e) => {
    if (fieldName === "cardNumber") {
      setCardLogo(getCardIcon(creditCardType(e.target.value)))
      if (e.target.value === "" || numeric_regex.test(e.target.value)) {
        setActiveCard({ ...activeCard, [fieldName]: e.target.value.split("-").join("") })
      }
    } else if (fieldName === "cvv") {
      if (e.target.value === "" || numeric_regex.test(e.target.value)) {
        setActiveCard({
          ...activeCard,
          [fieldName]: e.target.value.toString().slice(0, getCvvSize()),
        })
      }
    } else {
      setActiveCard({ ...activeCard, [fieldName]: e.target.value })
    }
  }

  const handleCreditCardBlur = (fieldName, e) => {
    let { value, attributes } = e.target
    let error_texts = validateCreditCardFields(fieldName, value, attributes)

    if (error_texts !== undefined) {
      setFormErrors({ ...formErrors, ...error_texts })
    } else {
      setFormErrors({ ...formErrors, [fieldName]: undefined })
    }
  }

  const validateCreditCardFields = (fieldName, value, attributes) => {
    if (
      value === "" &&
      ((attributes && "required" in attributes) ||
        ["expirationMonth", "expirationYear"].indexOf(fieldName) > -1)
    ) {
      return {
        [fieldName]: !(fieldName in emptyFieldErrors) ? "Required" : emptyFieldErrors[fieldName],
      }
    } else if (fieldName === "cardNumber" && !luhnCheck(activeCard.cardNumber)) {
      return { [fieldName]: "Invalid Card Number" }
    } else if (
      luhnCheck(activeCard.cardNumber) &&
      ((fieldName === "cvv" && getCvvSize() !== value.length) ||
        (fieldName === "cardNumber" &&
          activeCard.cvv.length > 0 &&
          getCvvSize() !== activeCard.cvv.length))
    ) {
      return { cvv: "Invalid CVV", cardNumber: undefined }
    } else if (
      (fieldName === "expirationMonth" || fieldName === "expirationYear") &&
      activeCard.expirationMonth !== "" &&
      activeCard.expirationYear !== ""
    ) {
      if (hasExpired(activeCard.expirationMonth, activeCard.expirationYear)) {
        return {
          expirationMonth: "This card is expired",
        }
      } else {
        return {
          expirationMonth: undefined,
          expirationYear: undefined,
        }
      }
    }

    return undefined
  }

  const handleShippingAddressCheck = e => {
    const elements = document.querySelectorAll("[required]")
    setUseShippingAddress(e.target.checked)
    let error_texts = {}

    if (e.target.checked) {
      setActiveCard({ ...activeCard, ...shippingAddress })
      elements.forEach(element => {
        if (element.name in shippingAddress) {
          error_texts[element.name] = validateBillingAddressFields(
            element.name,
            shippingAddress[element.name],
            element.attributes
          )
        }
      })
    } else {
      setActiveCard({ ...activeCard, ...customBillingAddress })
      elements.forEach(element => {
        if (element.name in customBillingAddress) {
          error_texts[element.name] = validateBillingAddressFields(
            element.name,
            customBillingAddress[element.name],
            element.attributes
          )
        }
      })
    }
    setFormErrors({ ...formErrors, ...error_texts })
  }

  const handleBillingAddressChange = (fieldName, e) => {
    if (["change", "click"].indexOf(e.type) > -1 && fieldName in customBillingAddress) {
      if (useShippingAddress) {
        setCustomBillingAddress({ ...shippingAddress, [fieldName]: e.target.value })
        setUseShippingAddress(false)
      } else {
        setCustomBillingAddress({ ...customBillingAddress, [fieldName]: e.target.value })
      }
    }
    if (fieldName === "zipCode") {
      const formattedZip = formatZipcode(e.target.value)
      e.target.value = formattedZip
    }
    setActiveCard({ ...activeCard, [fieldName]: e.target.value })
  }

  const handleBillingAddressBlur = (fieldName, e) => {
    let { value, attributes } = e.target
    const error_text = validateBillingAddressFields(fieldName, value, attributes)
    setFormErrors({ ...formErrors, [fieldName]: error_text })
  }

  const validateBillingAddressFields = (fieldName, value, attributes) => {
    if (
      value === "" &&
      ((attributes && "required" in attributes) || ["state"].indexOf(fieldName) > -1)
    ) {
      return !(fieldName in emptyFieldErrors) ? "Required" : emptyFieldErrors[fieldName]
    } else if (fieldName === "city" && /[^a-zA-Z\s]/.test(value.trim())) {
      return "City may only contain letters."
    } else if (fieldName === "zipCode" && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value.trim())) {
      return "Please Enter a Valid Zipcode."
    }

    return undefined
  }

  const formatCardNumber = () => {
    let cardNumber = activeCard["cardNumber"].toString()
    const cardType = creditCardType(activeCard["cardNumber"])

    if (
      cardType.length > 0 &&
      cardType[0].gaps &&
      cardType[0].gaps.length > 0 &&
      activeCard["cardNumber"].length > cardType[0].gaps[0]
    ) {
      let shift = 0
      cardType[0].gaps.forEach(function (pos) {
        if (activeCard["cardNumber"].length > pos) {
          cardNumber = cardNumber.slice(0, pos + shift) + "-" + cardNumber.slice(pos + shift)
          shift++
        }
      })
    }

    return cardNumber
  }

  const handleSubmit = useCallback(async () => {
    try {
      setShowBackdrop(!parentBackdrop)

      const nonceData = {
        name: activeCard.cardholder,
        number: activeCard.cardNumber,
        cvc: activeCard.cvv,
        expMonth: activeCard.expirationMonth,
        expYear: activeCard.expirationYear,
        addressLine1: activeCard.address1,
        addressLine2: activeCard.address2,
        city: activeCard.city,
        state: activeCard.state,
        zipCode: activeCard.zipCode,
      }
      const creditCardNonce = await generateNonce(nonceData)
      const paymentDetails = {
        cardholder: activeCard.cardholder,
        cardType: getCardType(creditCardType(activeCard.cardNumber)),
        lastFour: activeCard.cardNumber.slice(-4),
        billingAddress1: activeCard.address1,
        billingAddress2: activeCard.address2,
        billingCity: activeCard.city,
        billingState: activeCard.state,
        billingZipCode: activeCard.zipCode,
        nonce: creditCardNonce,
      }
      dispatch({
        type: UPDATE_PAYMENT_DETAILS,
        payload: {
          ...paymentDetails,
        },
      })
      parentCallBack(paymentDetails)
    } catch (err) {
      parentCallBack(false)
      setShowBackdrop(false)
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          text: `Form: ${err.message}`,
        },
      })
      setCallInProgress(false)
    }
  }, [activeCard, dispatch, parentCallBack, parentBackdrop])

  if (submitCalled && !callInProgress) {
    if (
      luhnCheck(activeCard.cardNumber) &&
      hasExpired(activeCard.expirationMonth, activeCard.expirationYear)
    ) {
      parentCallBack(false)
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          text: `Invalid Expiration Date`,
        },
      })
    } else if (luhnCheck(activeCard.cardNumber) && getCvvSize() !== activeCard.cvv.length) {
      parentCallBack(false)
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          text: `Invalid Card Details`,
        },
      })
    } else {
      setCallInProgress(true)
      handleSubmit().then()
    }
  }

  useEffect(() => {
    if (
      useShippingAddressCheckbox &&
      order.shipping.shipToAddress &&
      order.shipping.shipToAddress.shipAddress1
    ) {
      let shipping_data = shippingAddress
      for (const address_key in order.shipping.shipToAddress) {
        if (address_key.startsWith("ship")) {
          //The billing address should not contain the +4 zip digits
          if (
            address_key === "shipZipCode" &&
            order.shipping.shipToAddress[address_key].length > 5
          ) {
            shipping_data["zipCode"] = order.shipping.shipToAddress[address_key].substring(0, 5)
          } else {
            let shipping_key = address_key.substring(4)
            shipping_data[shipping_key.charAt(0).toLowerCase() + shipping_key.substring(1)] =
              order.shipping.shipToAddress[address_key]
          }
        }
      }
      setShippingAddress({ ...shipping_data })
    }

    if (activeCard) {
      validateForm(
        activeCard.cardNumber,
        activeCard.cvv,
        activeCard.zipCode,
        activeCard.expirationMonth
      )
    }

    if (endCallInProgress) {
      setCallInProgress(false)
    }
  }, [order.shipping.shipToAddress, activeCard, endCallInProgress]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Backdrop className={backdrop} open={showBackdrop}>
        <CircularProgress />
      </Backdrop>
      <Notification />
      <div className="pageContainer row">
        <h3 className={classes.h3Style}>ENTER PAYMENT INFORMATION</h3>
        <div className="column leftSide">
          <Grid
            container
            className={classes.creditCardGrid}
            direction="row"
            justifyContent="flex-start"
          >
            <Grid container item sm={12} xs={12} direction="column">
              <FormControl>
                <div className={classes.creditCardInputContainer}>
                  <h4 className={classes.h4Style}>Enter New Card Information</h4>
                  <TextField
                    id="cardholder"
                    required
                    className={`${classes.inputStyle} ${classes.cardInfoTop}`}
                    name="cardholder"
                    label="Cardholder Name"
                    variant="filled"
                    onChange={e => handleCreditCardChange("cardholder", e)}
                    onBlur={e => handleCreditCardBlur("cardholder", e)}
                    helperText={formErrors.cardholder}
                    error={formErrors.cardholder ? true : undefined}
                    value={activeCard["cardholder"] || ""}
                  />
                  <div className={classes.fakeInput}>
                    <TextField
                      id="cardNumber"
                      required
                      className={`${classes.inputStyle} ${classes.cardNumberInput} ${classes.cardInfoTop}`}
                      name="cardNumber"
                      label="Card Number"
                      variant="filled"
                      type="tel"
                      inputProps={{ maxLength: 22, minLength: 13 }}
                      onChange={e => handleCreditCardChange("cardNumber", e)}
                      onBlur={e => handleCreditCardBlur("cardNumber", e)}
                      helperText={formErrors.cardNumber}
                      error={formErrors.cardNumber ? true : undefined}
                      value={formatCardNumber()}
                    />
                    {cardLogo ? (
                      <img src={cardLogo} className={classes.cardIcon} alt="Card Type Logo" />
                    ) : (
                      ""
                    )}
                  </div>
                  <Grid
                    container
                    className={classes.inputStyle}
                    direction="row"
                    justifyContent="space-between"
                  >
                    {renderExpiryAndCVV()}
                  </Grid>
                </div>
              </FormControl>
              <h4 className={classes.h4Style}>Enter Billing Address</h4>
              <h5 className={classes.h5Style}>
                Your billing address should match the address on your credit card statement.
              </h5>
              <FormControl>
                <Grid container item xs={12} direction="column">
                  {useShippingAddressCheckbox && (
                    <FormControlLabel
                      className={`${classes.checkboxContainer}`}
                      control={
                        <Checkbox
                          name="defaultAddressCheckbox"
                          checked={useShippingAddress}
                          onChange={handleShippingAddressCheck}
                        />
                      }
                      label="Same as shipping address"
                    />
                  )}
                  <TextField
                    id="address1"
                    required
                    className={`${classes.inputStyle} ${classes.billingAddressTop}`}
                    name="address1"
                    label="Address 1"
                    variant="filled"
                    onChange={e => handleBillingAddressChange("address1", e)}
                    onBlur={e => handleBillingAddressBlur("address1", e)}
                    value={activeCard["address1"] || ""}
                    inputProps={{ maxLength: 30 }}
                    helperText={formErrors.address1}
                    onKeyDown={validateCharacters}
                    error={formErrors.address1 ? true : undefined}
                  />
                  <TextField
                    id="address2"
                    className={`${classes.inputStyle} ${classes.billingAddressTop}`}
                    name="address2"
                    label="Address 2"
                    variant="filled"
                    onChange={e => handleBillingAddressChange("address2", e)}
                    onBlur={e => handleBillingAddressBlur("address2", e)}
                    value={activeCard["address2"] || ""}
                    inputProps={{ maxLength: 30 }}
                    onKeyDown={validateCharacters}
                  />
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    className={`${classes.locationGrid}`}
                  >
                    <div className={classes.addressSplitInputCol}>
                      <TextField
                        id="city"
                        required
                        className={`${classes.inputStyle}, ${classes.inputLocationStyle}`}
                        name="city"
                        label="City"
                        variant="filled"
                        onChange={e => handleBillingAddressChange("city", e)}
                        onBlur={e => handleBillingAddressBlur("city", e)}
                        value={activeCard["city"] || ""}
                        inputProps={{ maxLength: 30 }}
                        helperText={formErrors.city}
                        onKeyDown={validateCharacters}
                        error={formErrors.city ? true : undefined}
                      />
                    </div>
                    <div className={classes.addressSplitInputCol}>
                      <TextField
                        id="state"
                        required
                        className={`${classes.inputStyle}, ${classes.inputLocationStyle}, ${classes.stateInput}`}
                        name="state"
                        label="State"
                        variant="filled"
                        select
                        inputProps={{ maxLength: 2 }}
                        onChange={e => handleBillingAddressChange("state", e)}
                        onBlur={e => handleBillingAddressBlur("state", e)}
                        value={activeCard["state"] || ""}
                        helperText={formErrors.state}
                        error={formErrors.state ? true : undefined}
                      >
                        {renderStates()}
                      </TextField>
                    </div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    className={`${classes.locationGrid}`}
                  >
                    <div className={classes.addressSplitInputCol}>
                      <TextField
                        id="zipCode"
                        required
                        className={`${classes.inputStyle}, ${classes.inputLocationStyle}`}
                        name="zipCode"
                        label="Zip Code"
                        variant="filled"
                        type="tel"
                        inputProps={{ maxLength: 10, minLength: 5 }}
                        onChange={e => handleBillingAddressChange("zipCode", e)}
                        onBlur={e => handleBillingAddressBlur("zipCode", e)}
                        value={activeCard["zipCode"] || ""}
                        helperText={formErrors.zipCode}
                        error={formErrors.zipCode ? true : undefined}
                      />
                    </div>
                    <div className={classes.addressSplitInputCol}>
                      <TextField
                        id="country"
                        disabled={true}
                        className={`${classes.inputStyle}, ${classes.inputLocationStyle}, ${classes.countryInput}`}
                        name="country"
                        label="Country"
                        variant="filled"
                        value="United States"
                        onChange={e => handleBillingAddressChange("country", e)}
                        onBlur={e => handleBillingAddressBlur("country", e)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default CreditCardComponent
