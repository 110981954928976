import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ children, location }) => (
  <Layout children={children} location={location}>
    <Seo title="Welcome to PCO" />
  </Layout>
)

export default NotFoundPage
