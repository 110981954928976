export const validateAddressFields = (fieldName, value, attributes) => {
  const statePattern =
    /^(?:A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])*$/gim

  if (
    value === "" &&
    ((attributes && "required" in attributes) || ["shipState"].indexOf(fieldName) > -1)
  ) {
    return (
      "The " + (fieldName === "label" ? fieldName : fieldName.substring(4)) + " field is required."
    )
  } else if (fieldName === "shipCity" && /[^a-zA-Z\s]/g.test(value.trim())) {
    return "City may only contain letters."
  } else if (fieldName === "shipZipCode" && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value.trim())) {
    return "Please Enter a Valid Zipcode."
  } else if (fieldName === "shipState" && !statePattern.test(value)) {
    return "Please enter a valid 2 letter State code. eg CA or MA"
  } else if (fieldName === "shipAddress1" || fieldName === "shipAddress2") {
    if (/\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i.test(value.trim())) {
      return "P.O. Boxes are not permitted"
    }
  }

  return undefined
}
