import React from "react"
import * as styles from "./orderNavigation.module.scss"
import { Button } from "@material-ui/core"

// export const OrderNavigation = (nextFunction, label, previousLabel) => {
export const OrderNavigation = (previousPage, previousLabel, nextPage, label, disabledCheck) => {
  return (
    <div className={styles.buttonContainer}>
      {previousLabel ? (
        <div className={styles.buttonColumn}>
          <Button
            variant={`contained`}
            className={`buttonSecondary`}
            id={styles.previousButton}
            onClick={previousPage}
          >
            {previousLabel}
          </Button>
        </div>
      ) : null}
      <div className={styles.buttonColumn}>
        <Button
          variant={`contained`}
          className={`buttonPrimary`}
          id={styles.nextButton}
          onClick={nextPage}
          disabled={disabledCheck}
        >
          {label}
        </Button>
      </div>
    </div>
  )
}
