import React from "react"
import { css } from "@emotion/react"

/**
 * LoadingSpinner
 * Usage:
 * import { LoadingSpinner } from "../../../../utils/loadingSpinner"
 * ...
 * const [isLoading, setIsLoading] = useState(false)
 * ...
 * setIsLoading(true) || setIsLoading(false)
 * ...
 * //HTML
 * //<div>
 * {isLoading ? <LoadingSpinner /> : ""}
 * //</div>
 **/

const spinnerContainer = css`
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const loadingSpinnerCss = css`
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const LoadingSpinner = () => {
  return (
    <div css={spinnerContainer}>
      <div css={loadingSpinnerCss}></div>
    </div>
  )
}
