import React, { useCallback, useContext, useState } from "react"
import * as styles from "../reviewOrder/review-order.module.scss"
import { isBrowser } from "../../../../services/auth"
import { CircularProgress, Backdrop } from "@material-ui/core"
import { Amplify, API, graphqlOperation } from "aws-amplify"
import { submitOrder } from "../../../../graphql/mutations"
import { AppDispatchContext, AppStateContext } from "../../../../context/appContext"
import { navigate } from "gatsby-link"
import { SET_ORDER_HISTORY, UPDATE_NOTIFICATION } from "../../../../constants/actionTypes"
import { initConfig } from "../../../../services/amplify"
import OrderSummaryComponent from "../../../orderSummary"
import Notification from "../../../notification"
import { getCardType, getTotalCost } from "../../../../services/card"
import agreementCheck from "../agreementCheck/index.js"
import { OrderNavigation } from "../../../orderNavigation"
import { getSubmitOrderJson } from "../../../../utils/submitOrderData"

Amplify.configure(initConfig())

const ReviewOrderPage = () => {
  const dispatch = useContext(AppDispatchContext)
  const {
    order: appOrder,
    orderHistory,
    user,
    profileAddress,
    authProvider,
  } = useContext(AppStateContext)
  const [pricingChecked, setPricingChecked] = useState(false)
  const [showBackdrop, setShowBackdrop] = useState(false)
  const orderPaymentMethod = appOrder.payment.paymentMethod

  const nextLabel = orderPaymentMethod === "BEFORE_CREDIT_CARD" ? "Payment" : "Order Cards"
  const prevLabel = "Back: Shipping"
  const nextButtonDisabled = orderPaymentMethod !== "BEFORE_CREDIT_CARD" ? !pricingChecked : false

  // Warn users before: reload, close window, close tab
  window.addEventListener("beforeunload", function (e) {
    const pathname = window.location.pathname
    //Paths for orders: card-front, card-back accessories shipping review-order payment
    if (
      pathname === "/app/order/card-front" ||
      pathname === "/app/order/card-front-deluxe" ||
      pathname === "/app/order/designer" ||
      pathname === "/app/order/card-back" ||
      pathname === "/app/order/accessories" ||
      pathname === "/app/checkout/shipping" ||
      pathname === "/app/checkout/review-order" ||
      pathname === "/app/checkout/payment" ||
      pathname === "/app/checkout/payment/" ||
      pathname === "/app/order/designer"
    ) {
      e.returnValue = ""
    }
  })

  const handleSubmit = useCallback(async () => {
    if (!pricingChecked && user.paymentMethod !== "BEFORE_CREDIT_CARD") {
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `info`,
          text: `Please check the checkbox to accept the pricing.`,
        },
      })
      return
    }
    setShowBackdrop(true)

    try {
      if (process.env.GATSBY_SHOW_PAYMENT === "1" && orderPaymentMethod === "BEFORE_CREDIT_CARD") {
        navigate("/app/checkout/payment")
      } else {
        const inputOrder = await getSubmitOrderJson(
          authProvider,
          user,
          appOrder,
          profileAddress,
          {},
          {}
        )
        const artWorkUrl = inputOrder.items[0].artWorkText
        const result = await API.graphql(graphqlOperation(submitOrder, { input: inputOrder }))

        const {
          data: { submitOrder: saved },
        } = result

        // add to order history here so we don't have to re-query
        const newOrderHistory = orderHistory.slice()
        newOrderHistory.unshift({
          date: saved.createdAt,
          status: saved.status,
          cardType: getCardType(appOrder.cards.cardType),
          artWorkUrl: artWorkUrl,
          paymentMethod: user.paymentMethod,
          totalCost: await getTotalCost(appOrder, profileAddress),
        })
        dispatch({
          type: SET_ORDER_HISTORY,
          payload: newOrderHistory,
        })

        if (isBrowser()) {
          window.sessionStorage.removeItem("pcoState")
          window.sessionStorage.setItem("orderSuccessfullySubmitted", "1")
        }
        setShowBackdrop(false)

        // reset state and redirect to order summary
        navigate(`/app/orders/order-summary/#` + window.btoa(saved.createdAt), {
          state: { resetOrder: 1 },
        })
      }
    } catch (err) {
      console.warn(err)
      setShowBackdrop(false)
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          text: `Error submitting order`,
        },
      })
    }
  }, [
    pricingChecked,
    appOrder,
    orderHistory,
    dispatch,
    user,
    orderPaymentMethod,
    profileAddress,
    authProvider,
  ])

  const navigateBack = () => {
    navigate("/app/checkout/shipping")
  }

  const handlePricingCheck = () => {
    setPricingChecked(prev => !prev)
  }

  const paymentMethodCheck = () => {
    let method = user.paymentMethod
    if (method === "AFTER_MERCHANT_ACCOUNT") {
      return true
    }
  }

  return (
    <>
      <Backdrop className={styles.backdrop} open={showBackdrop}>
        <CircularProgress />
      </Backdrop>
      <Notification />
      <div className={styles.sectionContainer}>
        <div className={styles.sectionLeft}>
          <h2 className={styles.sectionTitle}>review order</h2>
          <OrderSummaryComponent show_promo={true} />
        </div>
        <div className={styles.sectionRight}>
          <div className={styles.checkboxContainer}>
            {paymentMethodCheck()
              ? agreementCheck(pricingChecked, handlePricingCheck, authProvider)
              : null}
          </div>
          {OrderNavigation(navigateBack, prevLabel, handleSubmit, nextLabel, nextButtonDisabled)}
        </div>
      </div>
    </>
  )
}

export default ReviewOrderPage
