import { API, graphqlOperation } from "aws-amplify"
import { updateOrder } from "../graphql/mutations"
import { getPromoPercentageValue, getFreeShippingValue } from "./card"
import { getCoupon, orderByCouponAndValidationParams } from "../graphql/queries"

const getOrder = /* GraphQL */ `
  query GetOrder($PK: String!, $createdAt: AWSDateTime!) {
    getOrder(PK: $PK, createdAt: $createdAt) {
      items {
        artWorkText
        artWorkBackText
        type
      }
      pending
      status
      state
      orderId
      vlOrderId
      payment {
        paymentMethod
        paymentDetails {
          cardholder
          cardType
          lastFour
          billingAddress1
          billingAddress2
          billingCity
          billingState
          billingZipCode
        }
        promoDetails {
          promoCode
          value
          type
          text
          discount
        }
      }
    }
  }
`

const getOrderByCreatedAt = async (user, createdAt) => {
  const params = {
    PK: user.gatewayMID,
    createdAt,
    sortDirection: "DESC",
  }
  const response = await API.graphql(graphqlOperation(getOrder, params))
  return response.data.getOrder
}

export async function loadOrderByCreatedAt(user, createdAt) {
  const summary = await getOrderByCreatedAt(user, createdAt)
  if (summary.state === null) throw Error("Failed to load order state")
  const recent_order = JSON.parse(summary.state).order
  recent_order.pending = summary.pending
  recent_order.status = summary.status
  recent_order.vlOrderId = summary.vlOrderId
  recent_order.orderId = "orderId" in summary ? summary.orderId : ""
  recent_order.createdAt = createdAt
  recent_order.payment = summary.payment
  const item = summary.items.find(element => element.type !== null)

  if (
    item.type !== "DESIGNER" ||
    (item.type === "DESIGNER" && recent_order.status === "COMPLETE")
  ) {
    const item_assets = summary.items.find(element => element.artWorkText !== null)
    recent_order.cards.cardFront.imgData = item_assets.artWorkText
    recent_order.cards.cardBack.imgData = item_assets.artWorkBackText
  }
  return recent_order
}

export async function cancelByCreatedAt(user, createdAt) {
  const inputOrder = {
    PK: user.gatewayMID,
    createdAt,
    status: "CANCELLED",
    pending: null,
  }
  const condition = { pending: { attributeExists: true, eq: "1" } }
  await API.graphql(graphqlOperation(updateOrder, { input: inputOrder, condition }))
}

export const formatPaymentDetails = paymentObj => {
  const payAfterAccountLabel = "Merchant Account"
  if (!paymentObj || paymentObj.paymentMethod === undefined) {
    return payAfterAccountLabel
  }

  const labels = {
    BEFORE_CREDIT_CARD: "Credit Card",
    BEFORE_CLOVER_ACCOUNT: "Clover Account",
    AFTER_MERCHANT_ACCOUNT: payAfterAccountLabel,
  }
  return labels[paymentObj.paymentMethod]
}

export const getDiscountValue = (order, promoDetails) => {
  let discount_value = 0

  if ("type" in promoDetails && promoDetails.type === "dollar") {
    discount_value = promoDetails.value
  } else if ("type" in promoDetails && promoDetails.type === "percentage") {
    discount_value = getPromoPercentageValue(order, promoDetails.value)
  } else if ("type" in promoDetails && promoDetails.type === "free_shipping") {
    discount_value = getFreeShippingValue(order)
  }

  return discount_value
}

export const getPromoDetails = async (promoCode, authProvider, order, user) => {
  const params = {
    PK: promoCode,
  }
  let validationParams = {}

  const {
    data: { getCoupon: coupon },
  } = await API.graphql(graphqlOperation(getCoupon, params))

  let promoValidated = await validatePromoCode(coupon, user, order, authProvider, validationParams)

  if (!promoValidated || !promoValidated.success) {
    let promoError = "Sorry, this promo code is not valid"
    if (promoValidated && promoValidated.error) {
      promoError = promoValidated.error
    }
    return {
      error: promoError,
    }
  }

  let promoDetails = {}
  const promoValue = coupon.couponDiscount

  if (coupon.couponType === "PERCENTAGE" && promoValue < 100) {
    promoDetails = {
      type: "percentage",
      value: promoValue,
      text: promoValue + "% off your order!",
    }
  } else if (coupon.couponType === "DOLLAR") {
    promoDetails = {
      type: "dollar",
      value: promoValue * 100,
      text: "$" + promoValue + " off your order!",
    }
  } else if (coupon.couponType === "FREE_SHIPPING") {
    promoDetails = {
      type: "free_shipping",
      value: promoValue,
      text: "Free shipping!",
    }
  }

  if (Object.keys(promoDetails).length > 0) {
    if ("value" in promoDetails) {
      const discount_value = getDiscountValue(order, promoDetails)

      return {
        discount: discount_value,
        text: "text" in promoDetails ? promoDetails.text : "",
        type: "type" in promoDetails ? promoDetails.type : "",
        value: "value" in promoDetails ? promoDetails.value : 0,
        params: getValidationParamsString(validationParams),
      }
    }
  }

  return false
}

async function validatePromoCode(coupon, user, order, authProvider, validationParams) {
  if (
    isPromoActive(coupon) &&
    isPromoAssignedForAuthProvider(coupon, authProvider, validationParams) &&
    isPromoAssignedToMIDs(coupon, user, validationParams) &&
    isPromoAssignedForOrderType(coupon, order.cards.cardType, validationParams)
  ) {
    return await isPromoMultiUse(coupon, user, validationParams)
  }

  return {
    success: false,
    error: "Sorry, this promo code is not valid",
  }
}

async function isPromoMultiUse(coupon, user, validationParams) {
  const promoCode = coupon?.couponCode
  const multiUse = coupon && coupon.multiUse ? coupon.multiUse : false

  if (multiUse) {
    return {
      success: true,
    }
  } else {
    const params = {
      couponCode: promoCode,
      couponValidationParams: { eq: getValidationParamsString(validationParams) },
      filter: {
        status: { ne: "ERROR" },
        and: [{ status: { ne: "CANCELLED" } }],
      },
    }
    const {
      data: { orderByCouponAndValidationParams: orders },
    } = await API.graphql(graphqlOperation(orderByCouponAndValidationParams, params))

    if (orders.items && Array.isArray(orders.items) && orders.items.length === 0) {
      return {
        success: true,
      }
    } else {
      return {
        success: false,
        error: "Sorry, this promo code has already been redeemed",
      }
    }
  }
}

function isPromoActive(coupon) {
  const promoStatus = coupon?.couponStatus
  const promoStartDate = coupon && coupon.beginsAt ? new Date(coupon.beginsAt) : null
  const promoEndDate = coupon && coupon.expiresAt ? new Date(coupon.expiresAt) : null
  const today = new Date()

  if (promoStatus && promoStatus === "ACTIVE") {
    if (promoStartDate && promoEndDate && today >= promoStartDate && today < promoEndDate) {
      return true
    }
  }

  console.info("Promo not active")
  return false
}

function isPromoAssignedToMIDs(coupon, user, validationParams) {
  const closedLoopMIDs = coupon && coupon.closedLoopMIDs ? coupon.closedLoopMIDs : false
  const gatewayMIDs = coupon && coupon.gatewayMIDs ? coupon.gatewayMIDs : false

  if (closedLoopMIDs && Array.isArray(closedLoopMIDs) && closedLoopMIDs.length > 0) {
    validationParams["closedLoopMID"] = user["closedLoopMID"]
    if (!("closedLoopMID" in user) || !closedLoopMIDs.includes(user["closedLoopMID"])) {
      console.info("Promo not valid for the closedLoopMID: " + user["closedLoopMID"])
      return false
    }
  }

  if (gatewayMIDs && Array.isArray(gatewayMIDs) && gatewayMIDs.length > 0) {
    validationParams["gatewayMID"] = user["gatewayMID"]
    if (!("gatewayMID" in user) || !gatewayMIDs.includes(user["gatewayMID"])) {
      console.info("Promo not valid for the gatewayMID: " + user["gatewayMID"])
      return false
    }
  }

  return true
}

function isPromoAssignedForAuthProvider(coupon, authProvider, validationParams) {
  const authProviders = coupon && coupon.authProviders ? coupon.authProviders : false

  if (authProviders && Array.isArray(authProviders) && authProviders.length > 0) {
    validationParams["authProvider"] = authProvider
    if (!authProviders.includes(authProvider)) {
      console.info("Promo not valid for the Auth Provider: " + authProvider)
      return false
    }
  }

  return true
}

function isPromoAssignedForOrderType(coupon, orderType, validationParams) {
  const orderTypes = coupon && coupon.orderTypes ? coupon.orderTypes : false

  if (orderTypes && Array.isArray(orderTypes) && orderTypes.length > 0) {
    validationParams["orderType"] = orderType.toUpperCase()
    if (!orderTypes.includes(orderType.toUpperCase())) {
      console.info("Promo not valid for the Order Type: " + orderType.toUpperCase())
      return false
    }
  }

  return true
}

function getValidationParamsString(validationParams) {
  return (
    (validationParams && validationParams?.authProvider ? validationParams.authProvider : "") +
    "#" +
    (validationParams && validationParams?.closedLoopMID ? validationParams.closedLoopMID : "") +
    "#" +
    (validationParams && validationParams?.gatewayMID ? validationParams.gatewayMID : "") +
    "#" +
    (validationParams && validationParams?.orderType ? validationParams.orderType : "")
  )
}
