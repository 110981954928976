import { Backdrop, Box, Button, CircularProgress, Grid, TextField } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import Divider from "@material-ui/core/Divider"
import { Close, CloudUpload, Done } from "@material-ui/icons"
import { API, graphqlOperation, Storage } from "aws-amplify"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useDropzone } from "react-dropzone"
import { v4 as uuidv4 } from "uuid"
import validator from "validator"
import { UPDATE_NOTIFICATION } from "../../../constants/actionTypes"
import { AppDispatchContext, AppStateContext } from "../../../context/appContext"
import {
  getMerchantConversionJob,
  giftConversionFilePreValidator,
  giftConversionValidator,
} from "../../../graphql/mutations"
import Notification from "../../notification"
import * as styles from "../conversion/conversion_new.module.scss"
import Confirmation from "./confirmation"
import DownloadFile from "./download"
import Numbering from "./numbering"
import Validation from "./validation"
import awsmobile from "../../../aws-exports"

const Enumobj = {
  COMPLETED: "complete",
  VALIDATE: "validated",
  INITIAL: "INITIAL",
  ERROR: "error",
  FORMAT: "submitted",
  CONVERTING: "converting",
  DEFAULT: "default",
}

const ConversionPage = () => {
  var iValue = 1
  var filename = ""
  const { user } = useContext(AppStateContext)
  const [showBackdrop, setShowBackdrop] = useState(false)
  const csv = `https://${awsmobile.aws_user_files_s3_bucket}.s3.amazonaws.com/template/GiftCardTemplate.csv`
  const pdf = `https://${awsmobile.aws_user_files_s3_bucket}.s3.amazonaws.com/template/Gift+Card+Conversion+Instructional+Guide.pdf`
  const dispatch = useContext(AppDispatchContext)
  const [formErrors, setFormErrors] = useState({})
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  const [upload, setUpload] = useState(false)
  const [emailId, setEmailId] = useState("")
  const [checked, setChecked] = useState(false)
  const [isPreValidate, setIsPreValidate] = useState(false)
  const [conversionStatus, setConversionStatus] = useState(Enumobj.DEFAULT)
  const [response, setResponse] = useState()
  const permission = "private"
  const [fileLocation, setFileLocation] = useState("")
  const [successMsg, setSuccessMsg] = useState("")
  const [jobId, setJobId] = useState("")
  const [refresh, setRefresh] = useState(Enumobj.INITIAL)
  const interval = 10000
  const timerInterval = 25000

  const emptyAssetFileName = "Ready to Upload"
  const [assetsFileName, setAssetsFileName] = useState(emptyAssetFileName)
  const hasAssetFile = () => assetsFileName !== emptyAssetFileName
  const preview = `https://${awsmobile.aws_user_files_s3_bucket}.s3.amazonaws.com/template/Gift+Card+Conversion+Template+Preview.pdf`

  const toggleSubmitBn = () => {
    if (
      (formErrors === "" || formErrors.Email === "") &&
      checked &&
      isPreValidate &&
      upload &&
      (conversionStatus === "error" ||
        conversionStatus === Enumobj.ERROR ||
        conversionStatus === Enumobj.INITIAL ||
        conversionStatus === undefined)
    ) {
      setSubmitButtonDisabled(false)
    } else {
      setSubmitButtonDisabled(true)
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    dragDrop(acceptedFiles)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const dragDrop = async acceptedFiles => {
    var data = acceptedFiles[0]
    fileUpload(data)
  }

  const fileUpload = async data => {
    setShowBackdrop(true)
    const fileExtension = data.name.split(".").pop()
    if (fileExtension !== "csv") {
      setShowBackdrop(false)
      return dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          timer: timerInterval,
          text: `The file you are trying to upload is an incorrect file type. Please upload file in csv`,
        },
      })
    }
    setIsPreValidate(true)
    setUpload(true)

    setAssetsFileName(data.name)
    const upload_response = await uploadCsvFile(data)
    setResponse(upload_response)
    await preValidation(upload_response)
    setShowBackdrop(false)
  }

  const preValidation = async upload_response => {
    const params = {
      fileLocation: upload_response.location,
    }

    try {
      await API.graphql(graphqlOperation(giftConversionFilePreValidator, { input: params }))
      setIsPreValidate(true)
    } catch (err) {
      const {
        errors: [api_errresponse],
      } = err
      console.warn("Error giftConversionValidator order:", err)
      setIsPreValidate(false)
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          timer: timerInterval,
          text: `Pre-Validation Error. ${api_errresponse.message}`,
        },
      })
      return Enumobj.ERROR
    }
  }

  const getConversion = async () => {
    try {
      let status =
        conversionStatus === Enumobj.DEFAULT || conversionStatus === undefined
          ? Enumobj.INITIAL
          : conversionStatus

      const data_response = await API.graphql(
        graphqlOperation(getMerchantConversionJob, { input: user.cloverMerchantId })
      )
      const {
        data: { getMerchantConversionJob: fetch_response },
      } = data_response
      console.log("fetch_response:#", fetch_response)
      if (
        fetch_response !== null &&
        fetch_response !== undefined &&
        fetch_response.jobId !== null
      ) {
        setJobId(fetch_response.jobId)
        //setFileLocation(signedURL)
        setFileLocation(fetch_response.fileLocation)
        setEmailId(fetch_response.contactEmail)
        setFormErrors("")
        status = fetch_response.status
        if (status === Enumobj.COMPLETED) {
          var date = new Date(fetch_response.updatedAt)
          var datestr = date.toLocaleDateString()
          var timestr = date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "America/Los_Angeles",
          })
          setSuccessMsg(
            `Clover gift cards conversion has been completed successfully(${datestr} at ${timestr} PST)`
          )
        } else if (status === Enumobj.ERROR && refresh === Enumobj.INITIAL) {
          dispatch({
            type: UPDATE_NOTIFICATION,
            payload: {
              show: true,
              type: `error`,
              timer: timerInterval,
              text: `Your gift card conversion request failed. <br/><br/>Click the 'Download File' button to view the error(s) that need to be corrected.<br/>Resubmit once file is complete.`
                .split("<br/>")
                .join("\n"),
            },
          })
        }
      }
      return status
    } catch (err) {
      console.warn("Error giftConversionValidator order:", err)
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          timer: timerInterval,
          text: `Server Error.Please check the Backend`,
        },
      })
      return Enumobj.ERROR
    }
  }
  useInterval(async () => {
    if (
      conversionStatus !== Enumobj.ERROR &&
      conversionStatus !== Enumobj.COMPLETED &&
      conversionStatus !== Enumobj.INITIAL
    ) {
      let status = await getConversion()
      setConversionStatus(status)
    }
  }, interval)

  function useInterval(callback, delay) {
    const savedCallback = useRef()
    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  useEffect(async () => {
    let fetchCall = await getConversion()
    setConversionStatus(fetchCall)
  }, [conversionStatus, refresh]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChecked = ({ target }) => {
    setChecked(target.checked)
  }
  const emailIdChange = event => {
    setEmailId(event.target.value)
    emailValidation(event)
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const handleRemove = async event => {
    event.stopPropagation()
    setShowBackdrop(true)
    setUpload(false)
    setIsPreValidate(false)
    try {
      await Storage.remove(response.name, {
        level: permission,
      })
    } catch (err) {
      console.log("Err in Remove call$$", err)
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          timer: timerInterval,
          text: `Delete Error. ${err}`,
        },
      })
    }
    setAssetsFileName(emptyAssetFileName)
    setShowBackdrop(false)
  }
  const uploadCsvFile = async data => {
    const fileExtension = data.name.split(".").pop()
    const filename = `p-10-${uuidv4()}.${fileExtension}`
    await Storage.put(filename, data, {
      level: permission,
      contentType: data.type,
    })
    const signedUrl = await Storage.get(filename, {
      level: permission,
    })
    const uploadedPath = decodeURIComponent(signedUrl.split(`${permission}/`)[1].split("?")[0])
    const url = new URL(signedUrl)
    const json = {
      url: `https://${url.host}${url.pathname}`,
      jobId: jobId,
      location: `${permission}/${uploadedPath}`,
      file: data.name,
      name: filename,
    }
    return json
  }

  useEffect(() => {
    toggleSubmitBn()
  }, [upload, isPreValidate, checked, formErrors]) // eslint-disable-line react-hooks/exhaustive-deps

  const emailValidation = event => {
    const { name } = event.target
    const errors = { ...formErrors }
    if (validator.isEmail(emailId)) {
      errors[name] = ""
      setFormErrors(errors)
    } else {
      if (event.type !== "change") {
        errors[name] = "The email is invalid, please put in a valid email address"
        if (emailId === "") {
          errors[name] = "The email field is required. Please put in a valid email address"
        }
        setFormErrors(errors)
        console.log("Error input", errors)
      } else {
        errors[name] = ""
        setFormErrors(errors)
      }
    }
  }

  const submitForm = async () => {
    setShowBackdrop(true)
    try {
      let ConversionReqInput = {
        jobId: jobId,
        merchantId: user.cloverMerchantId,
        merchantName: "demo__merchant",
        contactEmail: emailId,
        fileName: response.file,
        fileLocation: response.location,
        uploadedBy: user.username,
      }

      const result = await API.graphql(
        graphqlOperation(giftConversionValidator, { input: ConversionReqInput })
      )
      const {
        data: { giftConversionValidator: api_response },
      } = result
      console.log("api_response $$$", api_response)
      setAssetsFileName(emptyAssetFileName)
      setChecked(false)
      setIsPreValidate(false)
      setUpload(false)
      setFormErrors({})
      setConversionStatus(Enumobj.FORMAT)
      setShowBackdrop(false)
      setRefresh("UPDATED")
    } catch (err) {
      console.log("Err in Mutation call$$", err)
      const {
        errors: [api_errresponse],
      } = err
      console.log(api_errresponse)
      setShowBackdrop(false)
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          timer: timerInterval,
          text: `${api_errresponse.message}`,
        },
      })
    }
  }

  const renderEmailText = () => {
    if (Enumobj.ERROR !== conversionStatus) {
      return (
        <div className={styles.usage_list_icon_text}>
          <Numbering number={iValue++} />
          <div className={styles.list_molecule_icon}>
            <div className={styles.dividertag}>
              <Divider />
            </div>
            <div>
              An email will be sent to confirm your successful gift card conversion and instructions
              for completing the verification process.
            </div>
          </div>
        </div>
      )
    }
  }

  const renderErrorElement = () => {
    const labelName = "Download File"
    if (conversionStatus === Enumobj.ERROR) {
      return (
        <div className={styles.usage_list_icon_text}>
          <Numbering number={iValue++} />
          <div className={styles.list_molecule_icon}>
            <div className={styles.dividertag}>
              <Divider />
            </div>
            <div className={styles.downloadfile}>Download the conversion to be fixed. </div>
            <div className={styles.downloadfile}>
              <DownloadFile link={fileLocation} filename={filename} labelName={labelName} />
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <Backdrop className={styles.backdrop} open={showBackdrop}>
        <CircularProgress />
      </Backdrop>
      <Notification />
      <div>
        <div className={styles.main}>
          {(() => {
            switch (true) {
              default:
                return (
                  <Backdrop className={styles.backdrop} open={true}>
                    <CircularProgress />
                  </Backdrop>
                )
              case conversionStatus === Enumobj.INITIAL:
              case conversionStatus === Enumobj.ERROR && refresh === Enumobj.INITIAL:
                return (
                  <div className={styles.content}>
                    <div className={styles.row_1}>
                      {renderErrorElement()}
                      <div className={styles.usage_list_icon_text}>
                        <Numbering number={iValue++} />
                        <div className={styles.list_molecule_icon}>
                          <div className={styles.dividertag}>
                            <Divider />
                          </div>
                          <div
                            className={`typographyH6`}
                            style={{ fontWeight: "bold", textTransform: "none" }}
                          >
                            It is important that you follow
                            <a
                              href={pdf}
                              color="primary"
                              target="_blank"
                              rel="noreferrer"
                              className={styles.viewTemplates}
                            >
                              {" "}
                              the conversion file instructions
                            </a>{" "}
                            to upload the conversion file.
                          </div>
                          <div
                            className={`typographyH6`}
                            style={{ fontWeight: "bold", textTransform: "none" }}
                          >
                            {" "}
                            File must be in .csv format. Cards can only be converted once.
                          </div>
                          <div
                            className={`typographyH6`}
                            style={{ margin: "30px 0 5px 0", textTransform: "none" }}
                          >
                            Tip: Click
                            <a
                              href={preview}
                              color="primary"
                              target="_blank"
                              rel="noreferrer"
                              download="Gift Card Conversion Template Preview.pdf"
                              className={styles.viewTemplates}
                            >
                              {" "}
                              here
                            </a>{" "}
                            to preview conversion template or use
                            <a
                              href={csv}
                              download="Convert Gift Card Template.csv"
                              className={styles.viewTemplates}
                            >
                              {" "}
                              this sample conversion file
                            </a>{" "}
                            to help get started. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </div>

                      <div className={styles.usage_list_icon_text}>
                        <Numbering number={iValue++} />
                        <div className={styles.list_molecule_icon}>
                          <div className={styles.dividertag}>
                            <Divider />
                          </div>
                          <div className={styles.emailcontent}>
                            Where would you like the confirmation email sent?
                            <TextField
                              id="filled-basic"
                              label="Confirmation Email"
                              name="Email"
                              fullWidth
                              onBlur={emailIdChange}
                              onChange={emailIdChange}
                              value={emailId}
                              required
                              style={{ margin: "0.5rem 0 0 0.5rem" }}
                              variant="filled"
                              helperText={formErrors.Email}
                              error={formErrors.Email ? true : undefined}
                            />
                          </div>
                        </div>
                      </div>

                      {renderEmailText()}

                      <div className={styles.usage_list_icon_text}>
                        <Numbering number={iValue++} />
                        <div className={styles.list_molecule_icon}>
                          <div className={styles.dividertag}>
                            <Divider />
                          </div>
                          <div>Upload the conversion file.</div>
                          <div className={styles.checkbox_row_text}>
                            <Checkbox onChange={handleChecked} checked={checked} />
                            <div className={styles.asteriskcolor}>*</div>I agree that my card
                            details and card balances are correct and valid.
                          </div>
                          <Box
                            className={styles.readyupload}
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              flexDirection: "column",
                              p: 3,
                              m: 0.5,
                            }}
                            {...getRootProps({
                              onClick: e => e.preventDefault(),
                            })}
                          >
                            <div className={`typographyPTiny`} style={{ fontWeight: "bold" }}>
                              {assetsFileName}
                            </div>
                            <div>
                              {hasAssetFile() ? (
                                <div style={{ color: "red" }}>
                                  <Button
                                    component="label"
                                    variant="text"
                                    onClick={handleRemove}
                                    style={{ color: "red", textTransform: "lowercase" }}
                                  >
                                    <Close label="Basic" />
                                    remove
                                  </Button>
                                </div>
                              ) : (
                                <Button
                                  component="label"
                                  variant="text"
                                  style={{
                                    fontSize: "small",
                                    color: "#00855f",
                                    textTransform: "lowercase",
                                  }}
                                >
                                  <input
                                    {...getInputProps()}
                                    accept=".csv"
                                    type="file"
                                    data-test="upload-element"
                                    hidden
                                  />
                                  <Done style={{ color: "#00855f" }} />
                                  add item
                                </Button>
                              )}
                            </div>
                          </Box>
                        </div>
                      </div>
                    </div>

                    <Grid className={styles.designerCheckoutButtonGrid}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        id="submit-designer-contact-button"
                        className="buttonPrimary"
                        disabled={submitButtonDisabled}
                        onClick={submitForm}
                        endIcon={<CloudUpload />}
                      >
                        Submit
                      </Button>
                    </Grid>

                    <div className={`typographyH4`} style={{ margin: "2.5rem 0 0 0" }}>
                      Follow these steps to convert your third-party gift cards into Clover gift
                      cards.
                    </div>
                  </div>
                )
              case conversionStatus === Enumobj.COMPLETED:
                return <Confirmation link={fileLocation} filename={filename} message={successMsg} />
              case conversionStatus === Enumobj.FORMAT:
              case conversionStatus === Enumobj.CONVERTING:
              case conversionStatus === Enumobj.VALIDATE:
              case conversionStatus === Enumobj.ERROR:
                return (
                  <Validation
                    conversion_status={conversionStatus}
                    email={emailId}
                    setRefresh={setRefresh}
                  />
                )
            }
          })()}
          <div>
            <div className={`typographyH2`} style={{ margin: "18px 0 0 0" }}>
              Gift Card Conversion
            </div>
          </div>
          <div className={styles.thirdpartyfont}>
            Third-party gift cards to work with the Clover gift card application.
          </div>
        </div>
      </div>
    </>
  )
}

export default ConversionPage
export { Enumobj }
