/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processUploadedArtwork = /* GraphQL */ `
  mutation ProcessUploadedArtwork($artworkUrl: String!) {
    processUploadedArtwork(artworkUrl: $artworkUrl) {
      artworkUrl
      __typename
    }
  }
`;
export const submitOrder = /* GraphQL */ `
  mutation SubmitOrder($input: CreateOrderInput!) {
    submitOrder(input: $input) {
      PK
      createdAt
      updatedAt
      cognitoSub
      username
      gatewayMID
      closedLoopMID
      authProvider
      orderId
      gcSource
      cardOrderId
      email
      itemType
      requestedShipMethod
      shipToAddress {
        label
        default
        shipAddress1
        shipAddress2
        shipCity
        shipState
        shipZipCode
        __typename
      }
      items {
        orderCategory
        isCardCarrier
        quantity
        cost
        description
        type
        artWorkFileName
        artWorkText
        artWorkBackText
        contactDetails {
          businessName
          email
          phoneNumber
          __typename
        }
        designerAssetsUrl
        notes
        __typename
      }
      status
      pending
      state
      vlOrderId
      payment {
        paymentStatus
        paymentMethod
        paymentId
        subtotalAmount
        totalAmount
        taxAmount
        shippingAmount
        paymentDetails {
          cardholder
          cardType
          lastFour
          billingAddress1
          billingAddress2
          billingCity
          billingState
          billingZipCode
          __typename
        }
        promoDetails {
          discount
          promoCode
          text
          type
          value
          params
          __typename
        }
        __typename
      }
      trackingNumber
      couponCode
      couponValidationParams
      itemsJson
      paymentJson
      shipToAddressJson
      itemsCount
      merchantName
      designOrderId
      originalOrderId
      __typename
    }
  }
`;
export const giftConversionFilePreValidator = /* GraphQL */ `
  mutation GiftConversionFilePreValidator($input: ConversionPreValidateInput!) {
    giftConversionFilePreValidator(input: $input) {
      status
      msg
      errorFileLocation
      __typename
    }
  }
`;
export const giftConversionValidator = /* GraphQL */ `
  mutation GiftConversionValidator($input: ConversionReqInput!) {
    giftConversionValidator(input: $input) {
      status
      msg
      errorFileLocation
      __typename
    }
  }
`;
export const getMerchantConversionJob = /* GraphQL */ `
  mutation GetMerchantConversionJob($input: String!) {
    getMerchantConversionJob(input: $input) {
      jobId
      fileName
      merchantId
      createdAt
      updatedAt
      uploadedBy
      status
      metrics {
        rows
        errors
        __typename
      }
      contactEmail
      errorFileLocation
      fileLocation
      version
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      PK
      createdAt
      updatedAt
      cognitoSub
      username
      gatewayMID
      closedLoopMID
      authProvider
      orderId
      gcSource
      cardOrderId
      email
      itemType
      requestedShipMethod
      shipToAddress {
        label
        default
        shipAddress1
        shipAddress2
        shipCity
        shipState
        shipZipCode
        __typename
      }
      items {
        orderCategory
        isCardCarrier
        quantity
        cost
        description
        type
        artWorkFileName
        artWorkText
        artWorkBackText
        contactDetails {
          businessName
          email
          phoneNumber
          __typename
        }
        designerAssetsUrl
        notes
        __typename
      }
      status
      pending
      state
      vlOrderId
      payment {
        paymentStatus
        paymentMethod
        paymentId
        subtotalAmount
        totalAmount
        taxAmount
        shippingAmount
        paymentDetails {
          cardholder
          cardType
          lastFour
          billingAddress1
          billingAddress2
          billingCity
          billingState
          billingZipCode
          __typename
        }
        promoDetails {
          discount
          promoCode
          text
          type
          value
          params
          __typename
        }
        __typename
      }
      trackingNumber
      couponCode
      couponValidationParams
      itemsJson
      paymentJson
      shipToAddressJson
      itemsCount
      merchantName
      designOrderId
      originalOrderId
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      PK
      createdAt
      updatedAt
      cognitoSub
      username
      gatewayMID
      closedLoopMID
      authProvider
      orderId
      gcSource
      cardOrderId
      email
      itemType
      requestedShipMethod
      shipToAddress {
        label
        default
        shipAddress1
        shipAddress2
        shipCity
        shipState
        shipZipCode
        __typename
      }
      items {
        orderCategory
        isCardCarrier
        quantity
        cost
        description
        type
        artWorkFileName
        artWorkText
        artWorkBackText
        contactDetails {
          businessName
          email
          phoneNumber
          __typename
        }
        designerAssetsUrl
        notes
        __typename
      }
      status
      pending
      state
      vlOrderId
      payment {
        paymentStatus
        paymentMethod
        paymentId
        subtotalAmount
        totalAmount
        taxAmount
        shippingAmount
        paymentDetails {
          cardholder
          cardType
          lastFour
          billingAddress1
          billingAddress2
          billingCity
          billingState
          billingZipCode
          __typename
        }
        promoDetails {
          discount
          promoCode
          text
          type
          value
          params
          __typename
        }
        __typename
      }
      trackingNumber
      couponCode
      couponValidationParams
      itemsJson
      paymentJson
      shipToAddressJson
      itemsCount
      merchantName
      designOrderId
      originalOrderId
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      PK
      createdAt
      updatedAt
      cognitoSub
      username
      gatewayMID
      closedLoopMID
      authProvider
      orderId
      gcSource
      cardOrderId
      email
      itemType
      requestedShipMethod
      shipToAddress {
        label
        default
        shipAddress1
        shipAddress2
        shipCity
        shipState
        shipZipCode
        __typename
      }
      items {
        orderCategory
        isCardCarrier
        quantity
        cost
        description
        type
        artWorkFileName
        artWorkText
        artWorkBackText
        contactDetails {
          businessName
          email
          phoneNumber
          __typename
        }
        designerAssetsUrl
        notes
        __typename
      }
      status
      pending
      state
      vlOrderId
      payment {
        paymentStatus
        paymentMethod
        paymentId
        subtotalAmount
        totalAmount
        taxAmount
        shippingAmount
        paymentDetails {
          cardholder
          cardType
          lastFour
          billingAddress1
          billingAddress2
          billingCity
          billingState
          billingZipCode
          __typename
        }
        promoDetails {
          discount
          promoCode
          text
          type
          value
          params
          __typename
        }
        __typename
      }
      trackingNumber
      couponCode
      couponValidationParams
      itemsJson
      paymentJson
      shipToAddressJson
      itemsCount
      merchantName
      designOrderId
      originalOrderId
      __typename
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      PK
      physicalCardTerms
      shipAddress {
        label
        default
        shipAddress1
        shipAddress2
        shipCity
        shipState
        shipZipCode
        __typename
      }
      createdAt
      updatedAt
      shipAddressJson
      __typename
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      PK
      physicalCardTerms
      shipAddress {
        label
        default
        shipAddress1
        shipAddress2
        shipCity
        shipState
        shipZipCode
        __typename
      }
      createdAt
      updatedAt
      shipAddressJson
      __typename
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      PK
      physicalCardTerms
      shipAddress {
        label
        default
        shipAddress1
        shipAddress2
        shipCity
        shipState
        shipZipCode
        __typename
      }
      createdAt
      updatedAt
      shipAddressJson
      __typename
    }
  }
`;
export const createGiftConversionJob = /* GraphQL */ `
  mutation CreateGiftConversionJob(
    $input: CreateGiftConversionJobInput!
    $condition: ModelGiftConversionJobConditionInput
  ) {
    createGiftConversionJob(input: $input, condition: $condition) {
      jobId
      fileName
      merchantId
      createdAt
      updatedAt
      uploadedBy
      status
      metrics {
        rows
        errors
        __typename
      }
      contactEmail
      audit {
        jobId
        fileName
        merchantId
        createdAt
        updatedAt
        uploadedBy
        status
        metrics {
          rows
          errors
          __typename
        }
        contactEmail
        errorFileLocation
        fileLocation
        version
        __typename
      }
      errorFileLocation
      fileLocation
      version
      __typename
    }
  }
`;
export const updateGiftConversionJob = /* GraphQL */ `
  mutation UpdateGiftConversionJob(
    $input: UpdateGiftConversionJobInput!
    $condition: ModelGiftConversionJobConditionInput
  ) {
    updateGiftConversionJob(input: $input, condition: $condition) {
      jobId
      fileName
      merchantId
      createdAt
      updatedAt
      uploadedBy
      status
      metrics {
        rows
        errors
        __typename
      }
      contactEmail
      audit {
        jobId
        fileName
        merchantId
        createdAt
        updatedAt
        uploadedBy
        status
        metrics {
          rows
          errors
          __typename
        }
        contactEmail
        errorFileLocation
        fileLocation
        version
        __typename
      }
      errorFileLocation
      fileLocation
      version
      __typename
    }
  }
`;
export const deleteGiftConversionJob = /* GraphQL */ `
  mutation DeleteGiftConversionJob(
    $input: DeleteGiftConversionJobInput!
    $condition: ModelGiftConversionJobConditionInput
  ) {
    deleteGiftConversionJob(input: $input, condition: $condition) {
      jobId
      fileName
      merchantId
      createdAt
      updatedAt
      uploadedBy
      status
      metrics {
        rows
        errors
        __typename
      }
      contactEmail
      audit {
        jobId
        fileName
        merchantId
        createdAt
        updatedAt
        uploadedBy
        status
        metrics {
          rows
          errors
          __typename
        }
        contactEmail
        errorFileLocation
        fileLocation
        version
        __typename
      }
      errorFileLocation
      fileLocation
      version
      __typename
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      PK
      couponCode
      couponStatus
      couponType
      couponDiscount
      beginsAt
      expiresAt
      createdAt
      multiUse
      authProviders
      closedLoopMIDs
      gatewayMIDs
      orderTypes
      updatedAt
      __typename
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      PK
      couponCode
      couponStatus
      couponType
      couponDiscount
      beginsAt
      expiresAt
      createdAt
      multiUse
      authProviders
      closedLoopMIDs
      gatewayMIDs
      orderTypes
      updatedAt
      __typename
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      PK
      couponCode
      couponStatus
      couponType
      couponDiscount
      beginsAt
      expiresAt
      createdAt
      multiUse
      authProviders
      closedLoopMIDs
      gatewayMIDs
      orderTypes
      updatedAt
      __typename
    }
  }
`;
