import React, { useContext } from "react"
import { Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { AppDispatchContext, AppStateContext } from "../context/appContext"
import { UPDATE_NOTIFICATION } from "../constants/actionTypes"

/**
 * Accepts:
 * type: success, info, warning, error
 * text: string
 */

export default function Notification() {
  const dispatch = useContext(AppDispatchContext)
  const state = useContext(AppStateContext)

  const handleClose = (event, reason) => {
    // NOTE: We just want to hide the notification as you click away
    // Otherwise it just lingers around
    // if (reason === "clickaway") {
    //   return
    // }
    dispatch({
      type: UPDATE_NOTIFICATION,
      payload: {
        show: false,
        type: state.notification.type,
        text: state.notification.text,
      },
    })
  }

  return (
    <>
      <Snackbar
        open={state.notification.show}
        autoHideDuration={
          state.notification.timer === "" || state.notification.timer === undefined
            ? 6000
            : state.notification.timer
        }
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" onClose={handleClose} severity={state.notification.type}>
          <div style={{ whiteSpace: "pre-line" }}> {state.notification.text} </div>
        </Alert>
      </Snackbar>
    </>
  )
}
