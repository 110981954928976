// extracted by mini-css-extract-plugin
export var MuiButtonBaseRoot = "deluxeFront-module--MuiButtonBase-root--f0eb7";
export var MuiButtonContained = "deluxeFront-module--MuiButton-contained--1aa97";
export var MuiButtonContainedPrimary = "deluxeFront-module--MuiButton-containedPrimary--bdff0";
export var MuiButtonGroupGrouped = "deluxeFront-module--MuiButtonGroup-grouped--6f33b";
export var MuiButtonGroupRoot = "deluxeFront-module--MuiButtonGroup-root--28c66";
export var MuiButtonLabel = "deluxeFront-module--MuiButton-label--393a1";
export var MuiButtonOutlined = "deluxeFront-module--MuiButton-outlined--7fe98";
export var MuiButtonRoot = "deluxeFront-module--MuiButton-root--fbf1e";
export var MuiButtonText = "deluxeFront-module--MuiButton-text--319da";
export var MuiCheckboxColorSecondary = "deluxeFront-module--MuiCheckbox-colorSecondary--0607d";
export var MuiCheckboxRoot = "deluxeFront-module--MuiCheckbox-root--ed5a1";
export var MuiChecked = "deluxeFront-module--Mui-checked--cf618";
export var MuiCircularProgressRoot = "deluxeFront-module--MuiCircularProgress-root--13f5b";
export var MuiDisabled = "deluxeFront-module--Mui-disabled--05fbd";
export var MuiError = "deluxeFront-module--Mui-error--621e8";
export var MuiFilledInputRoot = "deluxeFront-module--MuiFilledInput-root--8a944";
export var MuiFilledInputUnderline = "deluxeFront-module--MuiFilledInput-underline--4d56a";
export var MuiFocus = "deluxeFront-module--Mui-focus--87331";
export var MuiFocused = "deluxeFront-module--Mui-focused--12842";
export var MuiFormControlRoot = "deluxeFront-module--MuiFormControl-root--f4df5";
export var MuiFormHelperTextRoot = "deluxeFront-module--MuiFormHelperText-root--86f6a";
export var MuiFormLabelRoot = "deluxeFront-module--MuiFormLabel-root--0e30e";
export var MuiIconButtonRoot = "deluxeFront-module--MuiIconButton-root--65195";
export var MuiInputAdornmentRoot = "deluxeFront-module--MuiInputAdornment-root--9bcd0";
export var MuiInputUnderline = "deluxeFront-module--MuiInput-underline--cd309";
export var MuiListItemButton = "deluxeFront-module--MuiListItem-button--43891";
export var MuiOutlinedInputNotchedOutline = "deluxeFront-module--MuiOutlinedInput-notchedOutline--ebbd8";
export var MuiOutlinedInputRoot = "deluxeFront-module--MuiOutlinedInput-root--42433";
export var MuiPickersDayDay = "deluxeFront-module--MuiPickersDay-day--c4a60";
export var MuiRadioColorSecondary = "deluxeFront-module--MuiRadio-colorSecondary--002f1";
export var MuiRadioRoot = "deluxeFront-module--MuiRadio-root--217a8";
export var MuiSelected = "deluxeFront-module--Mui-selected--dce2f";
export var MuiSnackbarAnchorOriginTopCenter = "deluxeFront-module--MuiSnackbar-anchorOriginTopCenter--41fec";
export var MuiSnackbarContentRoot = "deluxeFront-module--MuiSnackbarContent-root--1d3d8";
export var MuiSnackbarRoot = "deluxeFront-module--MuiSnackbar-root--56e0c";
export var MuiSwitchColorPrimary = "deluxeFront-module--MuiSwitch-colorPrimary--3c79b";
export var MuiSwitchThumb = "deluxeFront-module--MuiSwitch-thumb--bacb8";
export var MuiSwitchTrack = "deluxeFront-module--MuiSwitch-track--202c5";
export var MuiTabTextColorPrimary = "deluxeFront-module--MuiTab-textColorPrimary--ac3b6";
export var MuiTypographyBody1 = "deluxeFront-module--MuiTypography-body1--d1d43";
export var active = "deluxeFront-module--active--429d5";
export var activeIndicator = "deluxeFront-module--activeIndicator--0e267";
export var amountOfCards = "deluxeFront-module--amountOfCards--888ce";
export var backdrop = "deluxeFront-module--backdrop--3357b";
export var breadcrumbs = "deluxeFront-module--breadcrumbs--ba0f8";
export var buttonContainer = "deluxeFront-module--buttonContainer--5ca03";
export var buttonPrimary = "deluxeFront-module--buttonPrimary--12086";
export var buttonSecondary = "deluxeFront-module--buttonSecondary--ce207";
export var buttonsContainer = "deluxeFront-module--buttonsContainer--bfe8d";
export var cardImg = "deluxeFront-module--cardImg--781d5";
export var cardOutline = "deluxeFront-module--cardOutline--157e3";
export var colorWhite = "var(--neutral-white)";
export var containerWidth = "1660px";
export var darkBlue = "#34404f";
export var datePickerInput = "deluxeFront-module--datePickerInput--c4ac6";
export var datePickerToggle = "deluxeFront-module--datePickerToggle--e9cef";
export var datePickersContainer = "deluxeFront-module--datePickersContainer--29ac1";
export var deluxeCard = "deluxeFront-module--deluxeCard--3c40f";
export var detailsContainer = "deluxeFront-module--detailsContainer--a4b73";
export var disabledLink = "deluxeFront-module--disabled-link--b6ed1";
export var download = "deluxeFront-module--download--a0007";
export var downloadAllButtonContainer = "deluxeFront-module--downloadAllButtonContainer--e04e9";
export var downloadButton = "deluxeFront-module--downloadButton--80b20";
export var downloadButtonContainer = "deluxeFront-module--downloadButtonContainer--d7af5";
export var downloadSection = "deluxeFront-module--downloadSection--c0aaa";
export var elevatedCard = "deluxeFront-module--elevatedCard--4f732";
export var error = "deluxeFront-module--error--d9c30";
export var filterByFrequencyRadios = "deluxeFront-module--filterByFrequencyRadios--32b18";
export var filterByHeading = "deluxeFront-module--filterByHeading--c4e7e";
export var filterBySection = "deluxeFront-module--filterBySection--a0260";
export var filtersContainer = "deluxeFront-module--filtersContainer--81a87";
export var footerCopy = "deluxeFront-module--footerCopy--dd59b";
export var hidden = "deluxeFront-module--hidden--f9460";
export var isFlipped = "deluxeFront-module--isFlipped--206f5";
export var itemHeading = "deluxeFront-module--itemHeading--8a66f";
export var itemSection = "deluxeFront-module--itemSection--db6a9";
export var itemValue = "deluxeFront-module--itemValue--9488f";
export var link = "deluxeFront-module--link--03138";
export var logoutButton = "deluxeFront-module--logoutButton--c1251";
export var mainContainer = "deluxeFront-module--mainContainer--876a7";
export var pageNavigation = "deluxeFront-module--pageNavigation--7435e";
export var pageWrapper = "deluxeFront-module--pageWrapper--ada22";
export var reportingOption = "deluxeFront-module--reportingOption--ba7a6";
export var reports = "deluxeFront-module--reports--e51a3";
export var resultsDateRange = "deluxeFront-module--resultsDateRange--a9ca4";
export var resultsSection = "deluxeFront-module--resultsSection--36261";
export var safeAreaColorSelection = "deluxeFront-module--safeAreaColorSelection--c2261";
export var safeAreaControl = "deluxeFront-module--safeAreaControl--7cd64";
export var sampleCardParameters = "deluxeFront-module--sampleCardParameters--dae47";
export var sectionBottom = "deluxeFront-module--sectionBottom--8e540";
export var sectionContainer = "deluxeFront-module--sectionContainer--77ed0";
export var sectionLeft = "deluxeFront-module--sectionLeft--53b9b";
export var sectionRight = "deluxeFront-module--sectionRight--6d705";
export var sectionTitle = "deluxeFront-module--sectionTitle--26c0e";
export var settings = "deluxeFront-module--settings--268d9";
export var statusText = "deluxeFront-module--statusText--632fe";
export var textSafeArea = "deluxeFront-module--textSafeArea--49306";
export var toggleGroup = "deluxeFront-module--toggleGroup--10e65";
export var toggleLinkContainer = "deluxeFront-module--toggleLinkContainer--a55a8";
export var transactionFilterSelect = "deluxeFront-module--transactionFilterSelect--84332";
export var transactions = "deluxeFront-module--transactions--f6253";
export var typographyH1 = "deluxeFront-module--typographyH1--4c31a";
export var typographyH2 = "deluxeFront-module--typographyH2--d1aa0";
export var typographyH3 = "deluxeFront-module--typographyH3--c987a";
export var typographyH4 = "deluxeFront-module--typographyH4--f035f";
export var typographyH5 = "deluxeFront-module--typographyH5--1728b";
export var typographyH6 = "deluxeFront-module--typographyH6--297ff";
export var typographyP = "deluxeFront-module--typographyP--d74fb";
export var typographyPSecondary = "deluxeFront-module--typographyPSecondary--7a05f";
export var typographyPTiny = "deluxeFront-module--typographyPTiny--2d8a6";
export var viewTemplates = "deluxeFront-module--viewTemplates--6d2ba";