import React, { useContext, useEffect, useState } from "react"
import * as styles from "./order-history.module.scss"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import { AppDispatchContext, AppStateContext } from "../../context/appContext"
import { Amplify, API, graphqlOperation } from "aws-amplify"
import { dateFormatSimple } from "../../services/date"
import {
  SET_ORDER_HISTORY,
  SET_ORDER,
  SET_RECENT_ORDER,
  UPDATE_NOTIFICATION,
} from "../../constants/actionTypes"
import { initConfig } from "../../services/amplify"
import { Button, Card, CardContent, IconButton, TableFooter } from "@material-ui/core"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import FirstPageIcon from "@material-ui/icons/FirstPage"
import LastPageIcon from "@material-ui/icons/LastPage"
import { navigate } from "gatsby"
import { toTitleCase } from "../../services/card"
import DesignerOrderUrl from "../../images/designer-package-cover-image.png"
import StatusDisplayMessage from "./orderStatusMessage.js"
import { loadOrderByCreatedAt } from "../../services/order-summary"
import { cardOutline, deluxeFrontCrop } from "../orderSummary/order-summary.module.scss"

Amplify.configure(initConfig())

const FIVE = 5
const TWENTY_FIVE = 25
const ROWS_PER_PAGE = [FIVE, 10, TWENTY_FIVE, 50]
const listOrdersForHistory = /* GraphQL */ `
  query ListOrders(
    $PK: String
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrders(
      PK: $PK
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        orderId
        vlOrderId
        status
        state
        items {
          artWorkText
          quantity
          type
        }
        payment {
          paymentMethod
          totalAmount
        }
        trackingNumber
      }
      nextToken
    }
  }
`

const OrderHistoryComponent = ({ pageType }) => {
  const recent = pageType === "recent"
  const dispatch = useContext(AppDispatchContext)
  const state = useContext(AppStateContext)
  const { orderHistory = null, user } = state
  const [orders, setOrders] = useState(orderHistory)
  const [orderCount, setOrderCount] = useState(
    orderHistory !== null ? parseInt(orderHistory.length, 10) : 0
  )
  //Pagination States
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(recent ? FIVE : TWENTY_FIVE)

  useEffect(() => {
    const loadOrders = async () => {
      try {
        const order_count_limit = recent ? FIVE : 1000
        const params = {
          PK: user.gatewayMID,
          limit: order_count_limit,
          sortDirection: "DESC",
        }
        const {
          data: {
            listOrders: { items },
          },
        } = await API.graphql(graphqlOperation(listOrdersForHistory, params))

        const orderList = []
        for (const order of items) {
          const paymentMethod = order.payment ? order.payment.paymentMethod : null
          const totalCost =
            order.payment && order.payment.totalAmount ? order.payment.totalAmount / 100 : 0
          const trackingNumber = order.trackingNumber
          let cardType = null
          let artWorkUrl = undefined
          for (const item of order.items) {
            if (item.type === "STD" || item.type === "DELUXE" || item.type === "DESIGNER") {
              cardType = item.type
            }
            if (item.artWorkText) {
              artWorkUrl = item.artWorkText
            }
          }

          orderList.push({
            status: order.status,
            date: order.createdAt,
            orderId: order.orderId,
            vlOrderId: order.vlOrderId,
            quantity: order.items[0].quantity,
            cardType,
            artWorkUrl,
            paymentMethod,
            totalCost,
            trackingNumber,
          })
        }

        setOrders(orderList)
        setOrderCount(orderList.length)
        dispatch({
          type: SET_ORDER_HISTORY,
          payload: orderList,
        })
      } catch (err) {
        setOrders(false)
        setOrderCount(0)
      }
    }

    if (user) {
      loadOrders().then()
    }
  }, [dispatch, user, recent])

  const handleRowClick = (event, order_date) => {
    navigate(`/app/orders/order-summary/#` + btoa(order_date))
  }

  const onChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const onChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const paginationStyle = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(3),
    },
  }))

  function TablePaginationActions() {
    const theme = useTheme()
    const classes = paginationStyle()

    const onFirstPageClick = event => {
      onChangePage(event, 0)
    }

    const onPrevPageClick = event => {
      onChangePage(event, page - 1)
    }

    const onNextPageClick = event => {
      onChangePage(event, page + 1)
    }

    const onLastPageClick = event => {
      onChangePage(event, Math.max(0, Math.ceil(orderCount / rowsPerPage) - 1))
    }

    return (
      <div className={classes.root}>
        <IconButton onClick={onFirstPageClick} disabled={page === 0}>
          {theme.direction === "ltr" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        <IconButton onClick={onPrevPageClick} disabled={page === 0}>
          {theme.direction === "ltr" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={onNextPageClick}
          disabled={page >= Math.ceil(orderCount / rowsPerPage) - 1}
        >
          {theme.direction === "ltr" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={onLastPageClick}
          disabled={page >= Math.ceil(orderCount / rowsPerPage) - 1}
        >
          {theme.direction === "ltr" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
      </div>
    )
  }

  const canReOrderList = ["SUBMITTED", "RECEIVED", "COMPLETE"]

  const canReorderCards = order => {
    if (!order) return false

    if (order.cardType === "DESIGNER" && order.status === "COMPLETE") {
      // Designer cards can be ordered only when the status is COMPLETE
      return true
    }

    // Standard cards can be re-ordered if the status is in ["SUBMITTED", "RECEIVED", "COMPLETE"]
    return ["STD", "DELUXE"].includes(order.cardType) && canReOrderList.includes(order.status)
  }

  const reOrder = async createdAt => {
    try {
      const orderDetails = await loadOrderByCreatedAt(user, createdAt)
      dispatch({
        type: SET_RECENT_ORDER,
        payload: orderDetails,
      })
      let navigate_to =
        user.paymentMethod &&
        user.paymentMethod === "BEFORE_CREDIT_CARD" &&
        process.env.GATSBY_SHOW_PAYMENT === "1"
          ? "/app/checkout/payment"
          : "/app/checkout/review-order"
      // NOTE: We should remove any of the order status fields before creating a new order
      delete orderDetails.pending
      delete orderDetails.status
      delete orderDetails.vlOrderId
      delete orderDetails.createdAt
      if (orderDetails.cards.cardType === "designer") {
        orderDetails.cards.cardType = "deluxe"
        orderDetails.cards.subCardType = "designer"
        orderDetails.designOrderId = orderDetails.orderId
        delete orderDetails.cards.amountOfCards
        navigate_to = "/app/order/card-front-deluxe"
      } else {
        orderDetails.originalOrderId = orderDetails.orderId
      }
      delete orderDetails.orderId

      orderDetails.payment = {
        paymentMethod:
          (user.paymentMethod &&
            user.paymentMethod === "BEFORE_CREDIT_CARD" &&
            process.env.GATSBY_SHOW_PAYMENT === "1") ||
          (user.paymentMethod && user.paymentMethod !== "BEFORE_CREDIT_CARD")
            ? user.paymentMethod
            : "AFTER_MERCHANT_ACCOUNT",
      }

      dispatch({
        type: SET_ORDER,
        payload: orderDetails,
      })
      navigate(navigate_to)
    } catch (err) {
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          text: `There was an error reordering. Please try again later or contact us if the problem persists.`,
        },
      })
      console.warn("Error: ", err)
    }
  }

  const toPaymentDetails = order => {
    const method = order.paymentMethod
    if (!method || method === "AFTER_MERCHANT_ACCOUNT") {
      return "Merchant Account"
    } else if (method === "BEFORE_CREDIT_CARD") {
      return "Credit Card"
    }
  }

  const toCardTypeLabel = order => {
    const type = order.cardType
    if (!type || type === "STD") {
      return "Standard"
    }
    return toTitleCase(type)
  }

  let renderCards = (
    <TableRow>
      <TableCell colSpan={2} align="center">
        Loading...
      </TableCell>
    </TableRow>
  )

  if (orders) {
    if (orderCount > 0) {
      const orderRows = orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      renderCards = orderRows.map((order, idx) => (
        <TableRow key={idx}>
          <TableCell className={styles.td} style={{ borderBottom: "none", padding: "0.5rem" }}>
            <Card className={`${styles.card} elevatedCard`}>
              <CardContent className={styles.cardContent}>
                {order.orderId ? (
                  <div className={styles.orderId}>ORDER#: {order.orderId}</div>
                ) : null}
                <div className={styles.orderDate}>
                  {order.cardType === "DESIGNER" ? (
                    <span>DESIGN REQUESTED: </span>
                  ) : (
                    <span>ORDER PLACED: </span>
                  )}
                  {dateFormatSimple(new Date(order.date))}
                </div>
                <div className={styles.orderHighlights}>
                  <div className={styles.imageContainer}>
                    <svg
                      className={deluxeFrontCrop}
                      xmlns="http://www.w3.org/2000/svg"
                      width="240"
                      height="157"
                      viewBox="0 0 1088 713"
                    >
                      <clipPath id="cardCrop">
                        <rect x="37.5" y="37.5" width="1013px" height="638px" rx="16px" ry="16px" />
                      </clipPath>
                      <rect
                        className={cardOutline}
                        x="37.5"
                        y="37.5"
                        width="1013px"
                        height="638px"
                        rx="16px"
                        ry="16px"
                        fill="transparent"
                      />
                      <image
                        href={order.artWorkUrl || DesignerOrderUrl}
                        className={styles.imagePreview}
                        x="0"
                        y="0"
                        height="100%"
                        width="100%"
                        clipPath="url(#cardCrop)"
                      />
                    </svg>
                  </div>
                  <div className={styles.orderElements}>
                    <div className={styles.dataTitle}>
                      CARD TYPE
                      <span className={styles.dataPoint}>{toCardTypeLabel(order)}</span>
                    </div>
                    <div className={styles.dataTitle}>
                      ORDER STATUS
                      {StatusDisplayMessage(order.status, order.cardType, order.trackingNumber)}
                    </div>
                    {order.status !== "PENDING" ? (
                      <div className={styles.dataTitle}>
                        SHIPPING REFERENCE
                        <span className={styles.dataPoint}>{order.vlOrderId}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className={styles.dataTitle}>
                      PAYMENT METHOD
                      <span className={styles.dataPoint}>{toPaymentDetails(order)}</span>
                    </div>
                    <div className={styles.dataTitle}>
                      TOTAL
                      <span className={styles.dataPoint}>
                        ${order.totalCost ? order.totalCost.toFixed(2) : ""}
                      </span>
                    </div>
                    <div className={styles.dataTitle}>
                      QUANTITY
                      <span className={styles.dataPoint}>{order.quantity}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.detailsButtonContainer}>
                  <Button
                    className={`${styles.orderHistoryButton} buttonPrimary`}
                    onClick={event => handleRowClick(event, order.date)}
                    variant="contained"
                  >
                    Order Details
                  </Button>
                  {canReorderCards(order) ? (
                    <Button
                      className={`buttonPrimary`}
                      variant={`contained`}
                      onClick={e => reOrder(order.date)}
                    >
                      {order.cardType === "DESIGNER" ? "Order Deluxe" : "Reorder"}
                    </Button>
                  ) : null}
                </div>
              </CardContent>
            </Card>
          </TableCell>
        </TableRow>
      ))
    } else {
      renderCards = (
        <TableRow>
          <TableCell colSpan={2} align="center">
            No orders found
          </TableCell>
        </TableRow>
      )
    }
  } else if (orders === false) {
    renderCards = (
      <TableRow>
        <TableCell colSpan={2} align="center">
          Error loading orders...
        </TableCell>
      </TableRow>
    )
  }

  const renderTable = () => {
    return (
      <Table>
        <TableBody>{renderCards}</TableBody>
        {recent ? null : (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={ROWS_PER_PAGE}
                colSpan={2}
                count={orderCount}
                labelRowsPerPage={"Orders per page:"}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    )
  }

  const renderTableContainer = () => {
    if (recent) {
      return (
        <TableContainer className={`${styles.tableContainer} ${styles.recentTable}`}>
          {renderTable()}
        </TableContainer>
      )
    } else {
      return (
        <TableContainer className={`${styles.tableContainer} ${styles.historyTable}`}>
          {renderTable()}
        </TableContainer>
      )
    }
  }

  return (
    <>
      <div className={styles.pageContainer}>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionFull}>
            <h3 className={styles.sectionTitle} data-test="orders-page-title">
              {recent ? "Recent Orders" : "Order History"}
            </h3>
            {renderTableContainer()}
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderHistoryComponent
