import React from "react"
import CloudDownload from "@material-ui/icons/CloudDownload"
import { Button, Link } from "@material-ui/core"
const DownloadFile = props => {
  return (
    <>
      <Button
        id="submit-designer-contact-button"
        className="buttonPrimary"
        variant="contained"
        component="label"
        color="default"
        size="large"
        style={{ width: "fit-content" }}
        endIcon={<CloudDownload />}
      >
        <Link href={props.link} download={props.filename} style={{ textDecoration: "none" }}>
          {props.labelName}
        </Link>
      </Button>
    </>
  )
}

export default DownloadFile
