/**
 * Format date as MM/DD/YYYY HH:MM (AM/PM)
 * @param dateObj
 * @return {string}
 */
export function dateFormatSimple(dateObj, displayTimeInfo = true) {
  const providedDate = new Date(dateObj)

  const month = providedDate.getMonth() + 1
  const monthStr = month.toString().padStart(2, "0")
  const date = providedDate.getDate()
  const dateStr = date.toString().padStart(2, "0")
  const year = providedDate.getFullYear()
  const hour = providedDate.getHours()
  let hourStr = hour
  let ampm = "AM"
  if (hour === 0) {
    hourStr = 12
  } else if (hour === 12) {
    ampm = "PM"
  } else if (hour > 12) {
    hourStr = hour - 12
    ampm = "PM"
  }
  const minutes = providedDate.getMinutes()
  const minStr = minutes.toString().padStart(2, "0")
  return `${monthStr}/${dateStr}/${year} ${displayTimeInfo ? hourStr + ":" + minStr + ampm : ""} `
}
