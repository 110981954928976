import React, { useContext, useEffect, useState } from "react"
import { API, graphqlOperation } from "aws-amplify"
import { createProfile, updateProfile } from "../../graphql/mutations"
import { getProfile } from "../../graphql/queries"
import { PHYSICAL_CARD_TERMS } from "../../constants/terms"
import { AppStateContext } from "../../context/appContext"
import { makeStyles } from "@material-ui/core/styles"
import { Divider, Snackbar } from "@material-ui/core"
import * as styles from "./settings.module.scss"
import Terms from "./terms"
import Address from "./address"
import { Alert } from "@material-ui/lab"
import { checkEmptyFields } from "../../services/validate"

export const useStyles = makeStyles(() => ({
  settingsTermsButtonGrid: {
    justifyContent: "flex-end",
    padding: `20px 5px`,
  },
  resetTermsButton: {
    marginRight: `20px`,
  },
  settingsAddressButtonGrid: {
    justifyContent: "flex-end",
    padding: `20px 15px`,
  },
  settingsSubSection: {
    paddingTop: `30px`,
  },
  settingsText: {
    fontFamily: `inherit`,
  },
}))

const ProfileComponent = () => {
  const [saveType, setSaveType] = useState("create")
  const [terms, setTerms] = useState("")
  const [openSuccessMessage, setOpenSuccessMessage] = useState("")
  const { user, profileAddress } = useContext(AppStateContext)
  const gatewayMid = user.gatewayMID

  const saveProfile = async (type, value, successMessage) => {
    const data = {
      PK: gatewayMid,
      [type]: value,
    }
    if (type === "shipAddress") {
      data["shipAddressJson"] = JSON.stringify(value)
    }
    const saveOperation = saveType === "create" ? createProfile : updateProfile
    try {
      await API.graphql(graphqlOperation(saveOperation, { input: data }))
      setOpenSuccessMessage(successMessage)
    } catch (e) {
      console.error(saveType + " profile failed!!", e)
    }
  }

  const closeMessage = (event, reason) => {
    setOpenSuccessMessage("")
    if (reason === "clickaway") {
      return
    }
  }

  useEffect(() => {
    const fetchProfile = async () => {
      let physicalCardTerms = null
      let shipAddress = null

      try {
        const {
          data: { getProfile: profile },
        } = await API.graphql(graphqlOperation(getProfile, { PK: gatewayMid }))

        if (profile) {
          physicalCardTerms = profile.physicalCardTerms ? profile.physicalCardTerms : null
          shipAddress = profile.shipAddress ? profile.shipAddress : null
          setSaveType("update")
        }

        return { physicalCardTerms, shipAddress }
      } catch (e) {
        console.error("Failed to show profile:", e)
      }
    }

    fetchProfile().then(({ physicalCardTerms }) => {
      setTerms(physicalCardTerms ? physicalCardTerms : PHYSICAL_CARD_TERMS)
    })
  }, [gatewayMid])

  return (
    <div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionLeft}>
          <h3 className={styles.sectionTitle} data-test="settings-page-title">
            Settings
          </h3>
          <Snackbar
            anchorOrigin={{ vertical: `top`, horizontal: `center` }}
            open={!!openSuccessMessage}
            autoHideDuration={5000}
            onClose={closeMessage}
          >
            <Alert onClose={closeMessage} severity="success">
              {openSuccessMessage}
            </Alert>
          </Snackbar>
          <Terms terms={terms} saveSettingsHandler={saveProfile} checkEmpty={checkEmptyFields} />
          <Divider />
          <Address
            address={profileAddress}
            saveSettingsHandler={saveProfile}
            checkEmpty={checkEmptyFields}
          />
        </div>
      </div>
    </div>
  )
}

export default ProfileComponent
