import pricingTable from "../constants/pricing.json"
import cloverPricingTable from "../constants/cloverPricing.json"
import { AUTH_PROVIDER_CLOVER } from "../constants/authProvider"

//Pulls the pricing json file determined by user origin
export function getPricingByCategory(category, subCategory, authProvider) {
  //If user is coming from clover, pull the clover pricing json
  if (authProvider === AUTH_PROVIDER_CLOVER) {
    return cloverPricingTable.filter(
      item =>
        item.category === category &&
        (subCategory === undefined || item.sub_category === subCategory)
    )
  } else {
    //Return the PBC pricing json
    return pricingTable.filter(
      item =>
        item.category === category &&
        (subCategory === undefined || item.sub_category === subCategory)
    )
  }
}

export function getItemByQuantity(category, subCategory, authProvider, quantity) {
  //If user is coming from clover, pull the clover pricing json
  if (authProvider === AUTH_PROVIDER_CLOVER) {
    return cloverPricingTable.filter(
      item =>
        item.category === category &&
        (subCategory === undefined || item.sub_category === subCategory) &&
        item.quantity === quantity
    )
  } else {
    //Return the PBC pricing json
    return pricingTable.filter(
      item =>
        item.category === category &&
        (subCategory === undefined || item.sub_category === subCategory) &&
        item.quantity === quantity
    )
  }
}

export function getItemBySku(authProvider, category, sku) {
  //If user is coming from clover, pull the clover pricing json
  if (authProvider === AUTH_PROVIDER_CLOVER) {
    return cloverPricingTable.filter(item => item.category === category && item.sku === sku)
  } else {
    //Return the PBC pricing json
    return pricingTable.filter(item => item.category === category && item.sku === sku)
  }
}
