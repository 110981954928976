export const carrierItems = [
  {
    value: "JC-BLK",
    label: "JC-BLK Black",
  },
  {
    value: "JC-BLU",
    label: "JC-BLU Blue",
  },
  {
    value: "JC-BRG",
    label: "JC-BRG Burgundy",
  },
  {
    value: "JC-GLD",
    label: "JC-GLD Gold",
  },
  {
    value: "JC-GRN",
    label: "JC-GRN Green",
  },
  {
    value: "JC-RED",
    label: "JC-RED Red",
  },
  {
    value: "JC-SIL",
    label: "JC-SIL Silver",
  },
  {
    value: "JC-WHT",
    label: "JC-WHT White",
  },
  {
    value: "PK-GFT",
    label: "PK-GFT Black/White",
  },
]
