import React, { useState, useEffect, useContext } from "react"
import { v4 as uuidv4 } from "uuid"
import * as styles from "./reports.module.scss"
import DateFnsUtils from "@date-io/date-fns"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import Moment from "moment"
import {
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  TablePagination,
} from "@material-ui/core"
import FirstPageIcon from "@material-ui/icons/FirstPage"
import LastPageIcon from "@material-ui/icons/LastPage"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { dateFormatSimple } from "../../services/date"
import GetAppSharpIcon from "@material-ui/icons/GetAppSharp"
import { API, graphqlOperation } from "aws-amplify"
import { getVLReports } from "../../graphql/queries"
import {
  REPORT_FREQUENCY_DAILY,
  REPORT_FREQUENCY_MONTHLY,
  REPORT_FREQUENCY_ALL,
  REPORT_TYPE_REGULAR,
  REPORT_TYPE_PROMO_SUMMARY,
  REPORT_TYPE_OUTSTANDING_BALANCE,
  REPORT_TYPE_PROMO_DETAIL,
} from "../../constants/vlReporting"
import axios from "axios"
import { UPDATE_NOTIFICATION } from "../../constants/actionTypes"
import { AppDispatchContext } from "../../context/appContext"
import { SMB_VL_REPORTING_BASE_URL } from "../../constants/localEnv"

const VLReportsComponent = ({ handleBackdrop }) => {
  const dispatch = useContext(AppDispatchContext)
  const vlReportingBaseUrl =
    process.env.GATSBY_SMB_VL_REPORTING_BASE_URL || SMB_VL_REPORTING_BASE_URL
  const [results, setResults] = useState([])
  const [resultsCount, setResultsCount] = useState(
    results !== null ? parseInt(results.length, 10) : 0
  )
  const [hasErrors, setHasErrors] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [endDateError, setEndDateError] = useState(false)
  const [startDateError, setStartDateError] = useState(false)
  const [useDateRange, setUseDateRange] = useState(false)
  const [frequencyType, setFrequencyType] = useState("All")

  //pagination states, functions
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [page, setPage] = useState(0)

  const reportFrequencyMap = {
    [REPORT_FREQUENCY_DAILY]: "Daily",
    [REPORT_FREQUENCY_MONTHLY]: "Monthly",
    [REPORT_FREQUENCY_ALL]: "All",
  }

  const reportTypeMap = {
    [REPORT_TYPE_REGULAR]: "Regular",
    [REPORT_TYPE_PROMO_SUMMARY]: "Promo Summary",
    [REPORT_TYPE_PROMO_DETAIL]: "Promo Detail",
    [REPORT_TYPE_OUTSTANDING_BALANCE]: "Outstanding Balance",
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const paginationStyle = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(3),
    },
  }))

  function TablePaginationActions() {
    const theme = useTheme()
    const classes = paginationStyle()

    const onFirstPageClick = event => {
      handleChangePage(event, 0)
    }

    const onPrevPageClick = event => {
      handleChangePage(event, page - 1)
    }

    const onNextPageClick = event => {
      handleChangePage(event, page + 1)
    }

    const onLastPageClick = event => {
      handleChangePage(event, Math.max(0, Math.ceil(results.length / rowsPerPage) - 1))
    }

    return (
      <div className={classes.root}>
        <IconButton onClick={onFirstPageClick} disabled={page === 0}>
          {theme.direction === "ltr" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        <IconButton onClick={onPrevPageClick} disabled={page === 0}>
          {theme.direction === "ltr" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={onNextPageClick}
          disabled={page >= Math.ceil(results.length / rowsPerPage) - 1}
        >
          {theme.direction === "ltr" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={onLastPageClick}
          disabled={page >= Math.ceil(results.length / rowsPerPage) - 1}
        >
          {theme.direction === "ltr" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
      </div>
    )
  }

  const handleFrequencyChange = event => {
    const { value } = event.currentTarget
    setFrequencyType(value)
  }

  const toggleDatePickers = event => {
    const { value } = event.currentTarget
    if (value === "single") {
      setUseDateRange(true)
    } else if (value === "range") {
      setUseDateRange(false)
    }
  }

  const downloadFile = event => {
    const { id, value } = event.currentTarget
    let report = results.find(o => o.uuid === id)
    if (report && report.uuid && report.name) {
      axios({
        url: vlReportingBaseUrl + "/" + value,
        method: "GET",
        responseType: "blob",
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", report.name) //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch(function (err) {
          console.warn("Could not download VL Report", err.message)
          dispatch({
            type: UPDATE_NOTIFICATION,
            payload: {
              show: true,
              type: `error`,
              text: `Sorry, there was an error downloading the report. Please try again later.`,
            },
          })
        })
    } else {
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          text: `Record mismatch error`,
        },
      })
    }
  }

  useEffect(() => {
    if (!startDate) {
      setStartDateError(true)
    } else {
      setStartDateError(false)
    }
  }, [startDate])

  const loadVLReports = async (startDate, endDate, frequencyType) => {
    try {
      const VLReportsReqInput = {
        startDate: Moment(startDate).format("YYYY-MM-DD"),
        endDate: useDateRange && endDate ? Moment(endDate).format("YYYY-MM-DD") : "",
        reportFrequency: parseInt(
          Object.keys(reportFrequencyMap).find(key => reportFrequencyMap[key] === frequencyType)
        ),
      }

      const result = await API.graphql(graphqlOperation(getVLReports, { input: VLReportsReqInput }))
      const {
        data: { getVLReports: reports },
      } = result
      return reports
    } catch (err) {
      console.warn("loadVLReports failed", err)
    }
  }

  const searchRecords = async () => {
    if (!startDate) {
      setStartDateError(true)
    } else {
      setStartDateError(false)
      if (endDate < startDate) {
        setEndDateError(true)
      } else {
        setEndDateError(false)
        handleBackdrop(true)
        console.log(
          "lets now trigger the search api/graphql call with the following params frequency = ",
          "\nstartDate",
          Moment(startDate).format("YYYY-MM-DD"),
          "\nendDate",
          Moment(endDate).format("YYYY-MM-DD"),
          "\nuseDateRange",
          useDateRange,
          "\nreportFrequency",
          frequencyType
        )
        let rows = []
        let reports = await loadVLReports(startDate, endDate, frequencyType)

        if (reports && !reports?.errors && reports.length > 0) {
          reports.forEach(function (report) {
            rows.push({
              uuid: uuidv4(),
              type: reportTypeMap[report["reportType"]],
              frequency: reportFrequencyMap[report["reportFrequency"]],
              createdAt: report["reportDate"],
              name: report["reportName"],
              url: report["reportUrl"],
            })
          })
          setHasErrors(false)
          setResults(rows)
          setResultsCount(rows.length)
        } else {
          setHasErrors(true)
          setResults([])
        }

        handleBackdrop(false)
      }
    }
  }

  const ResultCard = () => {
    const resultsRow = results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    return (
      <React.Fragment>
        {resultsRow.map((item, idx) => {
          return (
            <div key={idx} className={`resultsRow`}>
              <div className={`elevatedCard detailsContainer`}>
                <div className={`itemSection`}>
                  <p className={`itemHeading typographyH6`}>
                    {`Type`} <br />
                    <span className={`itemValue typographyH6`}>{item.type}</span>
                  </p>
                  <p className={`itemHeading typographyH6`}>
                    {`Frequency`} <br />
                    <span className={`itemValue typographyH6`}>{item.frequency}</span>
                  </p>
                </div>
                <div className={`itemSection`}>
                  <p className={`itemHeading typographyH6`}>
                    {`Date Created`}
                    <br />
                    <span className={`itemValue typographyH6`}>{item.createdAt}</span>
                  </p>
                  <p className={`itemHeading typographyH6`}>
                    {`Name`}
                    <br />
                    <span className={`itemValue typographyH6`}>{item.name}</span>
                  </p>
                </div>
                <div className="downloadButtonContainer">
                  <Button
                    variant="contained"
                    className={`downloadButton`}
                    data-test="reports-download-button"
                    onClick={downloadFile}
                    id={item.uuid}
                    value={item.url}
                    aria-label={`Download ${item.name}`}
                  >
                    <GetAppSharpIcon style={{ fontSize: 55 }} />
                  </Button>
                </div>
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {results.length > 0 && !hasErrors && (
        <div className="resultsDateRange">
          <p className="typographyH6">{`Results for "${dateFormatSimple(startDate, false)} ${
            useDateRange ? " - " + dateFormatSimple(endDate, false) : ""
          } - GMT"`}</p>
        </div>
      )}
      <div className={`filterBySection`}>
        <p className={`typographyH5 filterByHeading`}>Filter By</p>
        <div className={`filtersContainer`}>
          <FormControl component="fieldset" className={`filterByFrequencyRadios`}>
            <RadioGroup
              aria-label="frequency-options"
              id="frequencyRadio"
              name="frequencyType"
              value={frequencyType}
              onChange={handleFrequencyChange}
            >
              <FormControlLabel
                value={`${reportFrequencyMap[REPORT_FREQUENCY_ALL]}`}
                control={<Radio />}
                label={reportFrequencyMap[REPORT_FREQUENCY_ALL]}
                className={`typographyP`}
              ></FormControlLabel>
              <FormControlLabel
                value={`${reportFrequencyMap[REPORT_FREQUENCY_DAILY]}`}
                control={<Radio />}
                label={reportFrequencyMap[REPORT_FREQUENCY_DAILY]}
                className={`typographyP`}
              ></FormControlLabel>
              <FormControlLabel
                value={`${reportFrequencyMap[REPORT_FREQUENCY_MONTHLY]}`}
                control={<Radio />}
                label={reportFrequencyMap[REPORT_FREQUENCY_MONTHLY]}
                className={`typographyP`}
              ></FormControlLabel>
            </RadioGroup>
          </FormControl>
          <div className={`datePickersContainer`}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant="inline"
                inputVariant={`filled`}
                format="yyyy-MM-dd"
                id="startDate"
                label={`${useDateRange ? "Start Date" : "Single Date"}`}
                value={startDate}
                onChange={setStartDate}
                className={`datePickerInput`}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                refuse={/[^\d]+/gi}
                maxDate={new Date()}
                error={startDateError}
                helperText={startDateError ? "Please select a start date" : null}
              />
            </MuiPickersUtilsProvider>
            {useDateRange && (
              <React.Fragment>
                <p className={`typographyP`}>to</p>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    inputVariant={`filled`}
                    format="yyyy-MM-dd"
                    id="endDate"
                    label="End Date"
                    value={endDate}
                    onChange={setEndDate}
                    className={`datePickerInput`}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    refuse={/[^\d]+/gi}
                    minDate={startDate}
                    maxDate={new Date()}
                    error={endDateError}
                    helperText={endDateError ? "End date must occur after start date." : null}
                  />
                </MuiPickersUtilsProvider>
              </React.Fragment>
            )}
            <div className={`toggleLinkContainer`}>
              <Button
                id={`singleRangeToggle`}
                className={`link datePickerToggle`}
                variant="text"
                value={`${useDateRange ? "range" : "single"}`}
                onClick={toggleDatePickers}
              >
                {`${useDateRange ? "Single Date" : "Add Date Range"}`}
              </Button>
            </div>
          </div>
          <div className={`buttonsContainer`}>
            <div className={`searchButtonContainer`}>
              <Button
                variant="contained"
                className={`searchButton`}
                data-test="reports-search-button"
                onClick={searchRecords}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={`resultsSection`}>
        <div className={`${styles.resultsTable}`}>
          <div className={`${styles.tableBody}`}>
            {results.length === 0 && (
              <p className={`typographyP statusText`}>Search using your filter choices.</p>
            )}
            {results.length === 0 && hasErrors && (
              <p className={`typographyP error statusText`}>Oops, no results found. Try again.</p>
            )}
            {results.length > 0 && !hasErrors && <ResultCard />}
          </div>
          {results.length > 0 && !hasErrors && (
            <div className={`paginationContainer`}>
              <TablePagination
                className={styles.reportsPagination}
                align={`right`}
                component={`div`}
                page={page}
                count={resultsCount}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[5, 10, 25]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default VLReportsComponent
