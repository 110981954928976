import React from "react"
import ReportsComponent from "../../reports"

const ReportsPage = () => {
  return (
    <div className="mainContainer reports">
      <ReportsComponent />
    </div>
  )
}

export default ReportsPage
