import React from "react"
import ProfileComponent from "../../profile"

const ProfilePage = () => {
  return (
    <div className="Profile">
      <ProfileComponent />
    </div>
  )
}

export default ProfilePage
