import React, { useState } from "react"
import * as styles from "../shipping/shipping.module.scss"
import { Button, Dialog, DialogTitle } from "@material-ui/core"
import PropTypes from "prop-types"

/**
 * VerifyAddressDialog
 * Opens dialog with message from USPS and allows user to
 * a) user their entered address
 * b) use the found USPS address
 * c) edit their entered address (if not found)
 *
 * @param props
 */
export default function VerifyAddressDialog(props) {
  const { onClose, open, inputAddress, verifiedAddress, parentCallback } = props
  const [inputSelected, setInputSelected] = useState(true)
  const [uspsSelected, setUspsSelected] = useState(false)

  var confirmAddressText = "Use This Address"
  var cancelText = "Cancel"
  var closeText = "Close"
  var dialogMessage = " has suggested a change in address"
  var errorFound = false
  var formattedInputZip = inputAddress.shipZipCode
  if (formattedInputZip) {
    if (formattedInputZip.length === 9) {
      formattedInputZip =
        inputAddress.shipZipCode.slice(0, 5) + "-" + inputAddress.shipZipCode.slice(5)
    } else {
      formattedInputZip = inputAddress.shipZipCode
    }
  }

  Object.keys(verifiedAddress).forEach(prop => {
    if (prop === "errorDescription") {
      dialogMessage = verifiedAddress[prop]
      errorFound = true
    }
  })

  const handleClose = () => {
    onClose()
  }

  const toggleClass = selectedAddress => {
    if (selectedAddress === "input") {
      setInputSelected(true)
      setUspsSelected(false)
    } else {
      //selectedAddress === "usps"
      setUspsSelected(true)
      setInputSelected(false)
    }
  }

  const handleConfirmationClick = value => {
    //finish shipping info in shipping/index
    if (value === "Address") {
      let confirmedAddress = inputAddress
      if (uspsSelected) {
        confirmedAddress = verifiedAddress
      }
      parentCallback(confirmedAddress)
    } else {
      //User selected cancel
      parentCallback(value)
    }

    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
      <DialogTitle className={styles.validationDialogTitle}>USPS {dialogMessage}</DialogTitle>

      {!errorFound ? (
        <div className={styles.validationDialogMessage}>
          Please select an address to use, or cancel to edit.
        </div>
      ) : (
        <div className={styles.validationDialogMessage}>
          We have not been able to verify the address you have entered. Please provide a valid
          address to proceed.
        </div>
      )}

      <div>
        <div className={styles.validationDialogBody}>
          <Button
            className={
              inputSelected
                ? styles.validationDialogAddressBox +
                  " " +
                  styles.addressSelected +
                  " " +
                  styles.validationLeftColumn
                : styles.validationDialogAddressBox + " " + styles.validationLeftColumn
            }
            onClick={() => toggleClass("input")}
          >
            <div>
              <div>{inputAddress.shipAddress1}</div>
              <div>{inputAddress.shipAddress2}</div>
              <div>{inputAddress.shipCity}</div>
              <div>{inputAddress.shipState}</div>
              <div>{formattedInputZip}</div>
            </div>
          </Button>

          {!errorFound ? (
            <Button
              className={
                uspsSelected
                  ? styles.validationDialogAddressBox + " " + styles.addressSelected
                  : styles.validationDialogAddressBox
              }
              onClick={() => toggleClass("usps")}
            >
              <div>
                <div>{verifiedAddress.shipAddress1}</div>
                <div>{verifiedAddress.shipAddress2}</div>
                <div>{verifiedAddress.shipCity}</div>
                <div>{verifiedAddress.shipState}</div>
                {verifiedAddress.shipZip4 ? (
                  <div>
                    {verifiedAddress.shipZip5}-{verifiedAddress.shipZip4}
                  </div>
                ) : (
                  <div>{verifiedAddress.shipZip5}</div>
                )}
              </div>
            </Button>
          ) : (
            ""
          )}
        </div>
        <div className={styles.validationDialogBody}>
          <div className={`${styles.sectionHalf} ${styles.validationLeftColumn}`}>
            <Button
              className={`${styles.validationDialogButton} ${
                errorFound ? " buttonPrimary" : " buttonSecondary"
              }`}
              onClick={() => handleConfirmationClick(errorFound ? "Close" : "Cancel")}
            >
              {errorFound ? closeText : cancelText}
            </Button>
          </div>
          {!errorFound && (
            <div className={styles.sectionHalf}>
              <Button
                color="primary"
                variant="contained"
                className={styles.validationDialogButton + " " + styles.buttonPrimary}
                onClick={() => handleConfirmationClick("Address")}
              >
                {confirmAddressText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}

VerifyAddressDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  inputAddress: PropTypes.object,
  verifiedAddress: PropTypes.object,
  parentCallback: PropTypes.func,
}
