import React, { useCallback, useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { isEqual, toUpper } from "lodash"
import { AppDispatchContext, AppStateContext } from "../../../../context/appContext"
import { UPDATE_SHIPPING } from "../../../../constants/actionTypes"
import { AUTH_PROVIDER_CLOVER } from "../../../../constants/authProvider"
import * as styles from "../shipping/shipping.module.scss"
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Grid,
  TextField,
} from "@material-ui/core"
import { getPricingByCategory } from "../../../../services/pricing"
import { checkEmptyFields } from "../../../../services/validate"
import { formatZipcode } from "../../../creditCard/utils"
import { validateCharacters } from "../../../../utils/characterValidation"
import { validateAddressFields } from "../../../../utils/validateAddressFields"
import VerifyAddressDialog from "./verifyAddressDialog"
import { OrderNavigation } from "../../../orderNavigation"

// production URL = const uspsServerUrl = 'https://production.shippingapis.com/ShippingAPI.dll'
const secureUspsServerUrl = "https://secure.shippingapis.com/ShippingAPI.dll"
// smb-plastic-web UserID below;  '439TRANS4636' TransactionWireless
const uspsServerUsername = "979GYFT05326"

const ShippingPage = () => {
  const dispatch = useContext(AppDispatchContext)
  const { order, profileAddress, authProvider } = useContext(AppStateContext)

  const shipping = order.shipping
  const hasShippingAddress =
    Object.prototype.hasOwnProperty.call(shipping, "shipToAddress") &&
    !!shipping.shipToAddress.shipAddress1
  const { shipToAddress = {}, requestedShipMethod } = shipping

  const [addressInputs, setAddressInputs] = useState({
    label: shipToAddress.label || "",
    shipAddress1: shipToAddress.shipAddress1 || "",
    shipAddress2: shipToAddress.shipAddress2 || "",
    shipCity: shipToAddress.shipCity || "",
    shipState: shipToAddress.shipState || "",
    shipZipCode: shipToAddress.shipZipCode || "",
    requestedShipMethod,
  })
  const [customShippingAddress, setCustomShippingAddress] = useState({
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "",
    shipZipCode: "",
    requestedShipMethod: "GROUND",
  })
  const [openSelects, setOpenSelects] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [addressDialogOpen, setAddressDialogOpen] = useState(false)
  const [dialogInputAddress, setDialogInputAddress] = useState({})
  const [dialogVerifiedAddress, setDialogVerifiedAddress] = useState({})
  const [addressValidated, setAddressValidated] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [addressNotFound, setAddressNotFound] = useState(false)
  const useStyles = makeStyles({
    checkboxContainer: {
      marginBottom: "1rem",
      minWidth: "100%",
    },
  })
  const classes = useStyles()
  const [addressIsPOBox, setIsPOBox] = useState(false)
  const nextLabel = "Review Order"
  const prevLabel = authProvider === AUTH_PROVIDER_CLOVER ? "Back: Card Back" : "Back: Accessories"

  const pricing = getPricingByCategory("shipping", undefined, authProvider)
  // sort by cost
  pricing.sort((a, b) => {
    if (a.total_price < b.total_price) {
      return -1
    } else if (a.total_price > b.total_price) {
      return 1
    } else {
      return 0
    }
  })

  const shippingOpts = pricing.reduce(
    (result, price) => {
      const { label, total_price, sub_category } = price
      const optValue = sub_category.toUpperCase()
      return {
        ...result,
        [optValue]: {
          display: label,
          value: optValue,
          cost: total_price,
        },
      }
    },
    {
      /* initial value */
    }
  )

  // Warn users before: reload, close window, close tab
  window.addEventListener("beforeunload", function (e) {
    const pathname = window.location.pathname
    //Paths for orders: card-front, card-back accessories shipping review-order payment
    if (
      pathname === "/app/order/card-front" ||
      pathname === "/app/order/card-front-deluxe" ||
      pathname === "/app/order/designer" ||
      pathname === "/app/order/card-back" ||
      pathname === "/app/order/accessories" ||
      pathname === "/app/checkout/shipping" ||
      pathname === "/app/checkout/review-order" ||
      pathname === "/app/checkout/payment" ||
      pathname === "/app/checkout/payment/" ||
      pathname === "/app/order/designer"
    ) {
      e.returnValue = ""
    }
  })

  const handleSelectClose = event => {
    let temp = {}
    temp[event.target.id] = false
    setOpenSelects(temp)
  }

  const handleSelectOpen = event => {
    let temp = {}
    temp[event.target.id] = true
    setOpenSelects(temp)
  }

  const handleShippingAddressCheck = e => {
    setIsChecked(e.target.checked)

    if (!e.target.checked) {
      const elements = document.querySelectorAll("[required]")
      elements.forEach(element => {
        if (element.name in customShippingAddress) {
          addressInputs[element.name] = customShippingAddress[element.name]
        }
      })
      //The optional fields
      addressInputs["label"] = customShippingAddress["label"]
      addressInputs["shipAddress2"] = customShippingAddress["shipAddress2"]
      addressInputs["requestedShipMethod"] = customShippingAddress["requestedShipMethod"]

      setAddressInputs(addressInputs)
    } else {
      //Store current entries as custom address
      const elements = document.querySelectorAll("[required]")
      elements.forEach(element => {
        if (element.name in addressInputs) {
          customShippingAddress[element.name] = addressInputs[element.name]
        }
      })
      //The optional fields
      customShippingAddress["label"] = addressInputs["label"]
      customShippingAddress["shipAddress2"] = addressInputs["shipAddress2"]

      let defaultAddress = {
        requestedShipMethod: customShippingAddress["requestedShipMethod"] || "GROUND",
      }
      for (const [addressInputKey, addressInputValue] of Object.entries(addressInputs)) {
        if (addressInputKey in profileAddress) {
          defaultAddress[addressInputKey] = profileAddress[addressInputKey]
        } else {
          defaultAddress[addressInputKey] = addressInputValue
        }
      }
      setFormErrors({})
      setAddressInputs({ ...defaultAddress })
      setAddressNotFound(false)
    }
    setNextButtonDisabled(checkEmptyFields())
  }

  const clearNumber = value => {
    return value.replace(/\D+/g, "")
  }

  const clearAlpha = value => {
    return value.replace(/[^a-zA-Z. ]/g, "")
  }

  const handleAddressInput = event => {
    const { name, value, type } = event.target
    const errors = { ...formErrors }
    let clearValue = value
    if (type === "tel") {
      clearValue = clearNumber(value)
    }
    if (type === "text" && name === "shipState") {
      clearValue = clearAlpha(value)
    }
    if (name === "shipZipCode") {
      //Strip non digits from Zip (in case of "forms" autofill)
      clearValue = value.replace(/[\D-]/g, "")
      //When autofill is used, lengths may not be calculated properly if digits are stripped
      if (clearValue.length < 5) {
        errors[name] = "Please Enter a Valid Zipcode."
      }
    }
    //Since this is a non-required field we wil check it at time of entry
    if (name === "shipAddress1" || name === "shipAddress2") {
      if (/\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i.test(value.trim())) {
        errors[name] = "P.O. Boxes are not permitted."
        setIsPOBox(true)
      } else {
        setIsPOBox(false)
      }
    }

    //Store the requested shipping method without altering address information
    if (name === "requestedShipMethod") {
      addressInputs["requestedShipMethod"] = value
      setCustomShippingAddress({
        ...customShippingAddress,
        [name]: value,
      })
    } else {
      setCustomShippingAddress({
        ...addressInputs,
        [name]: value,
      })
      //Make sure we reset the validation state, so usps can check again
      setAddressValidated(false)
      setIsChecked(false)
    }

    setFormErrors(errors)
    setAddressNotFound(false)
    setAddressInputs({
      ...addressInputs,
      [name]: clearValue,
    })
  }

  const handleInputBlur = event => {
    const { name, value, attributes } = event.target
    const tempErrors = { ...formErrors }
    const error_text = validateAddressFields(name, value, attributes)
    tempErrors[name] = error_text
    setFormErrors(tempErrors)
  }

  useEffect(() => {
    const checkEmptyFormErrors = () => {
      let isEmpty = false

      if (formErrors) {
        for (const errorKey in formErrors) {
          if (formErrors[errorKey] && formErrors[errorKey] !== undefined) {
            isEmpty = true
            break
          }
        }
      }

      return isEmpty
    }

    setNextButtonDisabled(checkEmptyFields("[required]") || checkEmptyFormErrors())
  }, [formErrors])

  const handleSubmit = useCallback(() => {
    /**
     * verifyShippingAddressWithUsps
     *
     * @param uspsAddressToCheck - the address object containing shipping information
     */
    const verifyShippingAddressWithUsps = uspsAddressToCheck => {
      let encodedAddress1 = encodeURIComponent(uspsAddressToCheck.shipAddress1)
      let encodedAddress2 = encodeURIComponent(uspsAddressToCheck.shipAddress2)
      let encodedCity = encodeURIComponent(uspsAddressToCheck.shipCity)

      if (/%26/.test(encodedAddress1.trim())) {
        encodedAddress1 = encodedAddress1.replace(/%26/g, "%26amp;")
      }

      if (/%26/.test(encodedAddress2.trim())) {
        encodedAddress2 = encodedAddress2.replace(/%26/g, "%26amp;")
      }

      if (/%26/.test(encodedCity.trim())) {
        encodedCity = encodedCity.replace(/%26/g, "%26amp;")
      }

      //Build the xml required by USPS validation api
      let xmlAddress =
        '<AddressValidateRequest USERID="' +
        uspsServerUsername +
        '">' +
        "<IncludeOptionalElements>true</IncludeOptionalElements>" +
        "<ReturnCarrierRoute>true</ReturnCarrierRoute>" +
        "<Address ID='0'>" +
        "<FirmName />" +
        "<Address1>" +
        encodedAddress2 +
        "</Address1>" +
        "<Address2>" +
        encodedAddress1 +
        "</Address2>" +
        "<City>" +
        encodedCity +
        "</City>" +
        "<State>" +
        encodeURIComponent(uspsAddressToCheck.shipState) +
        "</State>" +
        "<Zip5>" +
        encodeURIComponent(uspsAddressToCheck.shipZipCode) +
        "</Zip5>" +
        "<Zip4></Zip4>" +
        "</Address>" +
        "</AddressValidateRequest>"

      //Build the URL
      let fetchAddress = secureUspsServerUrl + "?API=Verify&XML=" + xmlAddress

      fetch(fetchAddress)
        .then(async response => {
          const data = await response.text()
          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            console.error("USPS response = not OK")
          }
          handleUspsValidation(uspsAddressToCheck, data)
        })
        .catch(error => {
          console.error("Error calling USPS Api", error)
        })
    }

    const inputs = document.querySelectorAll("div.shipping_fields > div > input")
    if (validateAddressFields) {
      const shipToAddress = {}
      inputs.forEach(({ name }) => {
        shipToAddress[name] = addressInputs[name] || ""
      })

      //Set the dialog user input address
      setDialogInputAddress(shipToAddress)

      //Make sure we have user validate before moving forward
      if (addressValidated) {
        const requestedShipMethod =
          typeof addressInputs["requestedShipMethod"] !== "undefined"
            ? addressInputs["requestedShipMethod"]
            : "GROUND"

        //Format the zipcode (only for DB, not UI), dropping any non-numeric digits such as "-"
        shipToAddress["shipZipCode"] = shipToAddress["shipZipCode"].replace(/-/g, "")

        dispatch({
          type: UPDATE_SHIPPING,
          payload: {
            shipToAddress,
            requestedShipMethod,
            cost: shippingOpts[requestedShipMethod].cost,
          },
        })
        navigate("/app/checkout/review-order")
      } else {
        //Send the address to validation
        verifyShippingAddressWithUsps(shipToAddress)
      }
    }
  }, [addressInputs, addressValidated, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  const navigateBack = () => {
    if (authProvider === AUTH_PROVIDER_CLOVER) {
      navigate("/app/order/card-back")
    } else {
      navigate("/app/order/accessories")
    }
  }

  const handleClose = () => {
    setAddressDialogOpen(false)
  }

  /**
   * compareAddressFields
   * use lodash library to compare the address fields
   *
   * @param address - user input address
   * @param uspsAddress - usps response address
   */
  const compareAddressFields = (address, uspsAddress) => {
    let formattedAddressZip = address.shipZipCode
    if (formattedAddressZip.length === 9) {
      formattedAddressZip = formattedAddressZip.slice(0, 5) + "-" + formattedAddressZip.slice(5)
    }

    let uspsFormattedZip = uspsAddress.shipZip5 + "-" + uspsAddress.shipZip4
    let address2Match = false
    if (!address.shipAddress2 && !uspsAddress.shipAddress2) {
      address2Match = true
    } else {
      address2Match = isEqual(toUpper(address.shipAddress2), toUpper(uspsAddress.shipAddress2))
    }

    return (
      isEqual(toUpper(address.shipAddress1), toUpper(uspsAddress.shipAddress1)) &&
      address2Match &&
      isEqual(toUpper(address.shipCity), toUpper(uspsAddress.shipCity)) &&
      isEqual(toUpper(address.shipState), toUpper(uspsAddress.shipState)) &&
      isEqual(toUpper(formattedAddressZip), toUpper(uspsFormattedZip))
    )
  }

  /**
   * handleUspsValidation
   *
   * @param originalAddress - address entered on the page
   * @param responseData - response object from USPS api call
   */
  const handleUspsValidation = (originalAddress, responseData) => {
    const parser = new DOMParser()
    const xml = parser.parseFromString(responseData, "text/xml")

    const addressFromXml = {}
    let addressMatches = false

    let error = xml.getElementsByTagName("Error").length
    if (error === 0) {
      addressFromXml["shipAddress1"] =
        xml.getElementsByTagName("Address2")[0].childNodes[0].nodeValue
      //It is a possibility that suite/apt number will be null
      if (xml.getElementsByTagName("Address1").length !== 0) {
        addressFromXml["shipAddress2"] =
          xml.getElementsByTagName("Address1")[0].childNodes[0].nodeValue
      }
      addressFromXml["shipCity"] = xml.getElementsByTagName("City")[0].childNodes[0].nodeValue
      addressFromXml["shipState"] = xml.getElementsByTagName("State")[0].childNodes[0].nodeValue
      addressFromXml["shipZip5"] = xml.getElementsByTagName("Zip5")[0].childNodes[0].nodeValue

      //Not all addresses have a +4 zip
      if (xml.getElementsByTagName("Zip4")[0].childNodes[0]) {
        addressFromXml["shipZip4"] = xml.getElementsByTagName("Zip4")[0].childNodes[0].nodeValue
      }

      addressMatches = compareAddressFields(originalAddress, addressFromXml)
    } else {
      //We have an error returned from usps, get the description
      addressFromXml["errorDescription"] = xml
        .getElementsByTagName("Description")[0]
        .childNodes[0].nodeValue.trim()
        .replace(/[^a-z0-9 ]/gi, "")
      setAddressNotFound(true)
    }

    //Set the usps verified address, if any
    setDialogVerifiedAddress(addressFromXml)

    //No need to open the dialog if user entered a USPS verified address
    if (!addressMatches) {
      //Open the dialog
      setAddressDialogOpen(true)
    } else {
      setAddressValidated(true)
    }
  }

  /**
   * updateShippingAddress
   *
   * @param selectedAddress - the address selected by user from dialog
   */
  const updateShippingAddress = selectedAddress => {
    //The user wants to go back and edit the address
    if (selectedAddress === "Cancel" || selectedAddress === "Close") {
      setAddressValidated(false)
      if (selectedAddress === "Close") {
        setAddressNotFound(true)
      }
    } else {
      let updatedZipcode = selectedAddress["shipZip5"]
      //User is not using the USPS address
      if (updatedZipcode === undefined) {
        updatedZipcode = selectedAddress["shipZipCode"]
      } else {
        updatedZipcode =
          selectedAddress["shipZip5"] +
          (selectedAddress["shipZip4"] ? "-" + selectedAddress["shipZip4"] : "")
      }

      addressInputs["shipAddress1"] = selectedAddress["shipAddress1"]
      addressInputs["shipAddress2"] = selectedAddress["shipAddress2"]
      addressInputs["shipCity"] = selectedAddress["shipCity"]
      addressInputs["shipState"] = selectedAddress["shipState"]
      addressInputs["shipZipCode"] = updatedZipcode

      setAddressInputs({
        ...addressInputs,
      })
      setAddressValidated(true)
    }
  }

  useEffect(() => {
    let addressValidatedMount = true

    const matchProfileAddress = () => {
      let match = true
      for (const [addressInputKey, addressInputValue] of Object.entries(addressInputs)) {
        if (addressInputKey in profileAddress) {
          if (profileAddress[addressInputKey] !== addressInputValue) {
            match = false
            break
          }
        }
      }

      return match
    }

    if (hasShippingAddress && matchProfileAddress()) {
      setIsChecked(true)
    }

    if (!addressIsPOBox && !addressNotFound) {
      setNextButtonDisabled(checkEmptyFields())
    } else {
      setNextButtonDisabled(true)
    }

    if (addressValidatedMount) {
      if (addressValidated) {
        handleSubmit()
      }
    }
    return () => (addressValidatedMount = false)
  }, [
    hasShippingAddress,
    addressInputs,
    profileAddress,
    isChecked,
    addressIsPOBox,
    addressValidated,
    handleSubmit,
    addressNotFound,
  ])

  return (
    <>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionLeft}>
          <h4 className={styles.sectionTitle}>shipping</h4>
          <div className={styles.formContainer}>
            <div className={styles.sectionFull}>
              <Grid container spacing={3}>
                <h5 className={styles.h5Style}>Please Note: we do not ship to PO Boxes.</h5>
                <Grid item xs={12}>
                  {profileAddress &&
                    Object.keys(profileAddress).length > 0 &&
                    profileAddress.shipAddress1 && (
                      <FormControlLabel
                        className={`${classes.checkboxContainer}`}
                        control={
                          <Checkbox
                            name="defaultAddressCheckbox"
                            checked={isChecked}
                            onChange={handleShippingAddressCheck}
                          />
                        }
                        label="Use default shipping address"
                      />
                    )}
                  <FormControl variant="filled" className={styles.fullWidth}>
                    <TextField
                      className={`shipping_fields`}
                      type={`text`}
                      id={`label`}
                      name={`label`}
                      variant="filled"
                      label={`Shipping Label`}
                      fullWidth={true}
                      inputProps={{ maxLength: 30 }}
                      onChange={handleAddressInput}
                      onBlur={handleInputBlur}
                      value={addressInputs["label"] || ""}
                      required
                      helperText={formErrors.label}
                      error={formErrors.label ? true : undefined}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="filled" className={styles.fullWidth}>
                    <TextField
                      className={`shipping_fields`}
                      type={`text`}
                      id={`shipAddress1`}
                      name={`shipAddress1`}
                      variant="filled"
                      label={`Address 1`}
                      fullWidth={true}
                      inputProps={{ maxLength: 30 }}
                      onChange={handleAddressInput}
                      onBlur={handleInputBlur}
                      value={addressInputs["shipAddress1"] || ""}
                      required
                      helperText={formErrors.shipAddress1}
                      onKeyDown={validateCharacters}
                      error={formErrors.shipAddress1 ? true : undefined}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="filled" className={styles.fullWidth}>
                    <TextField
                      className={`shipping_fields`}
                      type={`text`}
                      id={`shipAddress2`}
                      name={`shipAddress2`}
                      variant="filled"
                      label={`Address 2`}
                      fullWidth={true}
                      inputProps={{ maxLength: 30 }}
                      onChange={handleAddressInput}
                      onBlur={handleInputBlur}
                      value={addressInputs["shipAddress2"] || ""}
                      helperText={formErrors.shipAddress2}
                      onKeyDown={validateCharacters}
                      error={formErrors.shipAddress2 ? true : undefined}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="filled" className={styles.fullWidth}>
                    <TextField
                      className={`shipping_fields`}
                      type={`text`}
                      id={`shipCity`}
                      name={`shipCity`}
                      variant="filled"
                      label={`City`}
                      fullWidth={true}
                      inputProps={{ maxLength: 30 }}
                      onChange={handleAddressInput}
                      onBlur={handleInputBlur}
                      value={addressInputs["shipCity"] || ""}
                      required
                      helperText={formErrors.shipCity}
                      onKeyDown={validateCharacters}
                      error={formErrors.shipCity ? true : undefined}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="filled" className={styles.fullWidth}>
                    <TextField
                      className={`shipping_fields`}
                      type={`text`}
                      id={`shipState`}
                      name={`shipState`}
                      variant="filled"
                      label={`State`}
                      fullWidth={true}
                      inputProps={{ maxLength: 2 }}
                      onChange={handleAddressInput}
                      onBlur={handleInputBlur}
                      value={addressInputs["shipState"] || ""}
                      required
                      helperText={
                        formErrors.shipState
                          ? formErrors.shipState
                          : "Enter a 2 Letter State Code eg. CA or MA"
                      }
                      error={formErrors.shipState ? true : undefined}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="filled" className={styles.fullWidth}>
                    <TextField
                      className={`additional_fields`}
                      type={`text`}
                      id={`shipCountry`}
                      name={`shipCountry`}
                      variant="filled"
                      label={`Country`}
                      fullWidth={true}
                      inputProps={{ maxLength: 30 }}
                      onChange={handleAddressInput}
                      onBlur={handleInputBlur}
                      disabled={true}
                      defaultValue={`United States`}
                      helperText={formErrors.shipCountry}
                      error={formErrors.shipCountry ? true : undefined}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="filled" className={styles.fullWidth}>
                    <TextField
                      className={`shipping_fields`}
                      id={`shipZipCode`}
                      name={`shipZipCode`}
                      variant="filled"
                      label={`Postal Code`}
                      fullWidth={true}
                      inputProps={{ maxLength: 10, minLength: 5 }}
                      onChange={handleAddressInput}
                      onBlur={handleInputBlur}
                      value={formatZipcode(addressInputs["shipZipCode"])}
                      required
                      helperText={formErrors.shipZipCode}
                      error={formErrors.shipZipCode ? true : undefined}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth={true}
                    variant="filled"
                    className={styles.fullWidth}
                    error={formErrors.requestedShipMethod ? true : undefined}
                  >
                    <InputLabel id={`shipping_method_label`}>Shipping Method</InputLabel>
                    <Select
                      className={`additional_fields`}
                      fullWidth={true}
                      labelId={`shipping_method_label`}
                      variant="filled"
                      id={`requestedShipMethod`}
                      name={`requestedShipMethod`}
                      label={`Shipping Method`}
                      open={openSelects["requestedShipMethod"]}
                      onClose={handleSelectClose}
                      onOpen={handleSelectOpen}
                      value={addressInputs["requestedShipMethod"] || "GROUND"}
                      onChange={handleAddressInput}
                    >
                      {Object.values(shippingOpts).map((key, indexNumber) => {
                        const { display, value } = key
                        return (
                          <MenuItem key={indexNumber} value={value}>
                            {display}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className={styles.sectionRight}>
          {OrderNavigation(navigateBack, prevLabel, handleSubmit, nextLabel, nextButtonDisabled)}
        </div>
        <VerifyAddressDialog
          inputAddress={dialogInputAddress}
          verifiedAddress={dialogVerifiedAddress}
          open={addressDialogOpen}
          onClose={handleClose}
          parentCallback={updateShippingAddress}
        />
      </div>
    </>
  )
}

export default ShippingPage
