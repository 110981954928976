import JC_SIL from "../images/marketing/carriers/JC-SIL.png"
import JC_BLK from "../images/marketing/carriers/JC-BLK.png"
import JC_BLU from "../images/marketing/carriers/JC-BLU.png"
import JC_BRG from "../images/marketing/carriers/JC-BRG.png"
import JC_GLD from "../images/marketing/carriers/JC-GLD.png"
import JC_GRN from "../images/marketing/carriers/JC-GRN.png"
import JC_RED from "../images/marketing/carriers/JC-RED.png"
import JC_WHT from "../images/marketing/carriers/JC-WHT.png"
import PK_GFT from "../images/marketing/carriers/PK-GFT.png"

export const getCarrierUrl = carrier => {
  if (carrier === "JC-SIL") return JC_SIL
  if (carrier === "JC-BLK") return JC_BLK
  if (carrier === "JC-BLU") return JC_BLU
  if (carrier === "JC-BRG") return JC_BRG
  if (carrier === "JC-GLD") return JC_GLD
  if (carrier === "JC-GRN") return JC_GRN
  if (carrier === "JC-RED") return JC_RED
  if (carrier === "JC-WHT") return JC_WHT
  if (carrier === "PK-GFT") return PK_GFT
}
