import { API, graphqlOperation } from "aws-amplify"
import { orderByOrderId } from "../graphql/queries"

//Generate an alphanumeric string for the Order Id
export async function generateOrderId(cardType) {
  let orderId = "AC"

  if (cardType && cardType.toUpperCase() === "STD") {
    orderId = "ST"
  } else if (cardType && cardType.toUpperCase() === "DELUXE") {
    orderId = "DE"
  } else if (cardType && cardType.toUpperCase() === "DESIGNER") {
    orderId = "DR"
  }

  for (let i = 0; i < 3; i++) {
    let randString = generateRandomString(6)
    let isAvailable = await isOrderIdAvailable(orderId + randString)
    if (isAvailable) {
      orderId += randString
      break
    }
  }

  return orderId.length > 2 ? orderId : ""
}

const generateRandomString = length => {
  let randString = ""
  const chars = "ABCDEFGHJKLMNPQRSTUVWXYZ"
  const nums = "1234567890"

  for (let i = 0; i < length; i++) {
    if (i % 2) {
      randString += nums.charAt(Math.floor(Math.random() * nums.length))
    } else {
      randString += chars.charAt(Math.floor(Math.random() * chars.length))
    }
  }

  return randString
}

async function isOrderIdAvailable(orderId) {
  const params = {
    orderId: orderId,
  }

  const {
    data: { orderByOrderId: orders },
  } = await API.graphql(graphqlOperation(orderByOrderId, params))

  return orders.items && Array.isArray(orders.items) && orders.items.length === 0
}
