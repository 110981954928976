import {
  bottom,
  card,
  cardOutline,
  cardArtBack,
  deluxeCardArtFront,
  cardArtFront,
  cardFaceBack,
  cardFaceFront,
  deluxeFrontCrop,
  reviewInfo,
  magstripe,
  numbersPlaceholderSpan,
  numbersPlaceholder,
  number,
  paymentDetails,
  top,
  cardIcon,
  billingDetails,
  shippingReference,
} from "../orderSummary/order-summary.module.scss"
import * as styles from "./order-summary.module.scss"
import classNames from "classnames"
import React, { useContext, useState } from "react"
import { AppStateContext } from "../../context/appContext"
import { isDesigner, isDeluxe } from "../../services/card"
import GiftCardExampleDesigner from "../img/giftCardExampleDesigner"
import { formatPaymentDetails } from "../../services/order-summary"
import { getCardIconFromType } from "../creditCard/utils"

const OrderSummaryPreviewComponent = ({ orderType }) => {
  const { order: currentOrder, recentOrder } = useContext(AppStateContext)
  const [isFlipped, setIsFlipped] = useState(false)
  const order = orderType ? recentOrder : currentOrder
  const invoicePage = orderType === "invoice"
  const cardType = order.cards.cardType

  const flipCard = () => {
    if (invoicePage) return
    setIsFlipped(!isFlipped)
  }

  const displayShippingReference = order => {
    if (order.status !== "PENDING") {
      return (
        <div className={shippingReference}>
          <strong>SHIPPING REFERENCE # {order.vlOrderId}</strong>
        </div>
      )
    }
  }

  const formatShippingAddress = addressObj => {
    const address = [addressObj.label, addressObj.shipAddress1]
    if (cardType !== "designer") {
      if (addressObj.shipAddress2) {
        address.push(addressObj.shipAddress2)
      }

      //The DB will not store any "-" characters, if the zip contains +4 we want UI to show "-"
      let rawZip = addressObj.shipZipCode
      if (rawZip.length === 9 && !/-/g.test(rawZip.trim())) {
        const dash = "-"
        const displayFormat = [rawZip.slice(0, 5), dash, rawZip.slice(5)].join("")
        rawZip = displayFormat
      }

      address.push(`${addressObj.shipCity}, ${addressObj.shipState} ${rawZip}`)
      return address.map((line, idx) => {
        return <div key={idx}>{line}</div>
      })
    }
  }

  const formatBillingAddress = paymentObj => {
    const address = [paymentObj.billingAddress1]
    if (paymentObj.billingAddress2) {
      address.push(paymentObj.billingAddress2)
    }
    address.push(
      `${paymentObj.billingCity}, ${paymentObj.billingState} ${paymentObj.billingZipCode}`
    )
    return address.map((line, idx) => {
      return <div key={idx}>{line}</div>
    })
  }

  const getFrontForOrder = () => {
    if (isDesigner(order)) {
      return (
        <div className={cardFaceFront}>
          {order.cards.cardFront.imgData ? (
            <img className={cardArtFront} src={order.cards.cardFront.imgData} alt="card front" />
          ) : (
            <GiftCardExampleDesigner />
          )}
        </div>
      )
    } else if (isDeluxe(order)) {
      return (
        <div
          className={classNames(card, isFlipped ? "isFlipped" : "")}
          onClick={flipCard}
          onKeyPress={flipCard}
          role="button"
          tabIndex="0"
        >
          <div className={cardFaceFront}>
            <svg
              className={deluxeFrontCrop}
              xmlns="http://www.w3.org/2000/svg"
              width="408"
              height="267"
              viewBox="0 0 1088 713"
            >
              <clipPath id="cardCrop">
                <rect x="37.5" y="37.5" width="1013px" height="638px" rx="16px" ry="16px" />
              </clipPath>
              <rect
                className={cardOutline}
                x="37.5"
                y="37.5"
                width="1013px"
                height="638px"
                rx="16px"
                ry="16px"
                fill="transparent"
              />
              <image
                className={deluxeCardArtFront}
                href={order.cards.cardFront.imgData}
                x="0"
                y="0"
                height="100%"
                width="100%"
                clipPath="url(#cardCrop)"
              />
            </svg>
            {!invoicePage ? (
              <div className={styles.flipInstructions}>Click on card to flip</div>
            ) : (
              ""
            )}
          </div>
          <div className={cardFaceBack}>
            <div className={magstripe} />
            <div className={numbersPlaceholder}>
              <span className={numbersPlaceholderSpan}>XXXX XXXX XXXX XXXX</span>
              <span className={number}>XXXXXXXXXX</span>
            </div>
            <img
              className={cardArtBack}
              src={order.cards.cardBack.imgData}
              alt="card back"
              title="Click on card to flip"
            />
            <div className={styles.flipInstructions}>Click on card to flip</div>
          </div>
        </div>
      )
    } else {
      //standard
      return (
        <div
          className={classNames(card, isFlipped ? "isFlipped" : "")}
          onClick={flipCard}
          onKeyPress={flipCard}
          role="button"
          tabIndex="0"
        >
          <div className={cardFaceFront}>
            <img
              className={cardArtFront}
              src={order.cards.cardFront.imgData}
              alt="card front"
              title="Click on card to flip"
            />
            {!invoicePage ? (
              <div className={styles.flipInstructions}>Click on card to flip</div>
            ) : (
              ""
            )}
          </div>
          <div className={cardFaceBack}>
            <div className={magstripe} />
            <div className={numbersPlaceholder}>
              <span className={numbersPlaceholderSpan}>XXXX XXXX XXXX XXXX</span>
              <span className={number}>XXXXXXXXXX</span>
            </div>
            <img
              className={cardArtBack}
              src={order.cards.cardBack.imgData}
              alt="card back"
              title="Click on card to flip"
            />
            <div className={styles.flipInstructions}>Click on card to flip</div>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div className={top}>{getFrontForOrder()}</div>
      <div className={paymentDetails}>
        <strong>PAYMENT DETAILS</strong>
        <br />
        <div data-test="payment-method">{formatPaymentDetails(order.payment)}</div>
        {orderType && order.payment.paymentDetails && order.payment.paymentDetails.cardholder && (
          <div>{order.payment.paymentDetails.cardholder}</div>
        )}
        {orderType &&
          order.payment.paymentDetails &&
          order.payment.paymentDetails.cardType &&
          order.payment.paymentDetails.lastFour && (
            <div>
              <img
                src={getCardIconFromType(order.payment.paymentDetails.cardType)}
                className={cardIcon}
                alt="Card Type Logo"
              />{" "}
              {order.payment.paymentDetails.lastFour}
            </div>
          )}
      </div>

      {orderType &&
        order.payment.paymentDetails &&
        order.payment.paymentDetails.billingAddress1 &&
        order.payment.paymentDetails.billingCity &&
        order.payment.paymentDetails.billingState &&
        order.payment.paymentDetails.billingZipCode && (
          <div className={billingDetails}>
            <strong>BILLING ADDRESS</strong>
            <br />
            {formatBillingAddress(order.payment.paymentDetails)}
          </div>
        )}

      {order.shipping.shipToAddress && (
        <div className={bottom}>
          {cardType === "designer" ? (
            <div />
          ) : (
            [
              displayShippingReference(order),
              <div className={reviewInfo} key="1">
                <strong>SHIPPING ADDRESS</strong>
                <br />
                {formatShippingAddress(order.shipping.shipToAddress)}
              </div>,
            ]
          )}
        </div>
      )}
    </>
  )
}

export default OrderSummaryPreviewComponent
