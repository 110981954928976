import React, { useState } from "react"
import { Button, FormControl, Grid, TextField } from "@material-ui/core"
import * as styles from "./settings.module.scss"
import { useStyles } from "./index"
import { PHYSICAL_CARD_TERMS } from "../../constants/terms"

const Terms = props => {
  const classes = useStyles()
  const textInputRef = React.createRef()
  const [updatedTerms, setUpdatedTerms] = useState(props.terms)
  const [saveTermsButtonDisabled, setSaveTermsButtonDisabled] = useState(!updatedTerms)
  const [shrinkLabel, setShrinkLabel] = useState(!updatedTerms)
  const [formErrors, setFormErrors] = useState(false)

  const saveTerms = async () => {
    await props.saveSettingsHandler(
      "physicalCardTerms",
      updatedTerms,
      "The Terms & Conditions have been saved successfully"
    )
    setSaveTermsButtonDisabled(true)
  }

  const resetTerms = () => {
    textInputRef.current.value = PHYSICAL_CARD_TERMS
    setUpdatedTerms(PHYSICAL_CARD_TERMS)
    setSaveTermsButtonDisabled(props.checkEmpty("[name='required-terms']"))
    setShrinkLabel(true)
  }

  const termsInput = event => {
    const type = event.type
    const length = event.target.value.length
    if (length < 1) {
      setFormErrors(true)
      setSaveTermsButtonDisabled(true)
      return
    } else {
      setFormErrors(false)
      setSaveTermsButtonDisabled(false)
    }
    if (type === "change") {
      setSaveTermsButtonDisabled(props.checkEmpty("[name='required-terms']"))
    }
    if (type === "blur") {
      setShrinkLabel(!props.checkEmpty("[name='required-terms']"))
    }
    setUpdatedTerms(event.target.value.trim())
  }

  return (
    <>
      <Grid container direction="column">
        <h4 className={styles.subSectionTitle} data-test="settings-page-title">
          Terms & Conditions
        </h4>
        <FormControl fullWidth variant="filled" className={styles.fullWidth}>
          <TextField
            id={`settings_terms`}
            className={styles.termsAndConditions}
            type={`text`}
            variant="filled"
            name={`required-terms`}
            label={`Terms & Conditions`}
            key={props.terms}
            inputRef={textInputRef}
            fullWidth={true}
            multiline
            rows={3}
            inputProps={{ maxLength: 245 }}
            InputLabelProps={{ shrink: shrinkLabel }}
            defaultValue={props.terms}
            onChange={termsInput}
            onBlur={termsInput}
            onFocus={e => setShrinkLabel(true)}
            required
            error={formErrors ? true : undefined}
            helperText={formErrors ? `This field is required.` : ""}
          />
        </FormControl>
      </Grid>
      <Grid container className={classes.settingsTermsButtonGrid}>
        <Button
          variant="contained"
          color="default"
          id="reset-terms-button"
          className={`buttonSecondary`}
          onClick={resetTerms}
          style={{ marginRight: "0.5rem" }}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          color="primary"
          id="save-terms-button"
          className="buttonPrimary"
          onClick={saveTerms}
          disabled={saveTermsButtonDisabled}
        >
          Save Terms
        </Button>
      </Grid>
    </>
  )
}

export default Terms
