import React, { useContext, useState } from "react"
import CreditCardComponent from "../../../creditCard"
import { Backdrop, Card, CardContent, CircularProgress } from "@material-ui/core"
import * as styles from "./payments.module.scss"
import { AppDispatchContext, AppStateContext } from "../../../../context/appContext"
import { SET_ORDER_HISTORY, UPDATE_NOTIFICATION } from "../../../../constants/actionTypes"
import { navigate } from "gatsby"
import { getCardType, getTotalCost } from "../../../../services/card"
import { API, graphqlOperation } from "aws-amplify"
import { submitOrder } from "../../../../graphql/mutations"
import { isBrowser } from "../../../../services/auth"
import { backdrop } from "../../../creditCard/credit-card.module.scss"
import Notification from "../../../notification"
import OrderSummaryReceiptComponent from "../../../orderSummary/receipt"
import agreementCheck from "../agreementCheck/index.js"
import { OrderNavigation } from "../../../orderNavigation"
import { getSubmitOrderJson } from "../../../../utils/submitOrderData"

const PaymentPage = () => {
  const dispatch = useContext(AppDispatchContext)
  const {
    order: appOrder,
    orderHistory,
    user,
    profileAddress,
    authProvider,
  } = useContext(AppStateContext)
  const [submitCalled, setSubmitCalled] = useState(false)
  const [endCallInProgress, setEndCallInProgress] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [pricingChecked, setPricingChecked] = useState(false)
  const [paymentData, setPaymentData] = useState(false)
  const isDesignerOrder = appOrder?.cards?.cardType.toLowerCase() === "designer"
  const nextLabel = "Order Cards"
  const prevLabel = isDesignerOrder ? "Back: Designer" : "Back: Review Order"
  const handleSubmit = () => {
    setButtonDisabled(true)
    setShowBackdrop(true)
    setSubmitCalled(true)
  }

  const navigateBack = () => {
    if (isDesignerOrder) {
      navigate("/app/order/designer")
    } else {
      navigate("/app/checkout/review-order")
    }
  }

  // Warn users before: reload, close window, close tab
  window.addEventListener("beforeunload", function (e) {
    const pathname = window.location.pathname
    //Paths for orders: card-front, card-back accessories shipping review-order payment
    if (
      pathname === "/app/order/card-front" ||
      pathname === "/app/order/card-front-deluxe" ||
      pathname === "/app/order/designer" ||
      pathname === "/app/order/card-back" ||
      pathname === "/app/order/accessories" ||
      pathname === "/app/checkout/shipping" ||
      pathname === "/app/checkout/review-order" ||
      pathname === "/app/checkout/payment" ||
      pathname === "/app/checkout/payment/" ||
      pathname === "/app/order/designer"
    ) {
      e.returnValue = ""
    }
  })

  const handlePricingCheck = () => {
    setPricingChecked(prev => !prev)
    if (paymentData) {
      setButtonDisabled(prev => !prev)
    }
  }

  const paymentMethodCheck = () => {
    let method = user.paymentMethod
    if (method === "BEFORE_CREDIT_CARD") {
      return true
    }
  }

  /**
   * @param data {boolean|object} Boolean flag to toggle the submit button state
   * or a object with the nonce and masked credit card data
   */
  const handlePaymentComponentChange = async data => {
    setSubmitCalled(false)
    if (data === false) {
      setButtonDisabled(true)
      setShowBackdrop(false)
      setPaymentData(false)
      return
    }
    if (data === true) {
      if (pricingChecked) {
        // Submit button should be enabled
        setButtonDisabled(false)
      }
      setPaymentData(true)
      return
    }

    try {
      const inputOrder = await getSubmitOrderJson(
        authProvider,
        user,
        appOrder,
        profileAddress,
        data,
        {}
      )
      const artWorkUrl = inputOrder.items[0].artWorkText
      const result = await API.graphql(graphqlOperation(submitOrder, { input: inputOrder }))
      const {
        data: { submitOrder: saved },
      } = result
      // add to order history here so we don't have to re-query
      const newOrderHistory = orderHistory.slice()
      newOrderHistory.unshift({
        date: saved.createdAt,
        status: saved.status,
        cardType: getCardType(appOrder.cards.cardType),
        artWorkUrl,
        paymentMethod: user.paymentMethod,
        totalCost: await getTotalCost(appOrder, profileAddress),
      })
      dispatch({
        type: SET_ORDER_HISTORY,
        payload: newOrderHistory,
      })

      if (isBrowser()) {
        window.sessionStorage.removeItem("pcoState")
        window.sessionStorage.setItem("orderSuccessfullySubmitted", "1")
      }
      setShowBackdrop(false)

      // reset state and redirect to order summary
      navigate(`/app/orders/order-summary/#` + window.btoa(saved.createdAt), {
        state: { resetOrder: 1 },
      })
    } catch (err) {
      setShowBackdrop(false)
      setEndCallInProgress(true)
      console.warn("Error submitting order:", err)
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          show: true,
          type: `error`,
          text: `There was an error processing your payment. Please try another card.`,
        },
      })
    }
  }

  return (
    <>
      <Notification />
      <Backdrop className={backdrop} open={showBackdrop}>
        <CircularProgress />
      </Backdrop>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionLeft}>
          <CreditCardComponent
            submitCalled={submitCalled}
            parentCallBack={handlePaymentComponentChange}
            useShippingAddressCheckbox={!isDesignerOrder}
            parentBackdrop={true}
            endCallInProgress={endCallInProgress}
          />
        </div>
        <div className={styles.sectionRight}>
          <div className={styles.summaryContainer}>
            <Card className={`elevatedCard`}>
              <CardContent>
                <div className={styles.cardContent}>
                  <h3 className={styles.h3Style}>Summary</h3>
                  <OrderSummaryReceiptComponent
                    orderType={""}
                    showPromo={true}
                    receiptView={"payments"}
                    isDesignerOrder={isDesignerOrder}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
          {paymentMethodCheck()
            ? agreementCheck(pricingChecked, handlePricingCheck, authProvider)
            : null}
          {OrderNavigation(navigateBack, prevLabel, handleSubmit, nextLabel, buttonDisabled)}
        </div>
      </div>
    </>
  )
}

export default PaymentPage
