import React from "react"
import * as styles from "../../conversion/conversion_new.module.scss"
import { Button } from "@material-ui/core"
import { Done } from "@material-ui/icons"
import { Box } from "@material-ui/core"
import { navigate } from "gatsby"
import DownloadFile from "../../conversion/download"
const Confirmation = props => {
  const labelName = "Download Converted Cards"
  console.log("file location", props.link)
  return (
    <div className={styles.content}>
      <div className={styles.row_1}>
        <Box sx={{ flex: "row" }}>
          <Box className={styles.confirmationbox}>
            <Done style={{ fontSize: "96px", color: "#00855F", marginInlineStart: "25px" }}></Done>
            <div className={styles.success}>Success!</div>
          </Box>
          <div className={styles.confirmationmsg}>{props.message}</div>
          <div style={{ margin: "40px 0px" }}>
            Click the button to download a copy of the successfully converted gift cards.
          </div>
          <DownloadFile
            link={props.link}
            filename={props.filename}
            labelName={labelName}
          ></DownloadFile>
        </Box>
        <Button
          style={{ marginTop: "100px" }}
          id="submit-designer-contact-button"
          color={`default`}
          className={`buttonSecondary`}
          variant={`contained`}
          onClick={() => navigate("/app/dashboard")}
        >
          Go to Home Page
        </Button>
      </div>
    </div>
  )
}

export default Confirmation
